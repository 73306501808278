import {
  Col,
  Table,
  Card,
  Form,
  Button,
  Input,
  CardBody,
  CardTitle,
  CardSubtitle,
  Row,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,Label
} from 'reactstrap';

import axios from 'axios';
import { useState, useEffect,useRef } from 'react';
import todoDot from "../../../assets/images/todoDot.png"
import initiatedDot from "../../../assets/images/initiatedDot.png"
import activeDot from "../../../assets/images/activeDot.png"
import closedDot from "../../../assets/images/closedDot.png"
import pendingDot from "../../../assets/images/pendingDot.png"
import Datetime from 'react-datetime';
import jwt_decode from 'jwt-decode'
import 'react-datetime/css/react-datetime.css';
import { useLocation,useNavigate } from 'react-router-dom';
import statusimage from "../../../assets/images/status.png";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import search from "../../../assets/images/search.png"
import calender from "../../../assets/images/calender.png"
import rightarrow from "../../../assets/images/chevron-right.png"
import retrigger from "../../../assets/images/retrigger.png"
import EditForm from '../EditForm/EditForm';
import MoveSanctionStatus from '../MoveSanctionStatus/MoveSanctionStatus';
import Sort from "../../../assets/images/sort.png";
import Sidebar from '../../Sidebar';
import close from "../../../assets/images/close.png";
import Topbar from '../../Topbar'
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import download from "../../../assets/images/download.png";
import PaginationTable from '../PaginationTable';
import "./SanctionTable.css"
import axiosInstance from '../../../helpers/axios';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'
import utr from "../../../assets/images/repayments.png"
import {userActions} from '../../../actions/user.actions';

//import '../../assets/scss/_variables.scss';

// eslint-disable-next-line import/extensions
//import ComponentCard from '../ComponentCard';

//require('moment/locale/fr');
//require('moment/locale/de');
//require('moment/locale/en-gb');

const SanctionTable = () => {

  const sanctionstate = useSelector((state) => state?.Insanction?.res);
  const usersearchstate = useSelector((state) => state?.UserSearch?.res?.data?.data);
 const bankSelfieState=useSelector((state)=>state?.BANKSELFIE?.bankSelfieData);
 const [activePage, setActivePage] = useState(15); 
  
  // const state = useMemo(() => (res), []);

  const [userDetails, setUserDetails] = useState(null)

  const dispatch = useDispatch();

  const ApplicationStatusEnum = {
  InSubmission: 'In Submission',
  InReview: 'In Review',
  InSanction: 'In Sanction',
  InDisbursement: 'In Disbursement',
  PendingDisbursement: "Pending Disbursement",
  Disbursed: 'Disbursed',
  Rejected: 'Rejected',
  Dropped: 'Dropped',
  OnHold:'On Hold', ReadyToDisburse:'Ready to Disburse',
};

  //tooltip
  const [signedTooltipOpen, setSignedTooltipOpen] = useState(false);
  const signedToggle = () => setSignedTooltipOpen(!signedTooltipOpen);
  
  const [selfieTooltipOpen, setSelfieTooltipOpen] = useState(false);
  const selfieToggle = () => setSelfieTooltipOpen(!selfieTooltipOpen);


  const [cra, newCra] = useState([]);
  const [selectedCRA, setSelectedCRA] = useState('');


  const location=useLocation();
  const [status, setStatus] = useState(ApplicationStatusEnum.InSanction);
  const localStatus = sessionStorage.setItem("status",status)
  const user = sessionStorage.getItem('user');
  const decode=jwt_decode(user)
  //filter
  const [filterInstituteName, setFilterInstituteName] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState(null);
  const [filterDateTo, setFilterDateTo] = useState(null);
  const [searchText, setSearchText] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const valid = (current) => {
    return current.isAfter(filterDateFrom);
  };
//tooltip
 const [agreementTooltipOpen, setAgreementTooltipOpen] = useState(false);
 const [mandateTooltipOpen, setMandateTooltipOpen] = useState(false);
 const [upiMandateTooltipOpen, setUpiMandateTooltipOpen] = useState(false);

const inputRef=useRef()
  const copyAgreementToggle = () => {

    setAgreementTooltipOpen(!agreementTooltipOpen);
    navigator.clipboard.writeText(`
              Aadhaar Suffix:${info?info.aadhaar_suffix:null},
              Name: ${info?info.name:null},
              Postal Code: ${info?info.postal_code:null},
              Display Name: ${info?info.display_name:null},
              Name Validation:${info?info.name_validation_score:null},
              Created At:${docDetails?docDetails.createdAt:null},
              Done At:${docDetails?docDetails.updatedAt:null},
              Download Signed Agreement Document:${docDetails?docDetails.signedDocumentUrl:null} `)
  }
  const copyMandateToggle = () => {

    setMandateTooltipOpen(!mandateTooltipOpen);
   navigator.clipboard.writeText(`
              Name:${mandateDetails?mandateDetails.name:null},
              Account Type:${mandateDetails?mandateDetails.accountNumber:null},
              Account Type: ${mandateDetails?mandateDetails.accountType:null},
              Customer mobile:${mandateDetails?mandateDetails.phone:null},
              Mandate Type:${mandateDetails?mandateDetails.mandateType:null},
              IFSC Code: ${mandateDetails?mandateDetails.ifsc:null},
              TokenId:${mandateDetails?mandateDetails.tokenId:null},
              PaymentId:${mandateDetails?mandateDetails.paymentId:null},
              CustomerId:${mandateDetails?mandateDetails.customerId:null},
             
               `)


           
  }
  //table
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);
  const [time, setTime] = useState('');
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [info,setInfo]=useState(null)
  const [docDetails,setDocDetails]=useState(null)
  const [currentPage, setcurrentPage] = useState(sessionStorage.getItem("page") ? parseInt(sessionStorage.getItem("page")) : 1);
 
  const [rowsPerPage, setrowsPerPage] = useState(15);
  const [totalFetchRows, setTotalFetchRows] = useState(null);
  const [formStatusOpen, setStatusOpen] = useState(false);
  const [mandateStatusOpen, setMandateStatusOpen] = useState(false);
  const [upiMandateStatusOpen, setUpiMandateStatusOpen] = useState(false);
  const [selfieStatusOpen, setSeflieStatusOpen] = useState(false);
  const [digilockerStatusOpen, setDigilockerStatusOpen] = useState(false);
  const [mandateDetails, setMandateDetails] = useState(null);
  const [upiMandateDetails, setUpiMandateDetails] = useState(null);

  //pagination
  //changepage
  const paginate = (pageNumber) => {
    let urlUsers
     if(searchText.length >= 4 && selectedCRA === ""){
      console.log("insdie if")
      urlUsers = `/integrations/applications?status=${status}&instituteId=${id}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
     }else if(searchText === "" && selectedCRA !== ""){
      console.log("insdie elseif")
      urlUsers = `/integrations/applications-cra?cra=${selectedCRA}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
     }else{
      console.log("insdie else")
      urlUsers = `/integrations/applications?status=${status}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
     }
     axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res?.data?.data?.details)
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
    
  };
  
  
   
 const closeAgreeCopy=()=>{
  setStatusOpen(!formStatusOpen)
  setAgreementTooltipOpen(agreementTooltipOpen?!agreementTooltipOpen:agreementTooltipOpen);
 }
 const closeMandateCopy=()=>{
  setMandateStatusOpen(!mandateStatusOpen)
  setMandateTooltipOpen(mandateTooltipOpen?!mandateTooltipOpen:mandateTooltipOpen);
 }

  //sorting
  const [sort,setSort]=useState(-1)

  const urlUsers = `/integrations/applications?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
  
  const[toggleSort,setToggleSort]=useState(false);


  useEffect(() => {
    if(usersearchstate){
      setIsLoading(false);
      setBankSelfieDetails(bankSelfieState?.res)
      setUserDetails(usersearchstate?.details)
      setTableData(usersearchstate?.details);
      setFilterTableData(usersearchstate?.details);
      setcurrentPage(usersearchstate?.pagination.currentPage);
      setTotalFetchRows(usersearchstate?.pagination.totalRows);
      setrowsPerPage(usersearchstate?.pagination.perPage); 
    }else{
      if(toggleSort)
      {
        setSort(1)
        
      } 
      else {
        setSort(-1)
       
      } 
  
        let urlUsers;
        if(searchText === "" && selectedCRA === ''){
        
          urlUsers = `/integrations/applications?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`
        }else if(selectedCRA !== ""){
          urlUsers = `/integrations/applications-cra?cra=${selectedCRA}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
        }
          axiosInstance.get(urlUsers, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          })
          .then((res) => {
            
            setBankSelfieDetails(bankSelfieState?.res)
            setTableData(res?.data?.data?.details);
            setcurrentPage(res?.data?.data?.pagination?.currentPage);
            setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
            setrowsPerPage(res?.data?.data?.pagination?.perPage);  
            const loadingTimeout = setTimeout(() => {
              setIsLoading(false);
            }, 300);
          })
          .catch((error) => {
            console.error("Error fetching filtered data:", error);
          });

    }
    
        
    const userUrl = `/admin/users`;
    axiosInstance.get(userUrl,{
      headers: {
        Authorization: `Bearer ${user}`,
      },
    }).then((res)=>{
        newCra(res?.data?.data)
    })
  }, [toggleSort, sanctionstate,usersearchstate,bankSelfieState,selectedCRA]);



  const handleDocDownload=(docUserId,docUserApplicationId)=>{
    setStatusOpen(!formStatusOpen);
    sessionStorage.setItem("regenAppId",docUserApplicationId)
    sessionStorage.setItem("regenUserId",docUserId)
      const docUrl=`/agreement/downloadAgreement?userId=${docUserId}&applicationId=${docUserApplicationId}`
    axiosInstance.post(docUrl).then((resp)=>{
      if(resp.data.data!=undefined)
      {

        setInfo(JSON.parse(resp.data.data.info))
        setDocDetails(resp.data.data)
      }
    }
    )
  }
  const [bankSelfieDetails,setBankSelfieDetails]=useState(null)

  const handleSelfieRetrigger=(docUserId,docUserApplicationId)=>{
     if(decode.role!=="institute_user"&&decode.role!=="institute_admin")
    {
      setSeflieStatusOpen(!selfieStatusOpen);
      sessionStorage.setItem("regenAppId",docUserApplicationId)
      sessionStorage.setItem("regenUserId",docUserId)
       const urlGetIntegrations= `${process.env.REACT_APP_BASE_URL}/integrations/details?applicationId=${docUserApplicationId}&userId=${docUserId}`;
       dispatch(userActions.getBankSelfieDetails(urlGetIntegrations,user))

    }
    //  axios.get(urlGetIntegrations, {
    //         headers: {
    //           Authorization: `Bearer ${user}`,
    //           'Content-type': 'application/json',
    //               },
    //          }).then((res) => {
    //           setSelfieIntegrationsData(res.data.data.selfieDetails);
    //      });
  }
  const handleDigilockerRetrigger=(digiStatus,docUserId,docUserApplicationId)=>{
    if(decode.role!=="institute_user"&&decode.role!=="institute_admin")
    {
      if(digiStatus==="Pass" || digiStatus==="Initiated" || digiStatus==="Not Initiated")
      {
  
        setDigilockerStatusOpen(!digilockerStatusOpen);
        sessionStorage.setItem("regenAppId",docUserApplicationId)
        sessionStorage.setItem("regenUserId",docUserId)
      }

    }
    
  }

  const [cashfreeDetails,setCashfreeDetails]= useState(null)

  const handleMandateDetails=(muserApp,muserId,muserName,mStatus,cra)=>{
   
    sessionStorage.setItem("regenName",muserName)
     sessionStorage.setItem("regenAppId",muserApp)
    sessionStorage.setItem("regenUserId",muserId)
    sessionStorage.setItem("mStatus",mStatus)
    if((mStatus === "Upi Mandate Initiated" || mStatus === "Upi Mandate Partial" || mStatus === "Upi Mandate Failed" || mStatus === "Upi Mandate Done") && cra !== "InsurFin"){
      const mandateUrl=`/mandate/mandate-details?applicationId=${muserApp}&userId=${muserId}`
      axiosInstance.get(mandateUrl,{
        headers: {
          Authorization: `Bearer ${user}`,
          'Content-type': 'application/json',
        },
      }).then((resp)=>{  console.log("resp",resp)
      setUpiMandateStatusOpen(!upiMandateStatusOpen);
      setMandateStatusOpen(mandateStatusOpen);
      setUpiMandateDetails(resp.data.data)
      setCashfreeDetails(null)}
 ).catch((err)=>console.log(err))
    }
    else if((mStatus === "Upi Mandate Initiated" || mStatus === "Upi Mandate Partial" || mStatus === "Upi Mandate Failed" || mStatus === "Upi Mandate Done") && cra === "InsurFin"){
      const mandateUrl=`/cashfree-upi/mandate-details?applicationId=${muserApp}&userId=${muserId}`
      axiosInstance.get(mandateUrl,{
        headers: {
          Authorization: `Bearer ${user}`,
          'Content-type': 'application/json',
        },
      }).then((resp)=>{  console.log("resp",resp)
      setUpiMandateStatusOpen(!upiMandateStatusOpen);
      setMandateStatusOpen(mandateStatusOpen);
      setCashfreeDetails(resp.data.data)
      setUpiMandateDetails(null)}
 ).catch((err)=>console.log(err))
    }
    else{
      const mandateUrl=`/integrations/razorpay-details?applicationId=${muserApp}&userId=${muserId}`
      axiosInstance.get(mandateUrl,{
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-type': 'application/json',
          },
        }).then((resp)=>{  console.log("resp",resp)
        setUpiMandateStatusOpen(upiMandateStatusOpen);
        setMandateStatusOpen(!mandateStatusOpen);
        setMandateDetails(resp.data.data)}
   )
    }
  
  }
console.log("upi",upiMandateDetails)
  //refresh 
  //const [inputCustomerId,setCustomerId]=useState(null)
  const [refreshMandateOpen,setRefreshMandateOpen]=useState(null)
  const handleRefreshMandate=()=>{
    

      const urlRefreshMandate='/nach/refresh'
      const body={
        userId:sessionStorage.getItem('regenUserId'),
        applicationId:sessionStorage.getItem('regenAppId'),
        
      }
      axiosInstance.post(urlRefreshMandate,body,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        alert("Refresh Success")
      })
      .catch((err)=>{
        
        alert(err?.response?.data?.message)
      })
    
   
  }
 
  //datefilter
  const handleDateFilterApi = (startDate, endDate) => {
    if (startDate === null || endDate === null) alert('Enter Date Range to Filter');
    else {
      const urlFilterUsers = `/integrations/applications?status=${status}&fromDate=${startDate}&toDate=${endDate+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
      axiosInstance.get(urlFilterUsers,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        setTableData(res?.data?.data?.details);
        setFilterTableData(res?.data?.data?.details);
        setcurrentPage(res?.data?.data?.pagination.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination.totalRows);
        setrowsPerPage(res?.data?.data?.pagination.perPage);
      });
    }
  };

  //clearfilter
  // const handleClearFilter = () => {
  //   setFilterInstituteName('');
  //   axios.get(urlUsers).then((res) => {
  //    setTableData(res.data.data.details);
  //       setFilterTableData(res.data.data.details);
        
  //        setcurrentPage(res.data.data.pagination.currentPage);

  //       setTotalFetchRows(res.data.data.pagination.totalRows);
  //       setrowsPerPage(res.data.data.pagination.perPage);
  //   });
  // };
  const navigate = useNavigate();

 const userstate = useSelector((state) => state.Handleuser?.res)
  const userCoAppstate = useSelector((state) => state.Handlecoappuser?.res)

  const handleUser = (event) => {
    
    const Id = event.userId;
    const applId = event.applicationId
    const urlProfile = `/summary/user?id=${Id}&applicationId=${event.applicationId}&status=In Sanction`;

    dispatch(userActions.fetchHandleuser(urlProfile, user,navigate,applId,Id),[])

      const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/user?id=${Id}&applicationId=${event.applicationId}&status=In Sanction`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `${process.env.REACT_APP_BASE_URL}/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    
   const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate('/status/sanction/profile', {
      state: { id: 1, profileId: Id,applicantId:event.userId,coapplicantId:event.coapplicantId,applicationId:event.applicationId,dir:"In Sanction" },
    });

   
  };

 


  const handleCoApp = (event,currTab) => {
    
    const Id = event.coapplicantId;
    const applId = event.applicationId

    const urlProfile = `/summary/coapplicant?id=${Id}&status=In Sanction`;
   
    dispatch(userActions.fetchHandlcoappuser(urlProfile, user,navigate,applId,Id),[])

      const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/coapplicant?id=${Id}&status=In Sanction`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `${process.env.REACT_APP_BASE_URL}/cibil/report?userId=${Id}`

const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    
   const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate('/status/sanction/profile', {
      state: { id: 1, profileId: Id,clickEvent:"coapp",applicantId:event.userId,coapplicantId:event.coapplicantId,applicationId:event.applicationId,dir:"In Sanction" },
    });
     
   
  };

  //Go To Application Profile
   const handleApplicationProfile=(currApplicationId,currUID,currCoAppId)=>{
    sessionStorage.setItem("userId",currUID)
    sessionStorage.setItem("appId",currApplicationId)
    sessionStorage.setItem("coappId",currCoAppId)
    const urlForApplicationDetails=`/admin/application/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const urlForBankSelfie=`/integrations/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const getEmiUrl=`/emis?applicationId=${sessionStorage.getItem("appId")}`
    const getActivityUrl=`/audit?applicationId=${sessionStorage.getItem("appId")}`
    dispatch(userActions.fetchUrlforApplicationdetails(urlForApplicationDetails,user),[])
    dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    dispatch(userActions.fetchEmiUrl(getEmiUrl,user),[])
    dispatch(userActions.getActivity(getActivityUrl,user))
    navigate("/status/sanction/appProfile")
    
  }
  
  const handleDownloadList=()=>{
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
  
    const formattedDate = `${day}-${month}-${year}`;

    document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "90px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"


    const downloadTemplateUrl=`${process.env.REACT_APP_BASE_URL}/excel/download`
    const downloadData={
        instituteId: decode.instituteId?decode.instituteId:id,

        status:status,
        fromDate:filterDateFrom,
        toDate:filterDateTo+" "+"23:59:59"
       
    }
    axios.post(downloadTemplateUrl,downloadData, {
      responseType:'arraybuffer',
      headers:{
        Authorization:`Bearer ${user}`

    }
  }).then((res)=>{
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 2000);
    const blob = new Blob([res.data], { type: "application/xlsx", });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href =url
    link.download = `${formattedDate}_${status}.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
      
    
  ).catch((error)=>{
    alert("Error Downloading")
    document.getElementById("downloadButton").innerText="Error..";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 1000);
  })
  }   


  
  const retriggerAgreement=()=>{
    const regenAgreementUrl=`/agreement/regenerate`
    console.log(sessionStorage.getItem("regenAppId"))
    console.log(sessionStorage.getItem("regenUserId"))
    const data={
      applicationId:sessionStorage.getItem("regenAppId"),
      userId:sessionStorage.getItem("regenUserId")
    }

    axiosInstance.post(regenAgreementUrl,data,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    })
    .then((res)=>{
      alert("Agreement Triggered ,You can close this box")
      axiosInstance
      .get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
          'Content-type': 'application/json',
        },
      })
      .then((resp) => {
        setTableData(resp.data.data.details);
        setFilterTableData(resp.data.data.details);
        setcurrentPage(resp.data.data.pagination.currentPage);
        setTotalFetchRows(resp.data.data.pagination.totalRows);
        setrowsPerPage(resp.data.data.pagination.perPage);
      });
    })
  }

  const [KID,setKID]=useState(null)
  const [transactionId,setTransactionID]=useState(null)

  const selfieRetrigger=()=>{
    if(KID!==null&&transactionId!==null)
    {

      const regenSelfieUrl=`/selfie/refreshDetails?applicationId=${sessionStorage.getItem("regenAppId")}&userId=${sessionStorage.getItem("regenUserId")}&kid=${KID.trim()}&transactionId=${transactionId.trim()}`
      console.log(sessionStorage.getItem("regenAppId"))
      console.log(sessionStorage.getItem("regenUserId"))
       
  
      axiosInstance.post(regenSelfieUrl,null,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
       alert("Selfie Refreshed ,You can close this box")
        axiosInstance
        .get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-type': 'application/json',
          },
        })
        .then((resp) => {
          setTableData(resp.data.data.details);
          setFilterTableData(resp.data.data.details);
          setcurrentPage(resp.data.data.pagination.currentPage);
          setTotalFetchRows(resp.data.data.pagination.totalRows);
          setrowsPerPage(resp.data.data.pagination.perPage);
        });
      }).catch((error)=>{alert(error?.response?.data?.message)})
    }
    else
    {
      alert("Enter Proper Values")
    }
  }

  const selfieReinitiate=()=>{
    const reinitiateselfie=`/selfie/reinitiate`
    const data={
      applicationId:sessionStorage.getItem("regenAppId"),
      userId:sessionStorage.getItem("regenUserId")
    }
    axiosInstance.post(reinitiateselfie,data, {
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-type': 'application/json',
      },
    }).then((res)=>{
      alert(res?.data?.message)
      setTimeout(() => window.location.reload(true), 500)}
      )
    .catch((err)=>{console.log(err)})

  }
  const digilockerRetrigger=()=>{
    if(KID!==null&&transactionId!==null)
    {

      const regenDigilockerUrl=`/digilocker/refreshDetails?applicationId=${sessionStorage.getItem("regenAppId")}&userId=${sessionStorage.getItem("regenUserId")}&kid=${KID.trim()}&transactionId=${transactionId.trim()}`
      console.log(sessionStorage.getItem("regenAppId"))
      console.log(sessionStorage.getItem("regenUserId"))
       
  
      axiosInstance.post(regenDigilockerUrl,null,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        alert("Digilocker triggered ,You can close this box")
        axiosInstance
        .get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-type': 'application/json',
          },
        })
        .then((resp) => {
          setTableData(resp.data.data.details);
          setFilterTableData(resp.data.data.details);
          setcurrentPage(resp.data.data.pagination.currentPage);
          setTotalFetchRows(resp.data.data.pagination.totalRows);
          setrowsPerPage(resp.data.data.pagination.perPage);
        });
      })
    }
    else
    {
      alert("Enter Proper Values")
    }
  }

  const upimandateTrigger=()=>{
      console.log(sessionStorage.getItem("regenAppId"))
      console.log(sessionStorage.getItem("regenUserId"))
      const registerUpiUrl=`/mandate/register-upi`

      const data={
        userId:sessionStorage.getItem("regenUserId"),
        applicationId:sessionStorage.getItem("regenAppId")
      }
      axiosInstance.post(registerUpiUrl,data,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>alert("UPI mandate triggered ,You can close this box"))
      .catch((err)=>alert(
        "Error !"
      ))
  }
  const [id,setId]=useState(null)
  const getInsituteInfo=(text)=>{
    
    const urlGetInstitute=`/institute/info/name?name=${text}`;
      
    if(text.length>=4)
    {
      axiosInstance.get(urlGetInstitute,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        console.log("text",res?.data?.data[0]?.id)
        setId(res?.data?.data[0]?.id)
      });
  
   
    }
 
  }
  const handlepageChange = (number) => {
    setIsLoading(true)
    setActivePage(number)
    setrowsPerPage(number);

    let queryParams = {}
    
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
   
    if(status){
      queryParams.status = status
    }
  
    if(selectedCRA){
      queryParams.cra = selectedCRA
    }
    if(rowsPerPage && currentPage){
      queryParams.perPage = number;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/integrations/applications',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))   
};



  const handleInputChange = (event) => {

    if(event.target.value.length >= 4){
      setSearchText(event.target.value)
    
      const urlUsers = `/integrations/applications?status=${status}&instituteId=${id}&perPage=${rowsPerPage}&pageNo=${1}&sortOrder=${sort}`;

  console.log("url",urlUsers)
    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      alert(error?.response?.data?.message)
      console.error("Error fetching filtered data:", error);
    });
  }
  else{
    setSearchText("")
    const urlUsers = `/integrations/applications?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;


    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination.totalRows);
      setrowsPerPage(res?.data?.data?.pagination.perPage);  
   
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });



  }
};
  
function formatNumberWithCommas(number) {
  return number.toLocaleString('en-IN'); 
}
const [fraudToolOpen,setFraudToolOpen] = useState(false)
const [fruadDetails, setFruadDetails] = useState(null);

const handleFraudDetails=(muserApp,muserId,muserName)=>{
  setFraudToolOpen(!fraudToolOpen);
  sessionStorage.setItem("regenName",muserName)
  sessionStorage.setItem("regenAppId",muserApp)
 sessionStorage.setItem("regenUserId",muserId)
  const fraudUrl=`/integrations/fraud-check?applicationId=${muserApp}&userId=${muserId}`
  axiosInstance.get(fraudUrl,{
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-type': 'application/json',
      },
    }).then((resp)=>

setFruadDetails(resp?.data?.data)
 )
 
}


const [manualAgreementUpload,setManualAgreementUpload]=useState(null)
  const UploadManualAgreement=(e)=>{

      const file=new FormData();
      file.append("file",e.target.files[0]);
      file.append("type","manual_agreement");
  
      
      axiosInstance.post(`/file/upload`,file)
      .then((res)=>{
        console.log(res.data.data)

        const uploadManualAgreementUrl=`/agreement/upload-signed`
        
        const body={
            userId: sessionStorage.getItem('regenUserId'),
            applicationId: sessionStorage.getItem('regenAppId'),
            signedDocumentUrl: res?.data?.data?.s3Url
          }
    
        axiosInstance.post(uploadManualAgreementUrl,body,{
          headers:{
            Authorization:`Bearer ${user}`
          }
        })
        .then(res=>{
          alert("Manual Agreement Uploaded")
          setStatusOpen(!formStatusOpen)
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
        )
        .catch((err)=>{
          alert(err?.response?.data?.message)
        }
        )
      })
    }

    const [modal, setModal] = useState(false);
    const [itemPath,setitemPath] = useState(null)
  const toggle1 = () =>{
    const url = `/file/url-content?path=${docDetails?docDetails.signedDocumentUrl.split('.com/')[1]:null}`
    axiosInstance
        .get(url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res)=>{
          setitemPath(res?.data?.data)
          })
    setModal(!modal);
  }
  const [paymentModal, setPaymentModal] = useState(false);
  const [advEmiChecked,setAdvEmiChecked] = useState(true)
  const [pfChecked,setPfChecked] = useState(true)
  const [paidAtInstChecked,setPaidAtInstChecked] = useState(false)
  const [pf,setPf] = useState(null)
  const [adv,setAdv] = useState(null)
  const [userId,setUserId] = useState(null)
  const [appId,setAppId] = useState(null)
  const [totalAmount,setTotalAmount] = useState(null)
  const [instName,setInstName] = useState(null)
  const [formLoader, setFormLoader] = useState(false);
  const [formsStatusOpen, setStatussOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('Hold On...');

  const togglePayment = (item) => {
    if (!paymentModal) {
      setAdvEmiChecked(true);
      setPfChecked(true);
      setPf(null);
      setAdv(null);
      setUserId(null);
      setAppId(null);
      setPaidAtInstChecked(false)
    }
      setUserId(item?.userId)
      setAppId(item?.applicationId)
      setInstName(item?.instituteName)
      setPf(item?.processingFee)
      setAdv(item?.advanceEmi)
      setPaymentModal(!paymentModal);
      const advanceEmi = parseFloat(item?.advanceEmi);
      const processingFee = parseFloat(item?.processingFee)
      if (!isNaN(advanceEmi) && !isNaN(processingFee)) {
        const totalAmount = advanceEmi + processingFee;
        setTotalAmount(totalAmount);
      }

  }
  const [clicked, setClicked] = useState(false);

  const GenerateLink = () =>{
    setClicked(true);
    const data = {
      userId:userId,
      applicationId:appId,
      advEmi:adv,
      processingFee:pf,
      totalAmount:totalAmount,
      instituteName:instName,
      status: paidAtInstChecked ? 5 : (pf === 0 && adv === 0 ? 4 : 0)
    };

    console.log("data",data)
    const createUrl = `${process.env.REACT_APP_BASE_URL}/pre-disbursement-collection/create-link`;

    axiosInstance.post(createUrl,data,{
      headers:{
        Authorization:`Bearer ${user}`
    }
    }).then((res)=>{
      console.log("paymentlink",res?.data?.message)
      if(res?.data?.message === "Successful"){
        alert('Link Generated Successfully')
        setClicked(false)
        setPaymentModal(!paymentModal);
        setTimeout(() => {
          window.location.reload()
      }, 500);
      }
    }).catch((error)=>{console.log(error)
    alert(error?.response?.data?.message)
  setClicked(false)})
  }
  const [manualUpdateModal,setManualUpdateModal] = useState(false);

  const toggleManualUpdateModal = () => setManualUpdateModal(!manualUpdateModal);

  const [utrNumber,setUtrNumber] = useState("")
  const [paidDate,setPaidDate] = useState("")
  const [advanceEmi,setAdvanceEmi] = useState(null)
  const [manualPf,setManualPf] = useState(null)
  const [remarks,setRemarks] = useState("")

  const CreateManualPayment = () =>{
    setClicked(true);
    const data = {
      userId:userId,
      applicationId:appId,
      advEmi:advanceEmi?advanceEmi:adv,
      processingFee:manualPf?manualPf:pf,
      totalAmount: (parseFloat(advanceEmi ? advanceEmi : adv) + parseFloat(manualPf ? manualPf : pf)),
      instituteName:instName,
      status:3,
      utr:utrNumber,
      mode:2,
      paidDate:paidDate
    };

    const createUrl = `${process.env.REACT_APP_BASE_URL}/pre-disbursement-collection/create-manual-payment`;

    axiosInstance.post(createUrl,data,{
      headers:{
        Authorization:`Bearer ${user}`
    }
    }).then((res)=>{
      if(res?.data?.message === "Successful"){
        alert('UpdatedSuccessfully')
        setClicked(false)
        setManualUpdateModal(!manualUpdateModal);
        setTimeout(() => {
          window.location.reload()
      }, 500);
      }
    }).catch((error)=>{console.log(error)
    alert(error?.response?.data?.message)
  setClicked(false)})
  }
  const [isOpen,setIsOpen]=useState(true)

  if (tableData) {
    return (
      
      <Card className="card" style={{ display:'flex',flexDirection:'row',width:"100%",height:'100%'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%'}}>
        <Topbar/>
        <CardBody>
        <div>
          <p style={{display:'flex',alignItems:'center'}}><img src={statusimage}/><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px'}}>status</span><span style={{marginLeft:'20px',marginBottom:'3px'}}> <img style={{height:"18px",width:"14px"}} src={rightarrow}/></span><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px',color:'#D32028'}}>{status}</span></p>
          
         </div>
          <Row>
          <div style={{ display: 'flex', flex: 'wrap'}}>
          <Col sm="12" md="10" lg="3">
            <div style={{ height: '23px', marginRight: '10px',fontFamily:'Inter'  , position: 'relative', display: 'flex', alignItems: 'center' }}>
            {decode.role === "institute_admin" || decode.role === "institute_user" ?
            <Input
            type="text"
            id="userSearch"
            value={decode.instituteName}
            readOnly
            disabled
            placeholder={'Institute Name'
            }
            style={{
               fontSize: '0.8vw',
              paddingLeft: '26px',
              height: '23px',
              fontFamily: 'Inter',
              backgroundImage: `url(${search})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '5px center',
              backgroundSize: '15px 15px',
              paddingRight: '5px', 
              borderColor:'#cccccc',
              borderStyle:'solid',
              borderRadius:"5px",
              borderWidth:'1px',
              height:'34px',
              marginTop:'11px'
            }}
          />: <Input
          type="text"
          id="userSearch"
          placeholder={'Institute Name'
          } onChange={(event) => {
            getInsituteInfo(event.target.value);
            handleInputChange(event);
          }}
          style={{
             fontSize: '0.8vw',
            paddingLeft: '26px',
            height: '23px',
            fontFamily: 'Inter',
            backgroundImage: `url(${search})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '5px center',
            backgroundSize: '15px 15px',
            paddingRight: '5px', 
            borderColor:'#cccccc',
            borderStyle:'solid',
            borderRadius:"5px",
            borderWidth:'1px',
            height:'34px',
            marginTop:'11px'
          }}
        />
          }
          </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  onChange={(event) =>{ 
                    if (event && event.format) {
                    setFilterDateFrom(event.format('DD MMM YYYY'));
                  }else{
                    setFilterDateFrom('');
                  }
                  }}
                  locale="en-gb"
                  inputProps={{ placeholder: 'From Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="toDate"
                  dateFormat="DD MMM YYYY"
                  isValidDate={valid}
                  timeFormat={false}
                  locale="en-gb"
                  onChange={(event) => {
                    if (event && event.format) {
                      setFilterDateTo(event.format('DD MMM YYYY'));
                    } else {
                      setFilterDateTo(''); 
                    }
                  }}
                  inputProps={{ placeholder: 'To Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col>
            <div> 
            <button style={{display: 'flex', alignItems: 'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter",paddingLeft:'10px'}}
                onClick={() => handleDateFilterApi(filterDateFrom, filterDateTo)}
               
              >
                Search Results
              </button>
              </div>
            </Col>
            <Col>
            <div>
          <Input type="select" name="select" id="exampleSelect" value={selectedCRA} onChange={(e) => setSelectedCRA(e.target.value)}>
          <option value="">Select CRA</option> 
            {cra?.map((item,index)=>(
              <option>{item.emailId}</option>
            ))}
          </Input>
          </div>
            </Col>
            <Col>
            <button style={{backgroundColor:'#D22129',width:'90px',height:'34px',padding:'10px',display: 'flex', alignItems: 'center',marginLeft:"150px",borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter"}} id="downloadButton" onClick={() => handleDownloadList()}>
           <p style={{display:'flex',alignItems:'center',marginTop:'15px'}}><img style={{height:'15px',alignItems:"center",marginRight:'5px'}}  src={download} alt="download"/><span>Export</span></p> 
            </button>
            </Col>
          </div>
        </Row>
        <Modal  isOpen={confirmationDialog}>
        <ModalHeader>Waiting For Confirmation</ModalHeader>
        <ModalBody>{confirmationMessage}</ModalBody>
      </Modal>
        <Modal isOpen={paymentModal}  >
        <ModalHeader toggle={togglePayment}>Payment Link</ModalHeader>
        <ModalBody>
        <Form> 
        <div>
          <FormGroup
            check
            inline
          >
            <Input type="checkbox"  style={{marginTop:'12px'}} onChange={() => setPaidAtInstChecked(!paidAtInstChecked)} checked={paidAtInstChecked}/>
            <Label check style={{marginLeft:'10px'}}>
              Paid At Institute
            </Label>
          </FormGroup>
          </div>
          <div style={{display:'flex',alignItems:'center'}}>
          <FormGroup
            check
            inline
          >
            <Input type="checkbox" style={{marginTop:'12px'}} onChange={() => setAdvEmiChecked(!advEmiChecked)} checked={advEmiChecked} disabled={paidAtInstChecked}/>
            <Label check>
            Adv Emi  
            </Label>
          </FormGroup>
          <FormGroup  style={{marginLeft:'0.9rem'}}
          >
                                  
            <Input type="text" placeholder='Adv Emi' style={{marginTop:'12px'}} value={advEmiChecked?adv:null} disabled={paidAtInstChecked}/>

          </FormGroup>
          </div>
          <div style={{display:'flex',alignItems:'center'}}>
          <FormGroup
            check
            inline
          >
            <Input type="checkbox"  style={{marginTop:'12px'}} onChange={() => setPfChecked(!pfChecked)} checked={pfChecked} disabled={paidAtInstChecked}/>
            <Label check style={{marginLeft:'10px'}}>
              Pf
            </Label>
          </FormGroup>
          <FormGroup
            style={{marginLeft:'3rem'}}
            
          >
            <Input type="text"placeholder='pf' style={{marginTop:'12px'}} defaultValue={pfChecked?pf:null} disabled={paidAtInstChecked}/>
            
          </FormGroup>
          </div>
          <div style={{display:'flex',alignItems:'center'}}>
            <Label>Adv + pf : </Label>
            <FormGroup
            style={{marginLeft:'2.6rem'}}
            
          >
            <Input type="text" style={{marginTop:'12px'}}  value={(advEmiChecked ? parseFloat(adv) : 0) + (pfChecked ? parseFloat(pf) : 0)} disabled={paidAtInstChecked}/>
            
          </FormGroup>
          </div>
          
        </Form>
        </ModalBody>
        <ModalFooter style={{display:'flex',justifyContent:'space-between'}}>
          <Button  style={{backgroundColor:'#D32028',color:'white',border:'none',transform: clicked ? 'scale(1.1)' : 'none',
        transition: 'transform 0.3s ease',}} onClick={GenerateLink}>
            Generate Link
          </Button>{' '}
          <Button  style={{backgroundColor:'#D32028',color:'white',border:'none',transform: clicked ? 'scale(1.1)' : 'none',
        transition: 'transform 0.3s ease',}} onClick={toggleManualUpdateModal}>
            Update Manual Pmt.
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={manualUpdateModal} toggle={toggleManualUpdateModal}>
        <ModalHeader toggle={toggleManualUpdateModal}>Manual Update Payment</ModalHeader>
        <ModalBody>
        <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em'}}>UTR</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}}  placeholder='Enter UTR' defaultValue={utrNumber} onChange={(e)=>setUtrNumber(e.target.value)}>
          
          </Input>
        <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>Paid Date</p>
        <Datetime
                closeOnSelect="false"
                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  onChange={(event) => {
                      setPaidDate(event.format('DD MMM YYYY'));
                    
                  }}
                  locale="en-gb"
                  inputProps={{ placeholder: 'Select Paid Date',style:{fontFamily:'Inter-Medium', backgroundImage: `url(${calender})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: '5px center',
                  backgroundSize: '15px 15px', paddingLeft: '30px',fontSize:"14px"} }}                />
           
          <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>Advance Emi</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} placeholder='Enter Advance Emi' defaultValue={adv} onChange={(e)=>setAdvanceEmi(e.target.value)}>
          
          </Input>
          <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>Processing Fee</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} placeholder='Enter Processing Fee' defaultValue={pf} onChange={(e)=>setManualPf(e.target.value)}>
          
          </Input>
          <p style={{fontSize:'14px',fontFamily:'Inter-Medium',lineHeight:'1em',marginTop:'3vh'}}>Remarks</p>
           <Input  style={{fontSize:'14px',color:'#667085',fontFamily:'Inter-Medium'}} placeholder='Enter Remarks' defaultValue={remarks} onChange={(e)=>setRemarks(e.target.value)}>
          
          </Input>
        </ModalBody>

        <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginBottom:'10px'}}>
            <button style={{paddingLeft:'10px',fontFamily:'Inter-medium',color:'white',backgroundColor:'#D32028',paddingRight:'10px',borderRadius:'6px',transform: clicked ? 'scale(1.1)' : 'none',
        transition: 'transform 0.3s ease'}} onClick={CreateManualPayment}>Update</button>
          </div>
      </Modal>
        <Modal isOpen={modal}  size="xl" style={{height:'100%'}}>
        <ModalHeader toggle={toggle1}>Agreement</ModalHeader>
        <ModalBody >
        <iframe
            src={itemPath}
            width="100%"
            height="1000vh"
            style={{ border: 'none' }}
          ></iframe>
        </ModalBody>
        
       
      </Modal>
      {formStatusOpen && (
        <>
        <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
     <Modal style={{
      width:"500px",
       
      top:"10%",width:'600px',borderRadius:"20px"}} isOpen={formStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium',fontWeight:"600",marginLeft:'10px',marginTop:'14px'}}>Agreement Details</p> 
          {agreementTooltipOpen&&<span style={{fontFamily:'Inter-Medium',marginLeft:"200px",height:"30px",display:"flex",alignItems:"center",fontSize:'11px',backgroundColor:'black',color:'white',borderRadius:'5em',padding:'0.5em',position:'absolute',marginTop:'0.5em'}}>Copied to clipboard</span>}
         
          <Button style={{backgroundColor:'#FFF',border:'1px solid blue',color:'black'}} onClick={()=>inputRef.current.click()}><input ref={inputRef} style={{visibility:'hidden',right:'1em',top:'10px',position:'absolute'}} type="file" onChange={(e)=>UploadManualAgreement(e)}/> Manual Agreement</Button>
          <span style={{marginRight:'20px',cursor:"pointer"}}><img onClick={() => closeAgreeCopy()} src={close} alt="close"/>
          </span>
          </div>
        <ModalBody>
          <Form >
            <FormGroup>
             { (docDetails||info)?<>

            
              <div style={{display:'flex',flexDirection:"row",justifyContent:"space-between"}}>
                <div style={{marginLeft:"10px"}}>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Aadhaar Suffix:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {info?info.aadhaar_suffix:null}</p>
                </div>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Aadhaar Name:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {info?info.name:null}</p>
                </div>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Display Name:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {info?info.display_name:null}</p>
                </div>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Created At:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {docDetails?docDetails.createdAt:null}</p>
                </div>
                </div>
                <div style={{marginRight:"20px"}}>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Aadhaar Name Validation:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',width:'20px',height:'20px',fontSize:'14px',fontFamily:'Inter-Medium',backgroundColor:`${info?info.name_validation_score>=75?'#ECFDF3':info.name_validation_score>=60 &&info.name_validation_score<75?'yellow':info.name_validation_score<60?'#FF3333':null:null}`}}><span style={{fontWeight:'500',fontFamily:'Inter-Medium',color:'black',fontSize:'17px',fontWeight:'600'}}>{info?info.name_validation_score:null}</span></p>
                </div>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Postal Code:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {info?info.postal_code:null}</p>
                </div>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Done At:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {docDetails?docDetails.updatedAt:null}</p>
                </div>
                </div>
              </div>
            
                
                  </>:<h3>Information is not yet available</h3>}
            </FormGroup>
          </Form>
        </ModalBody>
        <div style={{display:"flex",marginTop:"13px",borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",flexDirection:'row',backgroundColor:'#E9E9E9',alignItems:"center",justifyContent:'space-between',height:"60px"}}>
          
          <Button   style={{fontSize:"12px",fontFamily:"Inter-Medium",marginLeft:"20px",width:'100%',marginRight:'1em',border:"1px solid black",borderRadius:"30px",backgroundColor:"#F5F5F5",color:'black'}}
                onClick={() => retriggerAgreement() }
              >
                Retrigger Agreement
              </Button>

  
            {/* <Button onClick={() => closeAgreeCopy()}>Close Box</Button> */}
                <div style={{marginLeft:'150px',cursor:"pointer",marginRight:"20px",borderRadius:"30px",height:"33px",fontFamily:'Inter-Medium',backgroundColor:"#D32028",padding:'0.5em',display:"flex",flexDirection:"row",alignItems:"center",color:'white',width:'70%'}}>
                {decode.role==="institute_user"||decode.role==="institute_admin"?null:
                  <div  style={{textDecoration:'none'}} onClick={toggle1}>
                    <img style={{height:"18px",width:'20px',marginRight:'10px'}} src={download} alt="download"/><span style={{fontSize:"12px",fontFamily:"Inter-Medium",color:'white',textDecoration:'none'}}>Agreement</span>
                    </div>
                    }
                </div>
                </div>
      </Modal>
        </>
      )}

      {refreshMandateOpen && (
        <>
        <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
    <Modal style={{
      
      
      top:"10%",borderRadius:"10px"}} isOpen={mandateStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium',fontWeight:"600",marginLeft:'20px',marginTop:'13px'}}>Refresh Emandate</p>
    
          </div>
        
        <ModalBody>
          Click Refresh to inititate Emandate Refresh
        </ModalBody>
      
        
        {/* <Button color='info'  style={{fontSize:"12px",fontFamily:"Inter-Medium",marginLeft:"40px",marginRight:'1em',borderStyle:"none",borderRadius:"30px",width:"120px",height:'33px',backgroundColor:"#D32028"}}
              onClick={() => copyAgreementToggle() }
            >
              Copy
            </Button> */}


          {/* <Button onClick={() => closeAgreeCopy()}>Close Box</Button> */}
          <div style={{display:'flex',padding:"20px",justifyContent:'space-between'}}>

<div  onClick={()=>setRefreshMandateOpen(false)}style={{cursor:"pointer",borderRadius:"15px",height:"40px",fontFamily:'Inter-Medium',backgroundColor:'grey',textAlign:'center',color:'white',width:'5em'}}>
  Close</div>
  <div style={{backgroundColor:'#D32028',cursor:'pointer',width:'5em',height:'40px',borderRadius:"15px",color:'white',fontFamily:'Inter-Medium',textAlign:'center'}} onClick={()=>handleRefreshMandate()}>Refresh</div>

          </div>
          
         
      </Modal>
        </>
      )}
       
     
      {mandateStatusOpen && (
        <>
        <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
    <Modal style={{
      
      
      top:"10%",borderRadius:"10px"}} isOpen={mandateStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium',fontWeight:"600",marginLeft:'20px',marginTop:'13px'}}>Mandate Details</p>
          {mandateTooltipOpen&&<span style={{fontFamily:'Inter-Medium',marginLeft:"200px",height:"30px",display:"flex",alignItems:"center",fontSize:'11px',backgroundColor:'black',color:'white',borderRadius:'5em',padding:'0.5em',position:'absolute',marginTop:'0.5em'}}>Copied to clipboard</span>}
         
            <button style={{borderStyle:'solid',borderRadius:"30px",borderWidth:"1px",fontFamily:'Inter-Medium',fontSize:'12px',borderColor:'#D32027'}} onClick={()=>upimandateTrigger()}>
            <span style={{marginRight:'10px'}}><img style={{height:'20px',width:'20px'}} src={retrigger}/></span>Trigger UPI mandate
            </button>
          <span style={{marginRight:'20px',cursor:"pointer"}}>
         
            <img onClick={() => closeMandateCopy()} src={close} alt="close"/>
          {/* <Button color="info" style={{right:'1em',top:'10px',position:'absolute'}} type="button" onClick={()=>retriggerAgreement()}>Retrigger Agreement</Button> */}
          </span>
          </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
            { mandateDetails?<>
         
              <div style={{ display: 'flex', flexWrap: 'wrap',width:'100%' }}>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Name as per Appl.</span>
                
               <span 
                    
                    style={{
                      
                      textDecoration: 'none',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                      lineHeight:"30px",
                      display: 'block',
                      width: '100%',
                      wordBreak: 'break-word'
                    }}>{mandateDetails?mandateDetails.nameAsPerApplication:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Name as per Bank</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.name:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Match Percent</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?(Math.round(mandateDetails.matchPercentage*100)/100):null}%</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Account Number</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.accountNumber:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>Account Type</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.accountType:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>IFSC Code </span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.ifsc:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>Customer mobile</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.phone:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>Mandate Type </span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.mandateType:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>CustomerId</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.customerId:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>TokenId</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.tokenId:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>PaymentId</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.paymentId:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>Reason for Rejection</span>
                
               <span style={{color:"#D32027",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.remark:null}</span> 
              </p>
    </div>
    </div>
               
             </>:<h3>Information is not available</h3>} 
             
            </FormGroup>
          </Form>
        </ModalBody>
        <div style={{display:"flex",borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",flexDirection:'row',backgroundColor:'#E9E9E9',alignItems:"center",justifyContent:'space-between',height:"60px"}}>
        
        {/* <Button color='info'  style={{fontSize:"12px",fontFamily:"Inter-Medium",marginLeft:"40px",marginRight:'1em',borderStyle:"none",borderRadius:"30px",width:"120px",height:'33px',backgroundColor:"#D32028"}}
              onClick={() => copyAgreementToggle() }
            >
              Copy
            </Button> */}


          {/* <Button onClick={() => closeAgreeCopy()}>Close Box</Button> */}
          {
                mandateDetails?.status?null:<Button color="secondary" style={{border:'none',height:'2.5em',marginLeft:"10px",fontSize:'13px',borderRadius:'30px'}} onClick={()=>setRefreshMandateOpen(true)}>Refresh Emandate</Button>
          }
              <div style={{marginLeft:'150px',cursor:"pointer",marginRight:"50px",borderRadius:"30px",height:"33px",fontFamily:'Inter-Medium',backgroundColor:"#D32028",padding:'0.5em',display:"flex",flexDirection:"row",alignItems:"center",color:'white'}}>
              {decode.role==="institute_user"||decode.role==="institute_admin"?null:
                
                <a target='_blank' rel='noreferrer'href={docDetails?docDetails.signedDocumentUrl:null} style={{textDecoration:'none'}}>
                 <span style={{fontSize:"13px",color:'white',fontFamily:"Inter-Medium"}}>Download Agreement</span></a>
                  }</div></div>
      </Modal>
        </>
      )}
        {upiMandateStatusOpen && (
        <>
        <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
    <Modal style={{
      
      
      top:"10%",borderRadius:"10px"}} isOpen={upiMandateStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium',fontWeight:"600",marginLeft:'20px',marginTop:'13px'}}>UPI Mandate Details</p>
          {upiMandateTooltipOpen&&<span style={{fontFamily:'Inter-Medium',marginLeft:"200px",height:"30px",display:"flex",alignItems:"center",fontSize:'11px',backgroundColor:'black',color:'white',borderRadius:'5em',padding:'0.5em',position:'absolute',marginTop:'0.5em'}}>Copied to clipboard</span>}
          <button style={{borderStyle:'solid',borderRadius:"30px",borderWidth:"1px",fontFamily:'Inter-Medium',fontSize:'12px',borderColor:'#D32027'}} onClick={()=>upimandateTrigger()}>
            <span style={{marginRight:'10px'}}><img style={{height:'20px',width:'20px'}} src={retrigger}/></span>Trigger UPI mandate
            </button>
           
          <span style={{marginRight:'20px',cursor:"pointer"}}>
         
            <img onClick={() => setUpiMandateStatusOpen(!upiMandateStatusOpen)} src={close} alt="close"/>
          {/* <Button color="info" style={{right:'1em',top:'10px',position:'absolute'}} type="button" onClick={()=>retriggerAgreement()}>Retrigger Agreement</Button> */}
          </span>
          </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
            { upiMandateDetails || cashfreeDetails?<>
              <div style={{ display: 'flex', flexWrap: 'wrap',width:'100%' }}>
              <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
                        <p style={{display:'flex',flexDirection:"column"}}>
                          <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Name as per Appl.</span>
                          
                        <span 
                              
                              style={{
                                
                                textDecoration: 'none',
                                color: '#232323',
                                fontSize: '13px',
                                fontFamily: 'Inter-Medium',
                                lineHeight:"30px",
                                display: 'block',
                                width: '100%',
                                wordBreak: 'break-word'
                              }}>{sessionStorage.getItem("regenName")}</span> 
                        </p>
              </div>
              <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
                        <p style={{display:'flex',flexDirection:"column"}}>
                          <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Beneficiary Name</span>
                          
                        <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{upiMandateDetails?upiMandateDetails.beneficiaryName:cashfreeDetails?cashfreeDetails?.namePerVpa:null}</span> 
                        </p>
              </div>
              <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
                        <p style={{display:'flex',flexDirection:"column"}}>
                          <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Account Number</span>
                          
                        <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{upiMandateDetails?upiMandateDetails.accountNumber:null}</span> 
                        </p>
              </div>
              <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
                        <p style={{display:'flex',flexDirection:"column"}}>
                          <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Customer Vpa</span>
                          
                        <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{upiMandateDetails?upiMandateDetails.customerVpa:cashfreeDetails?cashfreeDetails?.vpa:null}</span> 
                        </p>
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                          <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Ifsc :</div>
                          
                        <div 
                              
                              style={{
                                
                                marginLeft:'1vw',
                                color: '#232323',
                                fontSize: '13px',
                                fontFamily: 'Inter-Medium',
                              
                              }}>{upiMandateDetails?upiMandateDetails.ifsc:null}</div> 
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Mandate Id :</div>
                
               <div 
                    
                    style={{
                      
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                     
                    }}>{upiMandateDetails?upiMandateDetails.mandateId:null}</div> 
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Transaction Id :</div>
                
               <div 
                    
                    style={{
                      
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                      
                    }}>{upiMandateDetails?upiMandateDetails.transactionId:null}</div> 
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Transaction Time :</div>
                
               <div 
                    
                    style={{
                      
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                     
                    }}>{upiMandateDetails?upiMandateDetails.transactionTime:cashfreeDetails?cashfreeDetails?.cfEventTime:null}</div> 
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Umrn :</div>
                
               <div 
                    
                    style={{
                      
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                      
                    }}>{upiMandateDetails?upiMandateDetails.umrn:cashfreeDetails?cashfreeDetails?.umrn:null}</div> 
              </div>
              </div>
             </>:<h3>Information is not available</h3>} 
             
            </FormGroup>
          </Form>
        </ModalBody>
        <div style={{display:"flex",borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",flexDirection:'row',backgroundColor:'#E9E9E9',alignItems:"center",justifyContent:'space-between',height:"60px"}}>
        
        {/* <Button color='info'  style={{fontSize:"12px",fontFamily:"Inter-Medium",marginLeft:"40px",marginRight:'1em',borderStyle:"none",borderRadius:"30px",width:"120px",height:'33px',backgroundColor:"#D32028"}}
              onClick={() => copyAgreementToggle() }
            >
              Copy
            </Button> */}


          {/* <Button onClick={() => closeAgreeCopy()}>Close Box</Button> */}
          {
                mandateDetails?.status?null:<Button color="secondary" style={{border:'none',height:'2.5em',marginLeft:"10px",fontSize:'13px',borderRadius:'30px'}} onClick={()=>setRefreshMandateOpen(true)}>Refresh Emandate</Button>
          }
              <div style={{marginLeft:'150px',cursor:"pointer",marginRight:"50px",borderRadius:"30px",height:"33px",fontFamily:'Inter-Medium',backgroundColor:"#D32028",padding:'0.5em',display:"flex",flexDirection:"row",alignItems:"center",color:'white'}}>
              {decode.role==='institute_user'?null:
                
                <a target='_blank' rel='noreferrer'href={docDetails?docDetails.signedDocumentUrl:null} style={{textDecoration:'none'}}>
                 <span style={{fontSize:"13px",color:'white',fontFamily:"Inter-Medium"}}>Download Agreement</span></a>
                  }</div></div>
      </Modal>
        </>
      )}

      {selfieStatusOpen && (
        <>
         <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
        <Modal style={{
      width:"500px",
       
      top:"5%",width:'400px',borderRadius:"10px"}} isOpen={selfieStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium',marginLeft:'10px'}}>Selfie Retrigger</p>
          <span style={{marginRight:'20px',cursor:"pointer"}}><img onClick={() => setSeflieStatusOpen(!selfieStatusOpen)} src={close} alt="close"/></span>

        </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
              <div style={{display:"flex",flexDirection:'column'}}>
              <label style={{fontFamily:'Inter-Medium',fontSize:'15px'}}>KID</label>
              <Input style={{fontFamily:'Inter-Medium',borderRadius:"5px",borderStyle:"solid",borderWidth:'1px',height:"30px",width:'300px'}} type="text" placeholder="KID" onChange={(e)=>setKID(e.target.value)}/>
              </div>
              <div style={{display:"flex",flexDirection:'column'}}>
              <label  style={{fontFamily:'Inter-Medium',fontSize:'15px'}}>Transaction Id</label>
              <Input  style={{fontFamily:'Inter-Medium',borderRadius:"5px",borderStyle:"solid",borderWidth:'1px',height:"30px",width:'300px'}} type="text" placeholder="Transaction ID" onChange={(e)=>setTransactionID(e.target.value)}/>
              </div>
              
             <div style={{border:'1px dashed #D0D0D0',padding:'1em',marginTop:'1em'}}>
              <ul style={{listStyle:"none"}}>
                <li>Face Liveness: <span style={{fontWeight:'bold'}}>{bankSelfieDetails?.selfieDetails?.faceLivenessResult}</span></li>
                <li>Face Liveness: <span style={{fontWeight:'bold'}}>{(bankSelfieDetails?.selfieDetails?.faceLivenessScore*100).toFixed(2)}%</span></li>
                <li>Face Match: <span style={{fontWeight:'bold'}}>{ Number(bankSelfieDetails?.selfieDetails?.facematchScore).toFixed(2)}%</span></li>
                <li>Face Source: <span style={{fontWeight:'bold'}}>{bankSelfieDetails?.selfieDetails?.facematchSource}</span></li>
                <li>Geo-Tag Accuracy: <span style={{fontWeight:'bold'}}>{ Number(bankSelfieDetails?.selfieDetails?.geoTagAccuracy).toFixed(2)} %</span></li>
                <li>Geo-Tag Address: <span style={{fontWeight:'bold'}}>{bankSelfieDetails?.selfieDetails?.geoTagAddress}</span></li>
              </ul>
            </div>
             
             
            </FormGroup>
          </Form>
        </ModalBody>
        <div style={{backgroundColor:'#E9E9E9',marginTop:"10px",borderBottomLeftRadius:'20px',borderBottomRightRadius:'20px',display:'flex',justifyContent:'space-between',padding:'20px'}}>
            <Button  style={{cursor:"pointer",fontFamily:'Inter-Medium',fontSize:"13px",color:"white",backgroundColor:"#D32028",borderRadius:"30px",borderStyle:'none',height:"30px"}}
              onClick={() => selfieRetrigger() }
            >
              Refresh Selfie
            </Button>
            <Button  style={{cursor:"pointer",fontFamily:'Inter-Medium',fontSize:"13px",color:"white",backgroundColor:"#D32028",borderRadius:"30px",borderStyle:'none',height:"30px"}}
              onClick={() => selfieReinitiate() }
            >
              Re-Initiate Selfie
            </Button>
            </div>
        
      </Modal>
        </>
      )}
       
      {digilockerStatusOpen && (
        <>
        <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
     <Modal style={{
      width:"500px",
    
      top:"15%",width:'400px',borderRadius:"10px"}} isOpen={digilockerStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium'}}>DigiLocker Re-fresh</p>
          <span style={{marginRight:'20px',cursor:"pointer"}}><img  onClick={() => setDigilockerStatusOpen(!digilockerStatusOpen)} src={close} alt="close"/></span>

        </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
            <div style={{marginLeft:'10px',display:"flex",flexDirection:'column'}}>
              <label style={{fontFamily:'Inter-Medium',fontSize:'15px'}}>KID</label>
              <Input style={{fontFamily:'Inter-Medium',borderRadius:"5px",borderStyle:"solid",borderWidth:'1px',height:"30px",width:'300px'}} type="text" placeholder="KID" onChange={(e)=>setKID(e.target.value)}/>
              </div>
              <div style={{marginLeft:'10px',display:"flex",flexDirection:'column'}}>
              <label  style={{fontFamily:'Inter-Medium',fontSize:'15px'}}>Transaction Id</label>
              <Input  style={{fontFamily:'Inter-Medium',borderRadius:"5px",borderStyle:"solid",borderWidth:'1px',height:"30px",width:'300px'}} type="text" placeholder="Transaction ID" onChange={(e)=>setTransactionID(e.target.value)}/>
              </div>
              
             
            </FormGroup>
          </Form>
        </ModalBody>
        <div style={{backgroundColor:'#E9E9E9',marginTop:"10px",borderBottomLeftRadius:'20px',borderBottomRightRadius:'20px'}}>
            <Button  style={{cursor:"pointer",marginTop:"15px",fontFamily:'Inter-Medium',fontSize:"13px",color:"white",marginLeft:"130px",backgroundColor:"#D32028",borderRadius:"30px",borderStyle:'none',height:"30px",marginBottom:'1em',marginRight:'1em'}}
               onClick={() => digilockerRetrigger() }
            >
             Retrigger DigiLocker Status
            </Button>
            </div>
        
      </Modal>
        </>
      )} 
      <Modal isOpen={fraudToolOpen} >
        <ModalHeader >Fraud Detection Indicators  <img src={close} style={{cursor:'pointer',marginLeft:'9vw'}} onClick={() => setFraudToolOpen(!fraudToolOpen)}/></ModalHeader>
        <ModalBody>
        <Table style={{ border: '1px solid #dee2e6'}}>
      <thead>
        <tr>
          <th style={{ border: '1px solid #dee2e6' }}>Indicator</th>
          <th style={{ border: '1px solid #dee2e6' }}>Status</th>
        </tr>
      </thead>
      <tbody>
        
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Agreement Name match</td>
          <td style={{ border: '1px solid #dee2e6', color: fruadDetails && fruadDetails.agreementMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer'}} onClick={()=>handleDocDownload(sessionStorage.getItem("regenUserId"),sessionStorage.getItem("regenAppId"))}>{fruadDetails && fruadDetails.agreementMatchScore !== null && fruadDetails.agreementMatchScore !== undefined ? `${fruadDetails.agreementMatchScore.toFixed(2)} %` : ''}</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Face Match</td>
          <td style={{ border: '1px solid #dee2e6', color: fruadDetails && fruadDetails.faceMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer' }}  onClick={()=>handleSelfieRetrigger(sessionStorage.getItem("regenUserId"),sessionStorage.getItem("regenAppId"))}>{fruadDetails && fruadDetails.faceMatchScore !== null && fruadDetails.faceMatchScore !== undefined ? `${fruadDetails.faceMatchScore.toFixed(2)} %` : ''}</td>
        </tr>
        {/* <tr>
          <td style={{ border: '1px solid #dee2e6' }}>OTP Match</td>
          <td style={{ border: '1px solid #dee2e6', color: fruadDetails && fruadDetails.otpScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer' }} onClick={()=>handleSelfieRetrigger(sessionStorage.getItem("regenUserId"),sessionStorage.getItem("regenAppId"))}>{fruadDetails && fruadDetails.otpScore !== null  &&  fruadDetails.otpScore !== undefined?`${fruadDetails.otpScore.toFixed(2)} %` : ''}</td>
        </tr> */}
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Location within 100KM</td>
          <td style={{ border: '1px solid #dee2e6', color: fruadDetails && fruadDetails.distanceRange > 100 ? '#D22129' : '#12B76A' }} >{fruadDetails && fruadDetails.distanceRange ? `${fruadDetails.distanceRange} KM` : ''}</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Name Match in Bank</td>
          <td style={{ border: '1px solid #dee2e6' , color: fruadDetails && fruadDetails.bankAccountNameMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer'}} onClick={()=>handleMandateDetails(sessionStorage.getItem("regenUserId"),sessionStorage.getItem("regenAppId"))}>  {fruadDetails && fruadDetails.bankAccountNameMatchScore !== null && fruadDetails.bankAccountNameMatchScore !== undefined ? `${fruadDetails.bankAccountNameMatchScore.toFixed(2)} %` : ''}
</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Pan Name Match</td>
          <td style={{ border: '1px solid #dee2e6' , color: fruadDetails && fruadDetails.panMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer'}}>  {fruadDetails && fruadDetails.panMatchScore !== null && fruadDetails.panMatchScore !== undefined ? `${fruadDetails.panMatchScore.toFixed(2)} %` : ''}
          </td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Mandate Provider</td>
          <td style={{ border: '1px solid #dee2e6', color: '#667085'}} >{fruadDetails?(fruadDetails?.mandateProvider === 1 ? (<p>DigioUpiMandate</p>):fruadDetails?.mandateProvider === 2 ? (<p>RazorpayMandate</p>):null) :null}</td>
        </tr>
        {/* <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Consent Location Match</td>
          <td style={{ border: '1px solid #dee2e6',color: fruadDetails && fruadDetails.consentLocationMatch === 1 ? '#D22129' : (fruadDetails && fruadDetails.consentLocationMatch === 100 ? '#12B76A' : '')}} >{fruadDetails && fruadDetails.consentLocationMatch !== null && fruadDetails.consentLocationMatch !== undefined? `${fruadDetails.consentLocationMatch}` : ''}</td>
        </tr> */}
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Consent Range</td>
          <td style={{ border: '1px solid #dee2e6', color: fruadDetails && fruadDetails.consentRange > 100 ? '#D22129' : '#12B76A' }} >{fruadDetails && fruadDetails.consentRange !== null && fruadDetails.consentRange !== undefined? `${fruadDetails.consentRange} KM` : ''}</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Name Match in BankStatement</td>
          <td style={{ border: '1px solid #dee2e6' , color: fruadDetails && fruadDetails.bankStatementMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer'}}>  {fruadDetails && fruadDetails.bankStatementMatchScore !== null && fruadDetails.bankStatementMatchScore !== undefined ? `${fruadDetails.bankStatementMatchScore.toFixed(2)} %` : ''}
          </td>
        </tr>
        {/* <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Name Match in AccountAggregator</td>
          <td style={{ border: '1px solid #dee2e6' , color: fruadDetails && fruadDetails.aaNameMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer'}}>  {fruadDetails && fruadDetails.aaNameMatchScore !== null && fruadDetails.aaNameMatchScore !== undefined ? `${fruadDetails.aaNameMatchScore.toFixed(2)} %` : ''}
          </td>
        </tr> */}
      </tbody>
    </Table>
          
          
        </ModalBody>
        
      </Modal>
        {isLoading ? (
          <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%',backgroundColor:'white',height:'100vh'}}>
          <CircularProgress />
        </Box>
        ):(
          <>
          <div className='tables' style={{ marginTop: '20px' }}>
            <table >
              <thead className='table-heading'>
                <tr>
                  <th style={{ fontSize: '15px',width:'6em',borderTopLeftRadius:'8px'}} id="appId">
                    <span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>Appl. Id</span>
                    {/* <img style={{cursor:'pointer',width:'0.9vw',marginLeft:'2px'}} src={Sort} alt="sort"/> */}
                  </th>
                  <th  id="appname" style={{width:'15em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px'}}>Appl. Name</span></th>
                  {(decode.role==="institute_user"||decode.role=="institute_admin" ) && decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? 
                   <th  style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'10em' }} id="appname">
                   <span style={{marginLeft:'10px',whiteSpace:'nowrap'}}></span> Student Name
                   </th>:null
                  }
                  <th  id="insName"style={{width:'15em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'10px',whiteSpace:'nowrap'}}>Institute Name</span></th>
                  <th  id="pfAmount"style={{width:'13em'}}><span style={{marginLeft:'18px',fontFamily:'Inter',fontSize:'0.9vw'}}>DigiLocker</span></th>
                  <th  id="selfie"style={{width:'13em'}}><span style={{marginLeft:'18px',fontFamily:'Inter',fontSize:'0.9vw'}}>Selfie</span></th>
                  <th  id="agreement"style={{width:'13em'}}><span style={{marginLeft:'7px',fontFamily:'Inter',fontSize:'0.9vw'}}>Agreement</span></th>
                  <th  id="eMandate"style={{width:'13em'}}><span style={{marginLeft:'7px',fontFamily:'Inter',fontSize:'0.9vw'}}>EMandate</span></th>
                  <th  style={{width:'4em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw'}}>Frd.Checks</span></th>
                  <th  id="amt"style={{marginLeft:'18px',fontFamily:'Inter',fontSize:'0.9vw',width:'6em'}}><span style={{marginLeft:'10px'}}></span>Amount</th>
                  <th  id="tenure"style={{width:'6em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw'}}>Tenure</span></th>
                  { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:<th id="fundSource"  style={{width:'8em'}} ><span style={{marginLeft:'5px',fontFamily:'Inter',fontSize:'0.9vw'}}>FundSrc</span></th>}
                  {/* {decode.role==="institute_user"||decode.role==="institute_admin"?null:<th id="cra" style={{textAlign:'center', fontSize: '0.9vw',width:'6em'}} ><span style={{fontFamily:'Inter',fontSize:'0.9vw'}}>CRA</span></th>} */}
                 {decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender" ? null :                  <th  id="pmt"style={{width:'6em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',display:'flex',flexWrap:'nowrap'}}>Pmt.Status</span></th>
}
                  { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:<th id="actions"  style={{width:'6em'}}><span style={{marginLeft:'10px',fontFamily:'Inter',fontSize:'0.9vw',borderTopRightRadius:'8px'}}>Actions</span></th>}
                </tr>
              </thead>
              {tableData.map((item,index) => {
            
                  return (
                    <tbody className='table-body' key={item.applicationId}>
                      <tr className='table-row'  style={{ lineHeight: '20px' }}>
                       {decode.role!=="institute_user"&&decode.role!=="institute_admin"?<td >
                          <span onClick={()=>handleApplicationProfile(item.applicationId,item.userId,item.coapplicantId)} style={{ cursor:'pointer', fontSize: '0.9vw', fontWeight: '500', fontFamily:'Inter', width: '6.5em' ,color:'#101828',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>{item.applicationId}</span>
                        </td>:<td  style={{  fontSize: '0.9vw', fontWeight: '400', width:'6.5em',fontFamily:"Inter" ,paddingLeft:'10px'}}>
                          {item.applicationId}
                        </td>
                        }
                        {decode.role==="institute_user"||decode.role==="institute_admin"?<td
                          style={{
                             fontSize: '0.9vw',
                            maxWidth: '15em',
                            fontFamily:"Inter",
                          }}
                         
                        >
                           <span title={item.name} style={{fontFamily:"Inter",fontSize:"0.9vw", color: '#101828', width: '10em',lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName ? (
                            <span title={item.coapplicantName} style={{fontFamily:"Inter",fontSize:"0.9vw", color: '#101828',lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                              {' '}
                              {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                            </span>
                          ) : (
                            ''
                          )}
                        </td>:<td
                          style={{
                             fontSize: '0.9vw',
                            color: '#101828',
                            cursor: 'pointer',
                            maxWidth: '15em',
                            fontFamily:"Inter",
                          }}
                        >
                          <span title={item.name} style={{fontFamily:"Inter",fontSize:"0.9vw" , color: '#101828', width: '10em',lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}onClick={() => handleUser(item)}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName ? (
                            <span title={item.coapplicantName} style={{fontFamily:"Inter", width: '10em',fontSize:"0.9vw" , color: '#101828',lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}onClick={() => handleCoApp(item)}>
                              {' '}
                              {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                            </span>
                          ) : (
                            ''
                          )}
                        </td>}
                        {(decode.role==="institute_user"||decode.role==="institute_admin")&&decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? <td title={item.studentName} >
                         <span style={{width: '10em', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}> {item.studentName ? item.studentName : "-"}</span>
                        </td>:null}
                        <td title={item.instituteName}><span style={{  fontSize: '0.9vw', width: '8em', color: '#667085',fontFamily:"Inter" ,display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' ,lineHeight: '1.5em'}}>{item.instituteName?item.instituteName:'-'}</span></td>

                        <td style={{  fontSize: '12px', width: '6.5em', textAlign: 'center' }}>
                          
                          {item.coapplicantDigilockerStatus?<>
                          <p  
                          style={{cursor:'pointer',width: '6.5em',marginTop:'1em',
                          backgroundColor:`${item.digilockerStatus==='Pass'?'#ECFDF3':`${item.digilockerStatus==='Not Initiated'?'#898989':`${item.digilockerStatus==='Failed'?'#FEEAEB':`${item.digilockerStatus==='Initiated'?'#EFE4FF':`${item.digilockerStatus==='Face mismatch'?"#FFF6E8":''}`}`}`}`}`,
                          color:`${item.digilockerStatus==='Pass'?'#12B76A':`${item.digilockerStatus==='Not Initiated'?'white':`${item.digilockerStatus==='Failed'?'red':`${item.digilockerStatus==='Initiated'?'#6E24E7':`${item.digilockerStatus==='Face mismatch'?"#F8A31F":''}`}`}`}`}`,
                          borderRadius:'1em',fontWeight:'600'}} 
                          onClick={()=>handleDigilockerRetrigger(item.digilockerStatus,item.userId,item.applicationId)}
                          >
                            {item.digilockerStatus ? (
                            item.digilockerStatus === "Pass" ? (
                              <span style={{fontSize:'12px'}}>
                                <img src={activeDot} /> Pass
                              </span>
                            ) : item.digilockerStatus === "Initiated" ? (
                              <span style={{fontSize:'12px'}}>
                                <img src={initiatedDot} /> Initiated
                              </span>
                            ) :item.digilockerStatus === "Not Initiated" ? (
                              <span style={{fontSize:'12px'}}>
                                <img src={todoDot} /> To Do
                              </span>
                            ) : (
                              item.digilockerStatus
                            )
                          ) : (
                            <span style={{fontSize:'12px'}}>NA</span>
                          )}
                          </p>
                          <p  
                          style={{cursor:'pointer',width: '6.5em',marginTop:'1em',
                          backgroundColor:`${item.coapplicantDigilockerStatus==='Pass'?'#ECFDF3':`${item.coapplicantDigilockerStatus==='Not Initiated'?'#898989':`${item.coapplicantDigilockerStatus==='Failed'?'#FEEAEB':`${item.coapplicantDigilockerStatus==='Initiated'?'#EFE4FF':`${item.coapplicantDigilockerStatus==='Face mismatch'?"#FFF6E8":''}`}`}`}`}`,
                          color:`${item.coapplicantDigilockerStatus==='Pass'?'#12B76A':`${item.coapplicantDigilockerStatus==='Not Initiated'?'white':`${item.coapplicantDigilockerStatus==='Failed'?'red':`${item.coapplicantDigilockerStatus==='Initiated'?'#6E24E7':`${item.coapplicantDigilockerStatus==='Face mismatch'?"#F8A31F":''}`}`}`}`}`,
                          borderRadius:'1em',fontWeight:'600'}} 
                          onClick={()=>handleDigilockerRetrigger(item.coapplicantDigilockerStatus,item.coapplicantId,item.applicationId)}>
                            {item.coapplicantDigilockerStatus ? (
                              item.coapplicantDigilockerStatus === "Pass" ? (
                                <span style={{fontSize:'12px'}}>
                                  <img src={activeDot} /> Pass
                                </span>
                              ) : item.coapplicantDigilockerStatus === "Initiated" ? (
                                <span style={{fontSize:'12px'}}>
                                  <img src={initiatedDot} /> Initiated
                                </span>
                              ) :item.coapplicantDigilockerStatus === "Not Initiated" ? (
                                <span style={{fontSize:'12px'}}>
                                  <img src={todoDot} /> To Do
                                </span>
                              ) : (
                                item.coapplicantDigilockerStatus
                              )
                            ) : (
                              <span style={{fontSize:'12px'}}>NA</span>
                            )}
                            
                            </p>
                          </>:
                          <p  
                          style={{cursor:'pointer',width: '6.5em',marginTop:'1em',
                          backgroundColor:`${item.digilockerStatus==='Pass'?'#ECFDF3':`${item.digilockerStatus==='Not Initiated'?'#898989':`${item.digilockerStatus==='Failed'?'#FEEAEB':`${item.digilockerStatus==='Initiated'?'#EFE4FF':`${item.digilockerStatus==='Face mismatch'?"#FFF6E8":''}`}`}`}`}`,
                          color:`${item.digilockerStatus==='Pass'?'#12B76A':`${item.digilockerStatus==='Not Initiated'?'white':`${item.digilockerStatus==='Failed'?'red':`${item.digilockerStatus==='Initiated'?'#6E24E7':`${item.digilockerStatus==='Face mismatch'?"#F8A31F":''}`}`}`}`}`,
                          borderRadius:'1em',fontWeight:'600'}} 
                          onClick={()=>handleDigilockerRetrigger(item.digilockerStatus,item.userId,item.applicationId)}>
                            {item.digilockerStatus ? (
                            item.digilockerStatus === "Pass" ? (
                              <span style={{fontSize:'12px'}}>
                                <img src={activeDot} /> Pass
                              </span>
                            ) : item.digilockerStatus === "Initiated" ? (
                              <span style={{fontSize:'12px'}}>
                                <img src={initiatedDot} /> Initiated
                              </span>
                            ) :item.digilockerStatus === "Not Initiated" ? (
                              <span style={{fontSize:'12px'}}>
                                <img src={todoDot} /> To Do
                              </span>
                            ) :
                             (
                              item.digilockerStatus
                            )
                          ) : (
                            <span style={{fontSize:'12px'}}>NA</span>
                          )}
                          </p>}
                        </td>

                        <td>
                         <p key={item.userId}  id="selfie" onClick={()=>handleSelfieRetrigger(item.userId,item.applicationId)} 
                         style={{ cursor:'pointer', fontWeight:'600',fontSize: '12px', width: '6.5em',marginTop:'1em',
                         backgroundColor:`${item.selfie==='Pass'?'#ECFDF3':`${item.selfie==='Not Initiated'?'#898989':`${item.selfie==='Failed'?'#FEEAEB':`${item.selfie==='Initiated'?'#EFE4FF':`${item.selfie==='Face mismatch'?"#FFF6E8":''}`}`}`}`}`,
                         color:`${item.selfie==='Pass'?'#12B76A':`${item.selfie==='Not Initiated'?'white':`${item.selfie==='Failed'?'red':`${item.selfie==='Initiated'?'#6E24E7':`${item.selfie==='Face mismatch'?"#F8A31F":''}`}`}`}`}`,
                          textAlign:'center',borderRadius:'1em'}}> 
                         {item.selfie?(item.selfie==="Pass"?<span><img src={activeDot} /> Pass</span>:item.selfie==="Failed"?<span><img src={closedDot} /> Failed</span>:item.selfie==="Not Initiated"?<span><img src={todoDot}/> To Do</span>:item.selfie==="Initiated"?<span><img src={initiatedDot}/> Initiated</span>:item.selfie==="Face mismatch"?<span><img src={pendingDot}/> Attn!</span>:item.selfie):"NA"}
                         </p>
                         {item.coapplicantSelfie?<p key={item.userId}  id="selfie" onClick={()=>handleSelfieRetrigger(item.coapplicantId,item.applicationId)}
                         style={{ cursor:'pointer', fontWeight:'600',fontSize: '12px', width: '6.5em',marginTop:'1em',
                         backgroundColor:`${item.coapplicantSelfie==='Pass'?'#ECFDF3':`${item.coapplicantSelfie==='Not Initiated'?'#898989':`${item.coapplicantSelfie==='Failed'?'#FEEAEB':`${item.coapplicantSelfie==='Initiated'?'#EFE4FF':`${item.coapplicantSelfie==='Face mismatch'?"#FFF6E8":''}`}`}`}`}`,
                         color:`${item.coapplicantSelfie==='Pass'?'#12B76A':`${item.coapplicantSelfie==='Not Initiated'?'white':`${item.coapplicantSelfie==='Failed'?'red':`${item.coapplicantSelfie==='Initiated'?'#6E24E7':`${item.coapplicantSelfie==='Face mismatch'?"#F8A31F":''}`}`}`}`}`,
                          textAlign:'center',borderRadius:'1em'}}>
                          {item.coapplicantSelfie?(item.coapplicantSelfie==="Pass"?<span><img src={activeDot} /> Pass</span>:item.coapplicantSelfie==="Failed"?<span><img src={closedDot} /> Failed</span>:item.coapplicantSelfie==="Not Initiated"?<span><img src={todoDot}/> To Do</span>:item.coapplicantSelfie==="Initiated"?<span><img src={initiatedDot}/> Initiated</span>:item.coapplicantSelfie==="Face mismatch"?<span><img src={pendingDot}/> Attn!</span>:item.coapplicantSelfie):"NA"}
                
                         </p>:null}
                         {/* <span style={{fontFamily:'Inter',fontSize:'13px',marginLeft:'30px'}}>NA</span> */}
                        </td>
                        <td>
                          {decode.role==="institute_user"||decode.role==="institute_admin"?<>
                          <p key={item.userId} id="signed"
                           style={{cursor:'pointer',fontSize: '12px', width: '6.5em',marginTop:'1em',fontWeight:'600',fontFamily:"Inter", 
                           backgroundColor:item.agreement==='Signed'||item.agreement==='Manual'?'#ECFDF3':item.agreement==="Regenerated"?'#00AD83':item.agreement==="Pending Coapplicant"?'#FFF6E8':item.agreement==='Not Initiated'?'#898989':item.agreement==='Pending'?'#FFF6E8':item.agreement==='Initiated'?'#EFE4FF':item.agreement==='Invalid'?'#FFF6E8':'',textDecoration:item.agreement==='Signed'||item.agreement==='Manual'?'underline':'none',
                           color:item.agreement==='Signed'||item.agreement==='Manual'?'#12B76A':item.agreement==="Regenerated"?'white':item.agreement==="Pending Coapplicant"?'#F8A31F':item.agreement==='Not Initiated'?'white':item.agreement==='Pending'?'#F8A31F':item.agreement==='Initiated'?'#6E24E7':item.agreement==='Invalid'?'#F8A31F':'',
                           textAlign:'center',borderRadius:'1em'}}> 
                            {item.agreement?(item.agreement==="Pending"?<span><img src={pendingDot}/> Pending</span>:item.agreement==="Signed"?<span><img src={activeDot}/> Signed</span>:item.agreement==="Invalid"?<span><img src={pendingDot}/> Attn!</span>:item.agreement==="Not Initiated"?<span><img src={todoDot}/> To Do</span>:item.agreement==="Regenerated"?<span><img src={activeDot}/> Regen</span>:item.agreement==="Pending Coapplicant"?<span><img src={pendingDot}/> Co-app P</span>:item.agreement==='Manual'?<span><img src={activeDot}/>Manual</span>:item.agreement):"NA"}
                          </p>
                            {item.coapplicantAgreement?
                            <p key={item.userId} id="signed"
                             style={{cursor:'pointer',fontSize: '12px', width: '6.5em',marginTop:'1em',fontWeight:'600',fontFamily:"Inter",
                          backgroundColor:item.coapplicantAgreement==='Signed'||item.coapplicantAgreement==='Manual'?'#ECFDF3':item.coapplicantAgreement==="Regenerated"?'#00AD83':item.coapplicantAgreement==="Pending Coapplicant"?'#FFF6E8':item.coapplicantAgreement==='Not Initiated'?'#898989':item.coapplicantAgreement==='Pending'?'#FFF6E8':item.coapplicantAgreement==='Initiated'?'#EFE4FF':item.coapplicantAgreement==='Invalid'?'#FFF6E8':'',textDecoration:item.coapplicantAgreement==='Signed'||item.coapplicantAgreement==='Manual'?'underline':'none',
                          color:item.coapplicantAgreement==='Signed'||item.coapplicantAgreement==='Manual'?'#12B76A':item.coapplicantAgreement==="Regenerated"?'white':item.coapplicantAgreement==="Pending Coapplicant"?'#F8A31F':item.coapplicantAgreement==='Not Initiated'?'white':item.coapplicantAgreement==='Pending'?'#F8A31F':item.coapplicantAgreement==='Initiated'?'#6E24E7':item.coapplicantAgreement==='Invalid'?'#F8A31F':'',
                          textAlign:'center',borderRadius:'1em'}}>
                            
                            {item.coapplicantAgreement?(item.coapplicantAgreement==="Pending"?<span><img src={pendingDot}/> Pending</span>:item.coapplicantAgreement==="Signed"?<span><img src={activeDot}/> {item.coapplicantAgreement}</span>:item.coapplicantAgreement==="Invalid"?<span><img src={pendingDot}/> Attn!</span>:item.coapplicantAgreement==="Not Initiated"?<span><img src={todoDot}/> To Do</span>:item.coapplicantAgreement==="Regenerated"?<span><img src={activeDot}/> Regen</span>:item.coapplicantAgreement==="Pending Coapplicant"?<span><img src={pendingDot}/> Co-app P</span>:item.coapplicantAgreement==='Manual'?<span><img src={activeDot}/>Manual</span>:item.coapplicantAgreement):"NA"}
                          </p>:null}</>:<><p onClick={()=>handleDocDownload(item.userId,item.applicationId)} key={item.userId} id="signed" 
                            style={{cursor:'pointer',fontSize: '12px', width: '6.5em',marginTop:'1em',fontFamily:"Inter",fontWeight:'600',
                          backgroundColor:item.agreement==='Signed'||item.agreement==='Manual'?'#ECFDF3':item.agreement==="Regenerated"?'#00AD83':item.agreement==='Not Initiated'?'#898989':item.agreement==='Pending'?'#FFF6E8':item.agreement==='Initiated'?'#EFE4FF':item.agreement==='Invalid'?'#FFF6E8':item.agreement==="Pending Coapplicant"?"#FFF6E8":'',textDecoration:item.agreement==='Signed'||item.agreement==='Manual'?'underline':'none',
                          color:item.agreement==='Signed'||item.agreement==='Manual'?'#12B76A':item.agreement==="Regenerated"?'white':item.agreement==="Pending Coapplicant"?'#F8A31F':item.agreement==='Not Initiated'?'white':item.agreement==='Pending'?'#F8A31F':item.agreement==='Initiated'?'#6E24E7':item.agreement==='Invalid'?'#F8A31F':'', 
                      textAlign:'center',borderRadius:'1em'}}>
                        
                            {item.agreement?(item.agreement==="Pending"?<span><img src={pendingDot}/> Pending</span>:item.agreement==="Signed"?<span><img src={activeDot}/>{item.agreement}</span>:item.agreement==="Invalid"?<span><img src={pendingDot}/> Attn!</span>:item.agreement==="Not Initiated"?<span><img src={todoDot}/> To Do</span>:item.agreement==="Regenerated"?<span><img src={activeDot}/> Regen</span>:item.agreement==="Pending Coapplicant"?<span><img src={pendingDot}/> Co-app P</span>:item.agreement==='Manual'?<span><img src={activeDot}/>Manual</span>:item.agreement):"NA"}
                          </p>{item.coapplicantAgreement?<p onClick={()=>handleDocDownload(item.coapplicantId,item.applicationId)} key={item.userId} id="signed"
                             style={{cursor:'pointer',fontSize: '12px',fontFamily:"Inter", width: '6.5em',marginTop:'1em',fontWeight:'600',fontFamily:"Inter",
                          backgroundColor:item.coapplicantAgreement==='Signed'||item.coapplicantAgreement==='Manual'?'#ECFDF3':item.coapplicantAgreement==="Regenerated"?'#00AD83':item.coapplicantAgreement==='Not Initiated'?'#898989':item.coapplicantAgreement==='Pending'?'#FFF6E8':item.coapplicantAgreement==='Initiated'?'#EFE4FF':item.coapplicantAgreement==='Invalid'?'#FFF6E8':item.coapplicantAgreement==="Pending Coapplicant"?"#FFF6E8":'',textDecoration:item.coapplicantAgreement==='Signed'||item.coapplicantAgreement==='Manual'?'underline':'none',
                          color:item.coapplicantAgreement==='Signed'||item.coapplicantAgreement==='Manual'?'#12B76A':item.coapplicantAgreement==="Regenerated"?'white':item.coapplicantAgreement==="Pending Coapplicant"?'#F8A31F':item.coapplicantAgreement==='Not Initiated'?'white':item.coapplicantAgreement==='Pending'?'#F8A31F':item.coapplicantAgreement==='Initiated'?'#6E24E7':item.coapplicantAgreement==='Invalid'?'#F8A31F':'', 
                         textAlign:'center',borderRadius:'1em'}}>
                           
                            {item.coapplicantAgreement?(item.coapplicantAgreement==="Invalid"?<span><img src={pendingDot}/> Attn!</span>:item.coapplicantAgreement==="Signed"?<span><img src={activeDot}/> {item.coapplicantAgreement}</span>:item.coapplicantAgreement==="Not Initiated"?<span><img src={todoDot}/> To Do</span>:item.coapplicantAgreement==="Regenerated"?<span><img src={activeDot}/> Regen</span>:item.coapplicantAgreement==="Pending Coapplicant"?<span><img src={pendingDot}/> Co-app </span>:item.coapplicantAgreement==='Manual'?<span><img src={activeDot}/>Manual</span>:item.coapplicantAgreement):"NA"}
                          </p>:null}</>}
                          
                        </td>
                        <td >
                          <div style={{display:'block'}}>
                           {decode.role==="institute_user"||decode.role==="institute_admin"?
                           <>
                           <p id="coapplicantEmandate"
                           style={{ 
                            fontWeight:'600',marginTop:'1em',color:item.emandate==='Invalid Bank Details'?'#A50021':item.emandate==='Not Applicable'?'#000':'white',textAlign:'center',borderRadius:'1em', fontSize: '12px',cursor:'pointer',fontFamily:"Inter",whiteSpace:'nowrap', width: '6.5em',
                            color:`${item.emandate==='Upi Mandate Initiated'?'#6E24E7':item.emandate==='Upi Mandate Failed'?'#D22129':item.emandate==='Upi Mandate Done'?'#12B76A':item.emandate==='Pass'?'#12B76A':`${item.emandate==='Not Initiated'?'white':`${item.emandate==='Rejected'?'#D22129':`${item.emandate==='Partial'?'white':`${item.emandate==='Initiated'?'#6E24E7':`${item.emandate==='Invalid Bank Details'?'#F8A31F':''}`}`}`}`}`}`,
                            backgroundColor:`${item.emandate==='Upi Mandate Initiated'?'#EFE4FF':item.emandate==='Upi Mandate Done'?'#ECFDF3':item.emandate==='Pass'?'#ECFDF3':`${item.emandate==='Not Initiated'?'#898989':`${item.emandate==='Rejected'?'#FEEAEB':`${item.emandate==='Partial'?'#BA55D3':`${item.emandate==='Initiated'?'#EFE4FF':`${item.emandate==='Invalid Bank Details'?'#FFF6E8':`${item.emandate==='Not Applicable'?'#FFF':''}`}`}`}`}`}`}`}}>
                    
                    {item.emandate?
                           (item.emandate==="Rejected"?<span><img src={closedDot}/> Rejected</span>
                           :item.emandate==="Upi Mandate Failed"?<span title='Upi Mandate Failed'><img src={closedDot}/> Upi M</span>
                           :item.emandate==="Pass"?<span><img src={activeDot}/> Pass</span>
                           :item.emandate==="Upi Mandate Done"?<span title='Upi Mandate Done'><img src={activeDot}/> Upi M</span>
                           :item.emandate==="Initiated"?<span><img src={initiatedDot}/> Initiated</span>
                           :item.emandate==="Upi Mandate Initiated"?<span title='Upi Mandate Initiated'><img src={initiatedDot}/>Upi M</span>
                           :item.emandate==="Not Initiated"?<span><img src={todoDot}/> To Do</span>
                           :item.emandate==="Partial"?<span><img src={todoDot}/>Partial</span>
                           :item.emandate==="Invalid Bank Details"?<span><img src={pendingDot}/>Attn! </span>
                           :item.emandate==="Not Applicable"?<span><img src={todoDot}/>NA</span>
                           :item.emandate):"NA"}                            
                            </p>
                            {item.coapplicantEmandate?<p id="emandate"   style={{ 
                              fontWeight:'600',marginTop:'1em',color:item.coapplicantEmandate==='Invalid Bank Details'?'#A50021':item.coapplicantEmandate==='Not Applicable'?'#000':'white',textAlign:'center',borderRadius:'1em', fontSize: '12px',cursor:'pointer',fontFamily:"Inter",whiteSpace:'nowrap', width: '6.5em',
                              color:`${item.coapplicantEmandate==='Upi Mandate Initiated'?'#6E24E7':item.coapplicantEmandate==='Upi Mandate Failed'?'#D22129':item.coapplicantEmandate==='Upi Mandate Done'?'#12B76A':item.coapplicantEmandate==='Pass'?'#12B76A':`${item.coapplicantEmandate==='Not Initiated'?'white':`${item.coapplicantEmandate==='Rejected'?'#D22129':`${item.coapplicantEmandate==='Partial'?'white':`${item.coapplicantEmandate==='Initiated'?'#6E24E7':`${item.coapplicantEmandate==='Invalid Bank Details'?'#F8A31F':''}`}`}`}`}`}`,
                              backgroundColor:`${item.coapplicantEmandate==='Upi Mandate Initiated'?'#EFE4FF':item.coapplicantEmandate==='Upi Mandate Done'?'#ECFDF3':item.coapplicantEmandate==='Pass'?'#ECFDF3':`${item.coapplicantEmandate==='Not Initiated'?'#898989':`${item.coapplicantEmandate==='Rejected'?'#FEEAEB':`${item.coapplicantEmandate==='Partial'?'#BA55D3':`${item.coapplicantEmandate==='Initiated'?'#EFE4FF':`${item.coapplicantEmandate==='Invalid Bank Details'?'#FFF6E8':`${item.coapplicantEmandate==='Not Applicable'?'#FFF':''}`}`}`}`}`}`}`}}>
           
           {item.coapplicantEmandate?
                           (item.coapplicantEmandate==="Rejected"?<span><img src={closedDot}/> Rejected</span>
                           :item.coapplicantEmandate==="Upi Mandate Failed"?<span title='Upi Mandate Failed'><img src={closedDot}/> Upi M</span>
                           :item.coapplicantEmandate==="Pass"?<span><img src={activeDot}/> Pass</span>
                           :item.coapplicantEmandate==="Upi Mandate Done"?<span title='Upi Mandate Done'><img src={activeDot}/> Upi M</span>
                           :item.coapplicantEmandate==="Initiated"?<span><img src={initiatedDot}/> Initiated</span>
                           :item.coapplicantEmandate==="Upi Mandate Initiated"?<span title='Upi Mandate Initiated'><img src={initiatedDot}/>Upi M</span>
                           :item.coapplicantEmandate==="Not Initiated"?<span><img src={todoDot}/> To Do</span>
                           :item.coapplicantEmandate==="Partial"?<span><img src={todoDot}/>Partial</span>
                           :item.coapplicantEmandate==="Invalid Bank Details"?<span><img src={pendingDot}/>Attn! </span>
                           :item.coapplicantEmandate==="Not Applicable"?<span><img src={todoDot}/>NA</span>
                           :item.coapplicantEmandate):"NA"}                            </p>:null}</>:
                            <>
                            <p id="emandate" onClick={()=>handleMandateDetails(item.applicationId,item.userId,item.name,item.emandate,item.cra)} style={{ 
                            fontWeight:'600',marginTop:'1em',color:item.emandate==='Invalid Bank Details'?'#A50021':item.emandate==='Not Applicable'?'#000':'white',textAlign:'center',borderRadius:'1em', fontSize: '12px',cursor:'pointer',fontFamily:"Inter",whiteSpace:'nowrap', width: '6.5em',
                            color:`${item.emandate==='Upi Mandate Initiated'?'#6E24E7':item.emandate==='Upi Mandate Failed'?'#D22129':item.emandate==='Upi Mandate Done'?'#12B76A':item.emandate==='Pass'?'#12B76A':`${item.emandate==='Not Initiated'?'white':`${item.emandate==='Rejected'?'#D22129':`${item.emandate==='Partial'?'white':`${item.emandate==='Initiated'?'#6E24E7':`${item.emandate==='Invalid Bank Details'?'#F8A31F':''}`}`}`}`}`}`,
                            backgroundColor:`${item.emandate==='Upi Mandate Initiated'?'#EFE4FF':item.emandate==='Upi Mandate Done'?'#ECFDF3':item.emandate==='Pass'?'#ECFDF3':`${item.emandate==='Not Initiated'?'#898989':`${item.emandate==='Rejected'?'#FEEAEB':`${item.emandate==='Partial'?'#BA55D3':`${item.emandate==='Initiated'?'#EFE4FF':`${item.emandate==='Invalid Bank Details'?'#FFF6E8':`${item.emandate==='Not Applicable'?'#FFF':''}`}`}`}`}`}`}`}}>
                             
                           {item.emandate?
                           (item.emandate==="Rejected"?<span><img src={closedDot}/> Rejected</span>
                           :item.emandate==="Upi Mandate Failed"?<span title='Upi Mandate Failed'><img src={closedDot}/> Upi M</span>
                           :item.emandate==="Pass"?<span><img src={activeDot}/> Pass</span>
                           :item.emandate==="Upi Mandate Done"?<span title='Upi Mandate Done'><img src={activeDot}/> Upi M</span>
                           :item.emandate==="Initiated"?<span><img src={initiatedDot}/> Initiated</span>
                           :item.emandate==="Upi Mandate Initiated"?<span title='Upi Mandate Initiated'><img src={initiatedDot}/>Upi M</span>
                           :item.emandate==="Not Initiated"?<span><img src={todoDot}/> To Do</span>
                           :item.emandate==="Partial"?<span><img src={todoDot}/>Partial</span>
                           :item.emandate==="Invalid Bank Details"?<span><img src={pendingDot}/>Attn! </span>
                           :item.emandate==="Not Applicable"?<span><img src={todoDot}/>NA</span>
                           :item.emandate):"NA"}
                           </p>
                            {item.coapplicantEmandate?<p id="emandate" onClick={()=>handleMandateDetails(item.applicationId,item.coapplicantId,item.name,item.coapplicantEmandate,item.cra)}
                             style={{ 
                              fontWeight:'600',marginTop:'1em',color:item.coapplicantEmandate==='Invalid Bank Details'?'#A50021':item.coapplicantEmandate==='Not Applicable'?'#000':'white',textAlign:'center',borderRadius:'1em', fontSize: '12px',cursor:'pointer',fontFamily:"Inter",whiteSpace:'nowrap', width: '6.5em',
                              color:`${item.coapplicantEmandate==='Upi Mandate Initiated'?'#6E24E7':item.coapplicantEmandate==='Upi Mandate Failed'?'#D22129':item.coapplicantEmandate==='Upi Mandate Done'?'#12B76A':item.coapplicantEmandate==='Pass'?'#12B76A':`${item.coapplicantEmandate==='Not Initiated'?'white':`${item.coapplicantEmandate==='Rejected'?'#D22129':`${item.coapplicantEmandate==='Partial'?'white':`${item.coapplicantEmandate==='Initiated'?'#6E24E7':`${item.coapplicantEmandate==='Invalid Bank Details'?'#F8A31F':''}`}`}`}`}`}`,
                              backgroundColor:`${item.coapplicantEmandate==='Upi Mandate Initiated'?'#EFE4FF':item.coapplicantEmandate==='Upi Mandate Done'?'#ECFDF3':item.coapplicantEmandate==='Pass'?'#ECFDF3':`${item.coapplicantEmandate==='Not Initiated'?'#898989':`${item.coapplicantEmandate==='Rejected'?'#FEEAEB':`${item.coapplicantEmandate==='Partial'?'#BA55D3':`${item.coapplicantEmandate==='Initiated'?'#EFE4FF':`${item.coapplicantEmandate==='Invalid Bank Details'?'#FFF6E8':`${item.coapplicantEmandate==='Not Applicable'?'#FFF':''}`}`}`}`}`}`}`}}>
                           
   
                           {item.coapplicantEmandate?
                           (item.coapplicantEmandate==="Rejected"?<span><img src={closedDot}/> Rejected</span>
                           :item.coapplicantEmandate==="Upi Mandate Failed"?<span title='Upi Mandate Failed'><img src={closedDot}/> Upi M</span>
                           :item.coapplicantEmandate==="Pass"?<span><img src={activeDot}/> Pass</span>
                           :item.coapplicantEmandate==="Upi Mandate Done"?<span title='Upi Mandate Done'><img src={activeDot}/> Upi M</span>
                           :item.coapplicantEmandate==="Initiated"?<span><img src={initiatedDot}/> Initiated</span>
                           :item.coapplicantEmandate==="Upi Mandate Initiated"?<span title='Upi Mandate Initiated'><img src={initiatedDot}/>Upi M</span>
                           :item.coapplicantEmandate==="Not Initiated"?<span><img src={todoDot}/> To Do</span>
                           :item.coapplicantEmandate==="Partial"?<span><img src={todoDot}/>Partial</span>
                           :item.coapplicantEmandate==="Invalid Bank Details"?<span><img src={pendingDot}/>Attn! </span>
                           :item.coapplicantEmandate==="Not Applicable"?<span><img src={todoDot}/>NA</span>
                           :item.coapplicantEmandate):"NA"}</p>:null}</>}
                            </div>
                        </td>
                        <td >
                           <div style={{display:'block',marginTop:'10px',maxWidth:'4em'}}>
                            {decode.role==="institute_user"||decode.role==="institute_admin" ?
                            <>
                            {item.coapplicantId ? 
                              <>
                                <p id="emandate"  style={{cursor:'pointer'}}>
                                  {item.applicantFraudCheck?
                                  (item.applicantFraudCheck==="fail"?<p title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                  :item.applicantFraudCheck==="pass"?<p title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                  :item.applicantFraudCheck):<p style={{fontSize:'12px',fontWeight:'bold'}}>NA</p>}
                                </p>
                                <p id="emandate"  style={{cursor:'pointer'}}>
                                          {item.coapplicantFraudCheck?
                                    (item.coapplicantFraudCheck==="fail"?<p title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                    :item.coapplicantFraudCheck==="pass"?<p title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                    :item.coapplicantFraudCheck):<p style={{fontSize:'12px',fontWeight:'bold'}}>NA</p>}  
                                </p>
                              </>
                              :
                              <p id="emandate"  style={{cursor:'pointer'}}>
                                  {item.applicantFraudCheck?
                                  (item.applicantFraudCheck==="fail"?<p title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                  :item.applicantFraudCheck==="pass"?<p title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                  :item.applicantFraudCheck):<p style={{fontSize:'12px',fontWeight:'bold'}}>NA</p>}
                                </p>
                            }
                            </>
                          :
                          <>
                          {item.coapplicantId ? 
                            <>
                              <p id="emandate" onClick={()=>handleFraudDetails(item.applicationId,item.userId,item.name)} style={{cursor:'pointer'}}>
                                {item.applicantFraudCheck?
                                (item.applicantFraudCheck==="fail"?<p title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                :item.applicantFraudCheck==="pass"?<p title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                :item.applicantFraudCheck):<p style={{fontSize:'12px',fontWeight:'bold'}}>NA</p>}
                              </p>
                              <p id="emandate" onClick={()=>handleFraudDetails(item.applicationId,item.coapplicantId,item.name)} style={{cursor:'pointer'}}>
                                        {item.coapplicantFraudCheck?
                                  (item.coapplicantFraudCheck==="fail"?<p title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                  :item.coapplicantFraudCheck==="pass"?<p title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                  :item.coapplicantFraudCheck):<p style={{fontSize:'12px',fontWeight:'bold'}}>NA</p>}  
                              </p>
                            </>
                            :
                            <p id="emandate" onClick={()=>handleFraudDetails(item.applicationId,item.userId,item.name)} style={{cursor:'pointer'}}>
                                {item.applicantFraudCheck?
                                (item.applicantFraudCheck==="fail"?<p title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                :item.applicantFraudCheck==="pass"?<p title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></p>
                                :item.applicantFraudCheck):<p style={{fontSize:'12px',fontWeight:'bold'}}>NA</p>}
                              </p>
                          }
                          </>
                              }

                           </div>
                        </td>  
                            
                        <td style={{  color: '#667085',fontSize: '0.9vw' ,marginBottom:'15px',fontFamily:"Inter" }}>{item.amount ? ( <>&#8377;{formatNumberWithCommas(item.amount)}</>) : ( '-')}</td>
                        <td style={{  fontSize: '0.9vw', width: '2em', textAlign: 'center' , color: '#667085',fontFamily:"Inter" ,marginBottom:'15px'}}>{item.tenure?item.tenure:'-'}
                      
                        </td>
                     
                        { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:<td style={{  fontSize: '0.9vw', width: '3em',fontFamily:"Inter", textAlign: 'center', color: '#667085'  }}>{item.fundSourceCode?item.fundSourceCode:'-'}
                          
                        </td>}
                        {decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender" ? null :
                         <td style={{  color: '#667085',fontSize: '0.9vw' ,fontFamily:"Inter" }}>
                         {item?.paymentStatus ?
                         (
                           item?.paymentStatus === "Link Generated"?<p title='Link Generated' style={{fontSize:'12px',backgroundColor:'#F8A31F',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p>
                           :item?.paymentStatus === "Failed"?<p title='Failed' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p>
                           :item?.paymentStatus === "Paid"?<p title='Paid' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p>
                           :item?.paymentStatus === "Not Applicable"?<p title='Not Applicable' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px',fontWeight:'700',display:'flex',justifyContent:'center',alignItems:'center',color:'black'}}>NA</p>
                           :item?.paymentStatus === "Paid At Institute"?<p title='Paid At Institute' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px',fontWeight:'700',display:'flex',justifyContent:'center',alignItems:'center',color:'black'}}>I</p>
                           :item?.paymentStatus
                         )
                         :"-"}
                         </td>}
                       

                        {/* {decode.role==="institute_user"||decode.role==="institute_admin"?null:<td title={item.cra} style={{  fontSize: '0.9vw',fontFamily:"Inter", color: '#667085', fontWeight: '500',textAlign:'center', width: 'auto' }}>
                          {item.cra?item.cra[0]+item.cra.toUpperCase().substr(item.cra.indexOf(" "),item.cra.indexOf("")+2):null}
                        </td>} */}
                       { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:
                        <td >
                          <div style={{display:'flex'}}>
                            <span title='Move Status'><MoveSanctionStatus    ApplicantId={item} /></span>
                            <span title="Payment Link">
                              {item.paymentStatus ? (       
                                <img src={utr} style={{ height: '20px', marginLeft: '7px', cursor: 'not-allowed' }} />
                              ) : (
                              <img
                                src={utr}
                                style={{ 
                                  height: '20px', 
                                  marginLeft: '7px', 
                                  cursor: item.instituteName === 'NxtWave' ? 'not-allowed' : 'pointer' 
                                }}
                                onClick={item.instituteName === 'NxtWave' ? null : () => togglePayment(item)}
                              />
                              )}
                            </span>                         
                             </div>
                          <div>
                          </div>
                        </td>}
                      </tr>
                      
                    </tbody>
                  );
              })}
            </table>
            </div>
            {totalFetchRows ? ( totalFetchRows <= 100 ?(
                    <div style={{display:"flex" ,justifyContent:"center",marginTop:"10px"}}>
                      <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px' }}>
               
               Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{" "}
                records of {totalFetchRows} |
               
             </div>
            <div style={{display:"flex", justifyContent:"space-between",}}>
            &nbsp;<p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
         
         
          </div>
                      
                    </div>
               
            ):( <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}><div style={{ textAlign: 'center',fontWeight: 'bold',justifyContent:"space-between",fontFamily:"Inter-Medium", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px',marginRight:'10px'}}>
                
            Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length} {" "}
            records of {totalFetchRows} |
           
          </div>
          <div style={{display:"flex", justifyContent:"space-between",}}>
            <p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
          </div>
          </div> )
             
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
            )}

            <PaginationTable
              startPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalFetchRows}
              paginate={paginate}
              searchText={searchText}
              filterDateFrom={filterDateFrom}
            />
          
          </>
        )}
          
        </CardBody>
        </div>

      </Card>
    );
  }
  return <div>....Loading</div>;
};

export default SanctionTable;
