import {
  Col,
  Table,
  Card,
  Form,
  Button,
  Input,
  CardBody,
  CardTitle,
  CardSubtitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,Label
} from 'reactstrap';
import axios from 'axios';
import { useState, useEffect } from 'react';
import todoDot from "../../../assets/images/todoDot.png"
import initiatedDot from "../../../assets/images/initiatedDot.png"
import activeDot from "../../../assets/images/activeDot.png"
import retrigger from "../../../assets/images/retrigger.png"
import closedDot from "../../../assets/images/closedDot.png"
import pendingDot from "../../../assets/images/pendingDot.png"
import Datetime from 'react-datetime';
import close from "../../../assets/images/close.png"
import jwt_decode from 'jwt-decode'
import search from "../../../assets/images/search.png"
import 'react-datetime/css/react-datetime.css';
import Sidebar from '../../Sidebar';
import Topbar from '../../Topbar';
import { useLocation,useNavigate } from 'react-router-dom';
import EditForm from '../EditForm/EditForm';
import MoveDisbursementStatus from '../MoveDisbursementStatus/MoveDisbursementStatus';
import statusimage from "../../../assets/images/status.png";
import rightarrow from "../../../assets/images/chevron-right.png"
import Sort from "../../../assets/images/sort.png";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import "./ReadyToDisburse.css"
import download from "../../../assets/images/download.png";
import selfie from "../../../assets/images/camera.png";
import kyc from "../../../assets/images/kyc1.png";
import agreement from "../../../assets/images/agreement.png";
import mandate from "../../../assets/images/mandate1.png"
import fraudimage from "../../../assets/images/fraudimage.png"
//import { useUserAuth } from '../../views/auth/AuthContext.js';
import PaginationTable from '../PaginationTable';
import axiosInstance from '../../../helpers/axios';
import { useSelector, useDispatch } from "react-redux"; import * as moment from 'moment'

import {userActions} from '../../../actions/user.actions';

//import '../../assets/scss/_variables.scss';

// eslint-disable-next-line import/extensions
//import ComponentCard from '../ComponentCard';

require('moment/locale/fr');
require('moment/locale/de');
require('moment/locale/en-gb');

const InDisbursement = () => {

  const state = useSelector((state) => state?.Readytodisburse?.res);
  const datestate = useSelector((state) => state.Datefilter?.res);
  // const state = useMemo(() => (res), []);

  const [userDetails, setUserDetails] = useState(null)

  const dispatch = useDispatch();


  const ApplicationStatusEnum = {
  InSubmission: 'In Submission',
  InReview: 'In Review',
  InSanction: 'In Sanction',
  InDisbursement: 'In Disbursement',
  PendingDisbursement: "Pending Disbursement",
  Disbursed: 'Disbursed',
  Rejected: 'Rejected',
  Dropped: 'Dropped',
  OnHold:'On Hold', ReadyToDisburse:'Ready to Disburse',
};

  //tooltip
  const [signedTooltipOpen, setSignedTooltipOpen] = useState(false);
  const signedToggle = () => setSignedTooltipOpen(!signedTooltipOpen);
  
  const [selfieTooltipOpen, setSelfieTooltipOpen] = useState(false);
  const selfieToggle = () => setSelfieTooltipOpen(!selfieTooltipOpen);

  const [digilockerStatusOpen, setDigilockerStatusOpen] = useState(false);

  const location=useLocation();
  const [status, setStatus] = useState(ApplicationStatusEnum.ReadyToDisburse);
  const localStatus = sessionStorage.setItem("status",status)
  const user = sessionStorage.getItem('user');
  const decode=jwt_decode(user)
  //filter
  const [filterInstituteName, setFilterInstituteName] = useState('');
  const [filterDateFrom, setFilterDateFrom] = useState('');
  const [filterDateTo, setFilterDateTo] = useState('');
  const [searchText, setSearchText] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const [bankSelfieDetails,setBankSelfieDetails]=useState(null)
  const [advEmiChecked,setAdvEmiChecked] = useState(false)
  const [pfChecked,setPfChecked] = useState(false)
  const [advChecked,setAdvChecked] = useState(true)
  const [pfsChecked,setPfsChecked] = useState(true)
  const [adjustementAmount,setAdjustementAmount] = useState(null)

  const valid = (current) => {
    return current.isAfter(filterDateFrom);
  };
//tooltip
 const [agreementTooltipOpen, setAgreementTooltipOpen] = useState(false);
 const [mandateTooltipOpen, setMandateTooltipOpen] = useState(false);
 const [fraudToolOpen,setFraudToolOpen] = useState(false)

 const [nestedModal, setNestedModal] = useState(false);
 const [closeAll, setCloseAll] = useState(false);

 const [upiMandateTooltipOpen, setUpiMandateTooltipOpen] = useState(false);
 const [clicked,setClicked] = useState(false)
 const [activePage, setActivePage] = useState(15); 

 


  const copyAgreementToggle = () => {

    setAgreementTooltipOpen(!agreementTooltipOpen);
    navigator.clipboard.writeText(`
              Aadhaar Suffix:${info?info.aadhaar_suffix:null},
              Name: ${info?info.name:null},
              Postal Code: ${info?info.postal_code:null},
              Display Name: ${info?info.display_name:null},
              Name Validation:${info?info.name_validation_score:null},
              Created At:${docDetails?docDetails.createdAt:null},
              Done At:${docDetails?docDetails.updatedAt:null},
              Download Signed Agreement Document:${docDetails?docDetails.signedDocumentUrl:null} `)
  }
  const copyMandateToggle = () => {

    setMandateTooltipOpen(!mandateTooltipOpen);
   navigator.clipboard.writeText(`
              Name:${mandateDetails?mandateDetails.name:null},
              Account Type:${mandateDetails?mandateDetails.accountNumber:null},
              Account Type: ${mandateDetails?mandateDetails.accountType:null},
              Customer mobile:${mandateDetails?mandateDetails.phone:null},
              Mandate Type:${mandateDetails?mandateDetails.mandateType:null},
              IFSC Code: ${mandateDetails?mandateDetails.ifsc:null},
              TokenId:${mandateDetails?mandateDetails.tokenId:null},
              PaymentId:${mandateDetails?mandateDetails.paymentId:null},
              CustomerId:${mandateDetails?mandateDetails.customerId:null},
             
               `)


           
  }
  //table
  const [tableData, setTableData] = useState([]);
  const [filterTableData, setFilterTableData] = useState([]);
  const [userProfileId, setUserProfileId] = useState(null);
  const [time, setTime] = useState('');
 
  const [info,setInfo]=useState(null)
  const [docDetails,setDocDetails]=useState(null)
  
  // eslint-disable-next-line no-unneeded-ternary 
  const [currentPage, setcurrentPage] = useState(sessionStorage.getItem("page") ? parseInt(sessionStorage.getItem("page")) : 1);
  //pagination
  //changepage

  const [selfieStatusOpen, setSeflieStatusOpen] = useState(false);
  const [rowsPerPage, setrowsPerPage] = useState(10);
  const [totalFetchRows, setTotalFetchRows] = useState(null);
  const [formStatusOpen, setStatusOpen] = useState(false);
  const [mandateStatusOpen, setMandateStatusOpen] = useState(false);
  const [mandateDetails, setMandateDetails] = useState(null);
  const [fruadDetails, setFruadDetails] = useState(null);
  const [upiMandateStatusOpen, setUpiMandateStatusOpen] = useState(false);
  const usersearchstate = useSelector((state) => state?.UserSearch?.res?.data?.data);
  const bankSelfieState=useSelector((state)=>state?.BANKSELFIE?.bankSelfieData);


 const closeAgreeCopy=()=>{
  setStatusOpen(!formStatusOpen)
  setAgreementTooltipOpen(agreementTooltipOpen?!agreementTooltipOpen:agreementTooltipOpen);
 }
 const closeMandateCopy=()=>{
  setMandateStatusOpen(!mandateStatusOpen)
  setMandateTooltipOpen(mandateTooltipOpen?!mandateTooltipOpen:mandateTooltipOpen);
 }
    //sorting
  const [sort,setSort]=useState(-1)


  const urlUsers = `/integrations/applications?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;

  const[toggleSort,setToggleSort]=useState(false);
  const clearDateFilter =()=>{
    setClicked(false)
    setFilterDateFrom('')
    setFilterDateTo('')
    const urlUsers = `/integrations/applications?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;
    
        axiosInstance.get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.details);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
       
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }
  useEffect(() => {
    if(usersearchstate){
      setIsLoading(false);
      setBankSelfieDetails(bankSelfieState?.res)
      setUserDetails(usersearchstate?.details)
      setTableData(usersearchstate?.details);
      setFilterTableData(usersearchstate?.details);
      setcurrentPage(usersearchstate?.pagination?.currentPage);
      setTotalFetchRows(usersearchstate?.pagination?.totalRows);
      setrowsPerPage(usersearchstate?.pagination?.perPage);
    }
else{

  if(toggleSort) 
  {
    setSort(1);
    
  }
  else{
     setSort(-1) ;
  } 

  if(datestate){
    setUserDetails(datestate?.details)
    setTableData(datestate?.details);
    setFilterTableData(datestate?.details);
    setcurrentPage(datestate?.pagination.currentPage);
    setTotalFetchRows(datestate?.pagination.totalRows);
    setrowsPerPage(datestate?.pagination.perPage);  
  }
  else{
    if(searchText === "" && filterDateFrom === '' && filterDateTo ===''){
    
      const urlUsers = `/integrations/applications?status=${status}&perPage=${rowsPerPage}&pageNo=${currentPage}&sortOrder=${sort}`;

      axiosInstance.get(urlUsers, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        setBankSelfieDetails(bankSelfieState?.res)
        setTableData(res?.data?.data?.details);
        setcurrentPage(res?.data?.data?.pagination?.currentPage);
        setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
        setrowsPerPage(res?.data?.data?.pagination?.perPage);  
        const loadingTimeout = setTimeout(() => {
          setIsLoading(false);
        }, 300);
      })
      .catch((error) => {
        alert(error?.response?.data?.message)
        console.error("Error fetching filtered data:", error);
      });
      }
      
     
   

  }
}
        
  }, [toggleSort,datestate,state,usersearchstate,bankSelfieState]);
  const paginate = (pageNumber) => {

    if(searchText.length >= 4 && filterDateFrom === '' && filterDateTo ===''){
      const urlUsers = `/integrations/applications?instituteId=${id}&status=${status}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
         axiosInstance.get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
        
          setTableData(res?.data?.data?.details);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
       
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
        }else if(searchText === "" && filterDateFrom !== '' && filterDateTo !=='' ){
          const urlUsers = `/integrations/applications?status=${status}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
         axiosInstance.get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
        
          setTableData(res?.data?.data?.details);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
       
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
        }else if(searchText !== "" && filterDateFrom !== '' && filterDateTo !=='' ){
          const urlUsers = `/integrations/applications?instituteId=${id}&status=${status}&fromDate=${filterDateFrom}&toDate=${filterDateTo+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
          axiosInstance.get(urlUsers, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          })
          .then((res) => {
          
            setTableData(res?.data?.data?.details);
            setcurrentPage(res?.data?.data?.pagination?.currentPage);
            setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
            setrowsPerPage(res?.data?.data?.pagination?.perPage);  
         
          })
          .catch((error) => {
            alert(error?.response?.data?.message)
            console.error("Error fetching filtered data:", error);
          });
        }else{
          const urlUsers = `/integrations/applications?status=${status}&perPage=${rowsPerPage}&pageNo=${pageNumber}&sortOrder=${sort}`;
          axiosInstance.get(urlUsers, {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          })
          .then((res) => {
          
            setTableData(res?.data?.data?.details);
            setcurrentPage(res?.data?.data?.pagination?.currentPage);
            setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
            setrowsPerPage(res?.data?.data?.pagination?.perPage);  
         
          })
          .catch((error) => {
            alert(error?.response?.data?.message)
            console.error("Error fetching filtered data:", error);
          });
        }
  };

  function formatNumberWithCommas(number) {
    return number.toLocaleString('en-IN'); 
  }
  const handleFraudDetails=(muserApp,muserId,muserName,mStatus)=>{
    setFraudToolOpen(!fraudToolOpen);
    sessionStorage.setItem("regenName",muserName)
    sessionStorage.setItem("regenAppId",muserApp)
   sessionStorage.setItem("regenUserId",muserId)
   sessionStorage.setItem("mStatus",mStatus)
    const fraudUrl=`/integrations/fraud-check?applicationId=${muserApp}&userId=${muserId}`
    axiosInstance.get(fraudUrl,{
        headers: {
          Authorization: `Bearer ${user}`,
          'Content-type': 'application/json',
        },
      }).then((resp)=>

  setFruadDetails(resp?.data?.data)
   )
  }

  const handleDocDownload=(docUserId,docUserApplicationId)=>{
    setStatusOpen(!formStatusOpen);
    sessionStorage.setItem("regenAppId",docUserApplicationId)
    sessionStorage.setItem("regenUserId",docUserId)
      const docUrl=`/agreement/downloadAgreement?userId=${docUserId}&applicationId=${docUserApplicationId}`
    axiosInstance.post(docUrl).then((resp)=>{
      if(resp.data.data!=undefined)
      {

        setInfo(JSON.parse(resp.data.data.info))
        setDocDetails(resp.data.data)
      }
    }
    )
  }
  const [KID,setKID]=useState(null)
  const [transactionId,setTransactionID]=useState(null)
  
  const handleSelfieRetrigger=(docUserId,docUserApplicationId)=>{
   console.log("clicked")
    if(decode.role!=="institute_user" && decode.role!=="institute_admin")
    {
      setSeflieStatusOpen(!selfieStatusOpen);
     
      sessionStorage.setItem("regenAppId",docUserApplicationId)
      sessionStorage.setItem("regenUserId",docUserId)
       const urlGetIntegrations= `/integrations/details?applicationId=${docUserApplicationId}&userId=${docUserId}`;
       dispatch(userActions.getBankSelfieDetails(urlGetIntegrations,user))

    }
    //  axios.get(urlGetIntegrations, {
    //         headers: {
    //           Authorization: `Bearer ${user}`,
    //           'Content-type': 'application/json',
    //               },
    //          }).then((res) => {
    //           setSelfieIntegrationsData(res.data.data.selfieDetails);
    //      });
  
    
  }
  const selfieReinitiate=()=>{
    const reinitiateselfie=`/one-way-kyc/reinitiate`
    const data={
      applicationId:sessionStorage.getItem("regenAppId"),
      userId:sessionStorage.getItem("regenUserId")
    }
    axiosInstance.post(reinitiateselfie,data, {
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-type': 'application/json',
      },
    }).then((res)=>{
      alert(res?.data?.message)
      setTimeout(() => window.location.reload(true), 500)}
      )
    .catch((err)=>{console.log(err)})

  }
  
  const selfieRetrigger=()=>{
    if(KID!==null&&transactionId!==null)
    {

      const regenSelfieUrl=`/one-way-kyc/refreshDetails?applicationId=${sessionStorage.getItem("regenAppId")}&userId=${sessionStorage.getItem("regenUserId")}&kid=${KID}&transactionId=${transactionId}`
      console.log(sessionStorage.getItem("regenAppId"))
      console.log(sessionStorage.getItem("regenUserId"))
       
  
      axiosInstance.post(regenSelfieUrl,null,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
       alert("Selfie Refreshed ,You can close this box")
        axiosInstance
        .get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-type': 'application/json',
          },
        })
        .then((resp) => {
          setTableData(resp.data.data.details);
          setFilterTableData(resp.data.data.details);
          setcurrentPage(resp.data.data.pagination.currentPage);
          setTotalFetchRows(resp.data.data.pagination.totalRows);
          setrowsPerPage(resp.data.data.pagination.perPage);
        });
      })
    }
    else
    {
      alert("Enter Proper Values")
    }
  }
  const upimandateTrigger=()=>{
    console.log(sessionStorage.getItem("regenAppId"))
    console.log(sessionStorage.getItem("regenUserId"))
    const registerUpiUrl=`/mandate/register-upi`

    const data={
      userId:sessionStorage.getItem("regenUserId"),
      applicationId:sessionStorage.getItem("regenAppId")
    }
    axiosInstance.post(registerUpiUrl,data,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    })
    .then((res)=>alert("UPI mandate triggered ,You can close this box"))
    .catch((err)=>alert(
      "Error !"
    ))
}
const [upiMandateDetails, setUpiMandateDetails] = useState(null);
const [cashfreeDetails,setCashfreeDetails]= useState(null)

const handleMandateDetails=(muserApp,muserId,muserName,mStatus,cra)=>{
   console.log("cra",cra)
  sessionStorage.setItem("regenName",muserName)
   sessionStorage.setItem("regenAppId",muserApp)
  sessionStorage.setItem("regenUserId",muserId)
  sessionStorage.setItem("mStatus",mStatus)
  const mstatus = sessionStorage.getItem("mstatus")
  if((mstatus === "Upi Mandate Initiated" || mstatus === "Upi Mandate Partial" || mstatus === "Upi Mandate Failed" || mstatus === "Upi Mandate Done" || mStatus === "Upi Mandate Initiated" || mStatus === "Upi Mandate Partial" || mStatus === "Upi Mandate Failed" || mStatus === "Upi Mandate Done") && cra !== "InsurFin"){
    const mandateUrl=`/mandate/mandate-details?applicationId=${muserApp}&userId=${muserId}`
    axiosInstance.get(mandateUrl,{
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-type': 'application/json',
      },
    }).then((resp)=>{  console.log("resp",resp)
    setUpiMandateStatusOpen(!upiMandateStatusOpen);
    setMandateStatusOpen(mandateStatusOpen);
    setUpiMandateDetails(resp.data.data)
  setCashfreeDetails(null)}
).catch((error)=>{console.log(error,"error")
alert(error?.response?.data?.message)})
  }  else if((mStatus === "Upi Mandate Initiated" || mStatus === "Upi Mandate Partial" || mStatus === "Upi Mandate Failed" || mStatus === "Upi Mandate Done") && cra === "InsurFin"){
    const mandateUrl=`/cashfree-upi/mandate-details?applicationId=${muserApp}&userId=${muserId}`
    axiosInstance.get(mandateUrl,{
      headers: {
        Authorization: `Bearer ${user}`,
        'Content-type': 'application/json',
      },
    }).then((resp)=>{  console.log("resp",resp)
    setUpiMandateStatusOpen(!upiMandateStatusOpen);
    setMandateStatusOpen(mandateStatusOpen);
    setCashfreeDetails(resp.data.data)
  setUpiMandateDetails(null)}
).catch((err)=>console.log(err))
  }
  
  else{
    const mandateUrl=`/integrations/razorpay-details?applicationId=${muserApp}&userId=${muserId}`
    axiosInstance.get(mandateUrl,{
        headers: {
          Authorization: `Bearer ${user}`,
          'Content-type': 'application/json',
        },
      }).then((resp)=>{  console.log("resp",resp)
      setUpiMandateStatusOpen(upiMandateStatusOpen);
      setMandateStatusOpen(!mandateStatusOpen);
      
      setMandateDetails(resp.data.data)}
 ).catch((error)=>{console.log(error,"error")
alert(error?.response?.data?.message)})
  }

}

console.log("cash",cashfreeDetails)
  //datefilter
  const handleDateFilterApi = (startDate, endDate) => {
    setClicked(!clicked)
    setFilterDateFrom(startDate)
    setFilterDateTo(endDate)
    if (startDate === null || endDate === null) alert('Enter Date Range to Filter');
    else {
      setIsLoading(true);
      if(searchText){
        const urlFilterUsers = `/integrations/applications?instituteId=${id}&status=${status}&fromDate=${startDate}&toDate=${endDate+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${1}&sortOrder=${sort}`;
        axiosInstance.get(urlFilterUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.details);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }else{
        const urlFilterUsers = `/integrations/applications?status=${status}&fromDate=${startDate}&toDate=${endDate+" "+"23:59:59"}&perPage=${rowsPerPage}&pageNo=${1}&sortOrder=${sort}`;
        // dispatch(userActions.fetchDatefilter(urlFilterUsers, user),[])
        axiosInstance.get(urlFilterUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res) => {
         
          setTableData(res?.data?.data?.details);
          setcurrentPage(res?.data?.data?.pagination?.currentPage);
          setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
          setrowsPerPage(res?.data?.data?.pagination?.perPage);  
          const loadingTimeout = setTimeout(() => {
            setIsLoading(false);
          }, 300);
        })
        .catch((error) => {
          alert(error?.response?.data?.message)
          console.error("Error fetching filtered data:", error);
        });
      }
     
    }
  };

  //clearfilter
  const handleClearFilter = () => {
    setFilterInstituteName('');
    axiosInstance.get(urlUsers).then((res) => {
     setTableData(res.data.data.details);
        setFilterTableData(res.data.data.details);
        
         setcurrentPage(res.data.data.pagination.currentPage);

        setTotalFetchRows(res.data.data.pagination.totalRows);
        setrowsPerPage(res.data.data.pagination.perPage);
    });
  };
  const navigate = useNavigate();
 const userstate = useSelector((state) => state.Handleuser?.res)
  const userCoAppstate = useSelector((state) => state.Handlecoappuser?.res)

  const handleUser = (event) => {
      
    const Id = event.userId;
    const applId = event.applicationId
    const urlProfile = `/summary/user?id=${Id}&applicationId=${event.applicationId}&status=Ready to Disburse`;

    dispatch(userActions.fetchHandleuser(urlProfile, user),[])
      const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/user?id=${Id}&applicationId=${event.applicationId}&status=In Disbursement`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `${process.env.REACT_APP_BASE_URL}/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
   
  
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(urlProfile));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate('/status/disbursement/profile', {
      state: { id: 1, profileId: Id,applicantId:Id,coapplicantId:event.coapplicantId,applicationId:event.applicationId,dir:"Ready to Disburse" },
    });

   
  };

 


  const handleCoApp = (event,currTab) => {
    
    const Id = event.coapplicantId;
    const applId = event.applicationId

    const urlProfile = `/summary/coapplicant?id=${Id}&status=In Disbursement`;
   
    dispatch(userActions.fetchHandlcoappuser(urlProfile, user),[])

      const ckycUrl=`/ckyc/details?userId=${Id}`;
  const profileUrl=`/summary/coapplicant?id=${Id}&status=In Disbursement`;
  const digilockerUrl=`/digilocker/details?applicationId=${event.applicationId}&userId=${Id}`;
  const panUrl=`/application/pan-details?userId=${Id}&applicationId=${event.applicationId}`;
  const bankSelfieUrl=`/integrations/details?applicationId=${event.applicationId}&userId=${Id}`;
  const uploadedFileUrl=`/users/documents/admin?userId=${Id}`
  const getActivityUrl=`/audit?applicationId=${event.applicationId}`
const urlForBankSelfie=`/integrations/application-profile?applicationId=${event.applicationId}`
  //cibil
  const urlCIBIL = `${process.env.REACT_APP_BASE_URL}/cibil/report?userId=${Id}`

  const urlIncomeProfile=`/bank-statement-analysis/finbox/admin-analytics-data?userId=${Id}&applicationId=${event.applicationId}`
   
    dispatch(userActions.getIncomeProfileAnalytics(urlIncomeProfile,user))
    
   
  const getFoirRefUrl=`/admin/application/additional-info?applicationId=${event.applicationId}`
  dispatch(userActions.getFoirDetails(getFoirRefUrl,user));
        dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
     dispatch(userActions.getCkycDetails(ckycUrl,user));
    dispatch(userActions.getCibilDetails(urlCIBIL));
    dispatch(userActions.getPanStatus(panUrl));
    dispatch(userActions.getBankSelfieDetails(bankSelfieUrl,user));
    dispatch(userActions.getDigiLocker(digilockerUrl,user));
    dispatch(userActions.getProfileDetails(profileUrl));
    dispatch(userActions.getUploadedFiles(uploadedFileUrl,user));
    dispatch(userActions.getActivity(getActivityUrl,user));
    navigate('/status/disbursement/profile', {
      state: { id: 1, profileId: Id,clickEvent:"coapp",applicantId:event.userId,coapplicantId:event.coapplicantId,applicationId:event.applicationId ,dir:"Ready to Disburse"},
    });

     
   
  };

  //Go To Application Profile
   const handleApplicationProfile=(currApplicationId,currUID,currCoAppId)=>{
    sessionStorage.setItem("userId",currUID)
    sessionStorage.setItem("appId",currApplicationId)
    sessionStorage.setItem("coappId",currCoAppId)
    const urlForApplicationDetails=`/admin/application/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const urlForBankSelfie=`/integrations/application-profile?applicationId=${sessionStorage.getItem("appId")}`
    const getEmiUrl=`/emis?applicationId=${sessionStorage.getItem("appId")}`
    const getActivityUrl=`/audit?applicationId=${sessionStorage.getItem("appId")}`
    dispatch(userActions.fetchUrlforApplicationdetails(urlForApplicationDetails,user),[])
    dispatch(userActions.fetchUrlforbankselfie(urlForBankSelfie,user),[])
    dispatch(userActions.fetchEmiUrl(getEmiUrl,user),[])
    dispatch(userActions.getActivity(getActivityUrl,user))
    navigate("/status/disbursement/appProfile")
    
  }
  
  const handleDownloadList=()=>{
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear());
  
    const formattedDate = `${day}-${month}-${year}`;
    document.getElementById("downloadButton").disabled=true;
    document.getElementById("downloadButton").innerText="Wait....";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    document.getElementById("downloadButton").style.color= "white";
    document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
    document.getElementById("downloadButton").style.fontSize= "15px";
    document.getElementById("downloadButton").style.width= "90px";
    document.getElementById("downloadButton").style.cursor= "pointer";
    document.getElementById("downloadButton").style.height= "34px";
    document.getElementById("downloadButton").style.borderRadius= "5px";
    document.getElementById("downloadButton").style.textAlign= "center";
    document.getElementById("downloadButton").style.marginLeft="150px";
    document.getElementById("downloadButton").style.backgroundImage = "none"


    const downloadTemplateUrl=`${process.env.REACT_APP_BASE_URL}/excel/download`
    const downloadData={
        instituteId: decode.instituteId?decode.instituteId:id,

        status:status,
        fromDate:filterDateFrom,
        toDate:filterDateTo+" "+"23:59:59"
       
    }
    axios.post(downloadTemplateUrl,downloadData, {
      responseType:'arraybuffer',
      headers:{
        Authorization:`Bearer ${user}`

    }
  }).then((res)=>{
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'

    }, 2000);
    const blob = new Blob([res.data], { type: "application/xlsx", });
    const url= window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href =url
    link.download = `${formattedDate}_${status}.xlsx`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  }
      
    
  ).catch((error)=>{
    alert("Error Downloading")
    document.getElementById("downloadButton").innerText="Error..";
    document.getElementById("downloadButton").style.backgroundColor="#D22129";
    setTimeout(() => {
      document.getElementById("downloadButton").disabled=false
      document.getElementById("downloadButton").innerText="Export";
      document.getElementById("downloadButton").style.paddingLeft = "30px";
      document.getElementById("downloadButton").style.backgroundColor="#D22129";
      document.getElementById("downloadButton").style.color= "white";
      document.getElementById("downloadButton").style.fontFamily= "Inter-Medium";
      document.getElementById("downloadButton").style.fontSize= "15px";
      document.getElementById("downloadButton").style.width= "90px";
      document.getElementById("downloadButton").style.cursor= "pointer";
      document.getElementById("downloadButton").style.height= "34px";
      document.getElementById("downloadButton").style.borderRadius= "5px";
      document.getElementById("downloadButton").style.textAlign= "center";
      document.getElementById("downloadButton").style.marginLeft= "150px";
      document.getElementById("downloadButton").style.backgroundImage = `url(${download})`
      document.getElementById("downloadButton").style.backgroundRepeat  ='no-repeat' 
      document.getElementById("downloadButton").style.backgroundSize  ='15px' 
      document.getElementById("downloadButton").style.backgroundPosition  ='10px 10px'


    }, 1000);
  })
  }   
  //payouts intergration
  const [selectedAlready,setSelectedAlready]=useState(true)
  // #1 get all selected
  const [adjustmentAmt,setAdjustmentAmount]=useState(null)
  const [processingFees,setProcessingFees]=useState(null)

  const [advanceEmiAmount,setAdvanceEmiAmount]=useState(null)

   const [amountToBeDisbursed,setAmountToBeDisbursed]=useState(null)
   const [sourceAccNum,setSourceAccountNumber]=useState("Src Acc No.")
  //  const [destinationAccNum,setDestinationAccountNumber]=useState("Destn. Acc No")
  const [dropDownOpen, setDropDownOpen] = useState(false);
  // const [destDropDownOpen, setDestDropDownOpen] = useState(false);
   const [amtNotes,setAmountNotes]=useState(null)
  
  const dropdown=[];

   const handleToggle = (position,array) => {
    if(array[position]==undefined)
     array[position]=true
     else
      array[position]=false
        };
    // const handleDestToggle = () => {
    //        setDestDropDownOpen(!destDropDownOpen);
    // };
  const [selectedStatusOpen,setSelectedStatusOpen]=useState(false)
 
  const [selectedApp,setSelectedApp]=useState(null)
  const [displaySelected,setDisplaySelected]=useState(null)
  const [selectedName,setSelectedName]=useState(null)
  
  const handleSelectedApp=(event,item,index)=>{
    setSelectedName(item.name)
    if(event.target.checked==true )
    {
      setSelectedApp(item.applicationId)
      setSelectedAlready(!selectedAlready)
      setTimeout(() => {
        
        document.getElementById(`input${index}`).disabled=false
      }, 50);
      
    }
    else if(event.target.checked==false)
    {
     setSelectedApp(null)
     setSelectedAlready(!selectedAlready)
    }
  


  }

  const [id,setId]=useState(null)
  const getInsituteInfo=(text)=>{
    
    const urlGetInstitute=`/institute/info/name?name=${text}`;
      
    if(text.length>=4)
    {
      axiosInstance.get(urlGetInstitute,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      }).then((res) => {
        console.log("text",res?.data?.data[0]?.id)
        setId(res?.data?.data[0]?.id)
      });
  
   
    }
 
  }


  const handlepageChange = (number) => {

    setIsLoading(true)
    setActivePage(number)
    setrowsPerPage(number);

    let queryParams = {}
    
    if(filterDateFrom && filterDateTo){
      queryParams.fromDueDate = filterDateFrom;
      queryParams.toDueDate = filterDateTo+" "+"23:59:59"
    }
    
    if(id){
      queryParams.instituteId = id
    }
   
    if(status){
      queryParams.status = status
    }
  
   
    if(rowsPerPage && currentPage){
      queryParams.perPage = number;
      queryParams.pageNo = 1
    }
    axiosInstance.get('/integrations/applications',{
      params:queryParams,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res)=>{    
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 300);
    })
    .catch((error)=>console.log(error))

};




  const handleInputChange = (event) => {

    if(event.target.value.length >= 4){
      setSearchText(event.target.value)
      const urlUsers = `/integrations/applications?instituteId=${id}&status=${status}&perPage=${rowsPerPage}&pageNo=${1}&sortOrder=${sort}`;


  console.log("url",urlUsers)
    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });
  }
  else{
    setSearchText("")
    const urlUsers = `/integrations/applications?&status=${status}&perPage=${rowsPerPage}&pageNo=${1}&sortOrder=${sort}`;

    axiosInstance.get(urlUsers, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    })
    .then((res) => {
      console.log("filtering",res)
      setTableData(res?.data?.data?.details);
      setcurrentPage(res?.data?.data?.pagination?.currentPage);
      setTotalFetchRows(res?.data?.data?.pagination?.totalRows);
      setrowsPerPage(res?.data?.data?.pagination?.perPage);  
   
    })
    .catch((error) => {
      console.error("Error fetching filtered data:", error);
    });



  }

};
   

const handleAddAdjustmentAmt=(event)=>{
  
  
  
}
const [inputCustomerId,setCustomerId]=useState(null)
const [refreshMandateOpen,setRefreshMandateOpen]=useState(null)
const handleRefreshMandate=()=>{
  if(inputCustomerId)
  {

    const urlRefreshMandate='/nach/refresh'
    const body={
      userId:sessionStorage.getItem('regenUserId'),
      applicationId:sessionStorage.getItem('regenAppId'),
      customerId:inputCustomerId
    }
    axiosInstance.post(urlRefreshMandate,body,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    })
    .then((res)=>{
      alert("Refresh Success")
    })
    .catch((err)=>{
      
      alert(err?.response?.data?.message)
    })
  }
  else
  {
    alert('Enter Customer Id')
  }
}

  const handlePayouts=(createdList)=>{
    
    
    const urlGetSelectedApp=`/disbursement/create`
    const data={
      applicationId:createdList
    }
    axiosInstance.post(urlGetSelectedApp,data,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    }).then((res)=>{
     
      setDisplaySelected(res.data.data)
      setSelectedStatusOpen(!selectedStatusOpen)
      
    }).catch((error)=>alert(error?.response?.data?.message))
  }
console.log(displaySelected?.amountToBeDisbursed-displaySelected?.processingFees,"select")
 function stringify(obj) {
  let cache = [];
  let str = JSON.stringify(obj, (key, value)=>{
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null; // reset the cache
  return str;
}
 
  const handleStatusChangeToPendingApprovals=(appId)=>{
     const disbursedAmount=document.getElementById("disbursementAmt").value
   
console.log(appId,adjustmentAmt,sourceAccNum,disbursedAmount,amtNotes)


   
    const urlSendForDisbursement=`/disbursement/change-status`
    if(amtNotes!=null  && sourceAccNum!=="Src Acc No.")
    {

      const data={
        applicationId:appId,
        adjustedAmount:adjustmentAmt,
        fundSource:sourceAccNum,
        disbursementAmount:disbursedAmount,
        notes:amtNotes?.trim(),
        pfDeducted:pfsChecked,
        advEmiDeducted:advChecked
      
      }
      
    axiosInstance.post(urlSendForDisbursement,data,{
      headers:{
        Authorization:`Bearer ${user}`
      }
    })
    .then((res)=>{
      setSelectedStatusOpen(!selectedStatusOpen)
      alert(res.data.message)
      
     setTimeout(() => {
      window.location.reload(true)
     }, 500); 
    })
      
    }
    else
    alert("None Selected or Incorrect Format")
  }
  const digilockerRetrigger=()=>{
    if(KID!==null&&transactionId!==null)
    {

      const regenDigilockerUrl=`/digilocker/refreshDetails?applicationId=${sessionStorage.getItem("regenAppId")}&userId=${sessionStorage.getItem("regenUserId")}&kid=${KID.trim()}&transactionId=${transactionId.trim()}`
      console.log(sessionStorage.getItem("regenAppId"))
      console.log(sessionStorage.getItem("regenUserId"))
       
  
      axiosInstance.post(regenDigilockerUrl,null,{
        headers:{
          Authorization:`Bearer ${user}`
        }
      })
      .then((res)=>{
        alert("Digilocker triggered ,You can close this box")
        axiosInstance
        .get(urlUsers, {
          headers: {
            Authorization: `Bearer ${user}`,
            'Content-type': 'application/json',
          },
        })
        .then((resp) => {
          setTableData(resp.data.data.details);
          setFilterTableData(resp.data.data.details);
          setcurrentPage(resp.data.data.pagination.currentPage);
          setTotalFetchRows(resp.data.data.pagination.totalRows);
          setrowsPerPage(resp.data.data.pagination.perPage);
        });
      })
    }
    else
    {
      alert("Enter Proper Values")
    }
  }
  const handleDigilockerRetrigger=(digiStatus,docUserId,docUserApplicationId)=>{
    if(decode.role!=="institute_user" && decode.role!=="institute_admin")
    {
      if(digiStatus==="Pass")
      {
  
        setDigilockerStatusOpen(!digilockerStatusOpen);
        sessionStorage.setItem("regenAppId",docUserApplicationId)
        sessionStorage.setItem("regenUserId",docUserId)
      }

    }
    
  }

  const [isOpen,setIsOpen]=useState(true)
  function formatNumberWithCommas(number) {
    return number?.toLocaleString('en-IN');
  }
  const [itemPath,setitemPath] = useState(null)
  const [modal, setModal] = useState(false);
  const toggle1 = () =>{
    const url = `/file/url-content?path=${docDetails?docDetails.signedDocumentUrl.split('.com/')[1]:null}`
    axiosInstance
        .get(url, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then((res)=>{
          setitemPath(res?.data?.data)
          })
    setModal(!modal);
  }
  if (tableData) {
    return (
      <Card  className="card" style={{display:'flex',flexDirection:'row',width:"100%",height:'100%'}}>
        <Sidebar isOpenSidebar={isOpen} handler={setIsOpen}/>
        <div style={{width:isOpen?'80%':'100%', overflow:"auto",marginLeft:isOpen?"20%":'0%'}}>
          <Topbar/>
        
        <CardBody>
        <div>
            <p style={{display:'flex',alignItems:'center'}}><img src={statusimage}/><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px'}}>status</span><span style={{marginLeft:'20px',marginBottom:'3px'}}> <img style={{height:"18px",width:"14px"}} src={rightarrow}/></span><span style={{fontFamily:'Inter-Medium',marginLeft:'10px',fontSize:'14px',color:'#D32028'}}>In Disbursement</span></p>
            
           </div>
          <Row>
            <div style={{ display: 'flex', flex: 'wrap' }}>
            <Col sm="12" md="10" lg="3">
            <div style={{ height: '23px', marginRight: '10px',fontFamily:'Inter'  , position: 'relative', display: 'flex', alignItems: 'center' }}>
            {decode.role === "institute_admin" || decode.role === "institute_user" ?
            <Input
            type="text"
            id="userSearch"
            value={decode.instituteName}
            readOnly
            disabled
            placeholder={'Institute Name'
            }
            style={{
               fontSize: '0.8vw',
              paddingLeft: '26px',
              height: '23px',
              fontFamily: 'Inter',
              backgroundImage: `url(${search})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '5px center',
              backgroundSize: '15px 15px',
              paddingRight: '5px', 
              borderColor:'#cccccc',
              borderStyle:'solid',
              borderRadius:"5px",
              borderWidth:'1px',
              height:'34px',
              marginTop:'11px'
            }}
          />: <Input
          type="text"
          id="userSearch"
          placeholder={'Institute Name'
          } onChange={(event) => {
            getInsituteInfo(event.target.value);
            handleInputChange(event);
          }}
          style={{
             fontSize: '0.8vw',
            paddingLeft: '26px',
            height: '23px',
            fontFamily: 'Inter',
            backgroundImage: `url(${search})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '5px center',
            backgroundSize: '15px 15px',
            paddingRight: '5px', 
            borderColor:'#cccccc',
            borderStyle:'solid',
            borderRadius:"5px",
            borderWidth:'1px',
            height:'34px',
            marginTop:'11px'
          }}
        />
          }
          </div>
            </Col>
              <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="fromDate"
                  dateFormat="DD MMM YYYY"
                  timeFormat={false}
                  onChange={(event) =>{ 
                    if (event && event.format) {
                    setFilterDateFrom(event.format('DD MMM YYYY'));
                  }else{
                    setFilterDateFrom('');
                    clearDateFilter()
                  }
                  }}
                  locale="en-gb"
                  inputProps={{ placeholder: 'From Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>
            <Col>
              <div style={{ height: '7px', marginRight: '10px' }}>
                <Datetime
                closeOnSelect="false"
                  id="toDate"
                  dateFormat="DD MMM YYYY"
                  isValidDate={valid}
                  timeFormat={false}
                  locale="en-gb"
                  onChange={(event) => {
                    if (event && event.format) {
                      setFilterDateTo(event.format('DD MMM YYYY'));
                    } else {
                      setFilterDateTo(''); 
                      clearDateFilter()
                    }
                  }}
                  inputProps={{ placeholder: 'To Date',style:{fontFamily:'Inter',fontSize:"14px"} }}
                />
              </div>
            </Col>

              <Col>
              <div> 
              <button style={{display:'flex',alignItems:'center',width:'130px',backgroundColor:'#D22129',height:'34px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter",paddingLeft:'10px'}}
                  onClick={() => handleDateFilterApi(filterDateFrom, filterDateTo)}
                 
                >
                  Search Results
                </button>
                </div>
              </Col>
              {decode.role!=="institute_user"&&decode.role!=="institute_admin"?<Col>
              <div style={{height:'34px',lineHeight:'33px',fontSize:'13px',fontFamily:"Inter",width:'200px',display:'flex',alignItems:'center'}}>
                <Button disabled={selectedApp!=null?false:true} onClick={() => handlePayouts(selectedApp)} color="primary">
                  Create Disbursement
                </Button></div>
              </Col>:null}

              {/* <Col>
              <div>
                <button style={{width:'100px',backgroundColor:'gray',height:'36.5px',borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter"}} onClick={() => handleClearFilter()} color="primary">
                  Clear Filter
                </button>
                </div>
              </Col> */}
              <Col>
              <Col>
            <button style={{backgroundColor:'#D22129',width:'90px',height:'34px',padding:'10px',display: 'flex', alignItems: 'center',marginLeft:"120px",borderStyle:'none',borderRadius:"5px",color:'white',fontFamily:"Inter"}} id="downloadButton" onClick={() => handleDownloadList()}>
           <p style={{display:'flex',alignItems:'center',marginTop:'15px'}}><img style={{height:'15px',alignItems:"center",marginRight:'5px'}}  src={download} alt="download"/><span>Export</span></p> 
            </button>
            </Col>
              </Col>
            </div>
          </Row>
          {upiMandateStatusOpen && (
        <>
        <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
    <Modal style={{
      
      
      top:"10%",borderRadius:"10px"}} isOpen={upiMandateStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium',fontWeight:"600",marginLeft:'20px',marginTop:'13px'}}>UPI Mandate Details</p>
          {upiMandateTooltipOpen&&<span style={{fontFamily:'Inter-Medium',marginLeft:"200px",height:"30px",display:"flex",alignItems:"center",fontSize:'11px',backgroundColor:'black',color:'white',borderRadius:'5em',padding:'0.5em',position:'absolute',marginTop:'0.5em'}}>Copied to clipboard</span>}
          <button style={{borderStyle:'solid',borderRadius:"30px",borderWidth:"1px",fontFamily:'Inter-Medium',fontSize:'12px',borderColor:'#D32027'}} onClick={()=>upimandateTrigger()}>
            <span style={{marginRight:'10px'}}><img style={{height:'20px',width:'20px'}} src={retrigger}/></span>Trigger UPI mandate
            </button>
           
          <span style={{marginRight:'20px',cursor:"pointer"}}>
         
            <img onClick={() => setUpiMandateStatusOpen(!upiMandateStatusOpen)} src={close} alt="close"/>
          {/* <Button color="info" style={{right:'1em',top:'10px',position:'absolute'}} type="button" onClick={()=>retriggerAgreement()}>Retrigger Agreement</Button> */}
          </span>
          </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
            { upiMandateDetails || cashfreeDetails?<>
         
        
   
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Account Number :</div>
               <div 
                    style={{
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                     
                    }}>{upiMandateDetails?upiMandateDetails.accountNumber:null}</div> 
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Beneficiary Name  :</div>
               <div 
                    style={{
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                     
                    }}>{upiMandateDetails?upiMandateDetails.beneficiaryName:cashfreeDetails?cashfreeDetails?.namePerVpa:null}</div> 
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Customer Vpa :</div>
                
               <div 
                    
                    style={{
                      
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                      
                    }}>{upiMandateDetails?upiMandateDetails.customerVpa:cashfreeDetails?cashfreeDetails?.vpa:null}</div> 
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Ifsc :</div>
                
               <div 
                    
                    style={{
                      
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                     
                    }}>{upiMandateDetails?upiMandateDetails.ifsc:null}</div> 
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Mandate Id :</div>
                
               <div 
                    
                    style={{
                      
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                     
                    }}>{upiMandateDetails?upiMandateDetails.mandateId:null}</div> 
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Transaction Id :</div>
                
               <div 
                    
                    style={{
                      
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                      
                    }}>{upiMandateDetails?upiMandateDetails.transactionId:null}</div> 
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Transaction Time :</div>
                
               <div 
                    
                    style={{
                      
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                     
                    }}>{upiMandateDetails?upiMandateDetails.transactionTime:cashfreeDetails?cashfreeDetails?.cfEventTime:null}</div> 
              </div>
              <div style={{display:'flex',alignItems:'center',textAlign:'center'}}>
                <div style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',fontSize:'15px'}}>Umrn :</div>
                
               <div 
                    
                    style={{
                      
                      marginLeft:'1vw',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                      
                    }}>{upiMandateDetails?upiMandateDetails.umrn:cashfreeDetails?cashfreeDetails?.umrn:null}</div> 
              </div>
   

    
   
              
             </>:<h3>Information is not available</h3>} 
             
            </FormGroup>
          </Form>
        </ModalBody>
        <div style={{display:"flex",borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",flexDirection:'row',backgroundColor:'#E9E9E9',alignItems:"center",justifyContent:'space-between',height:"60px"}}>
        
        {/* <Button color='info'  style={{fontSize:"12px",fontFamily:"Inter-Medium",marginLeft:"40px",marginRight:'1em',borderStyle:"none",borderRadius:"30px",width:"120px",height:'33px',backgroundColor:"#D32028"}}
              onClick={() => copyAgreementToggle() }
            >
              Copy
            </Button> */}


          {/* <Button onClick={() => closeAgreeCopy()}>Close Box</Button> */}
          {
                mandateDetails?.status?null:<Button color="secondary" style={{border:'none',height:'2.5em',marginLeft:"10px",fontSize:'13px',borderRadius:'30px'}} onClick={()=>setRefreshMandateOpen(true)}>Refresh Emandate</Button>
          }
              <div style={{marginLeft:'150px',cursor:"pointer",marginRight:"50px",borderRadius:"30px",height:"33px",fontFamily:'Inter-Medium',backgroundColor:"#D32028",padding:'0.5em',display:"flex",flexDirection:"row",alignItems:"center",color:'white'}}>
              {decode.role==='institute_user'?null:
                
                <a target='_blank' rel='noreferrer'href={docDetails?docDetails.signedDocumentUrl:null} style={{textDecoration:'none'}}>
                 <span style={{fontSize:"13px",color:'white',fontFamily:"Inter-Medium"}}>Download Agreement</span></a>
                  }</div></div>
      </Modal>
        </>
      )}

          {selfieStatusOpen && (
        <>
         <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
        <Modal style={{
      width:"500px",
       
      top:"5%",width:'400px',borderRadius:"10px"}} isOpen={selfieStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium',marginLeft:'10px'}}>Selfie Retrigger</p>
          <span style={{marginRight:'20px',cursor:"pointer"}}><img onClick={() => setSeflieStatusOpen(!selfieStatusOpen)} src={close} alt="close"/></span>

        </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
              <div style={{display:"flex",flexDirection:'column'}}>
              <label style={{fontFamily:'Inter-Medium',fontSize:'15px'}}>KID</label>
              <Input style={{fontFamily:'Inter-Medium',borderRadius:"5px",borderStyle:"solid",borderWidth:'1px',height:"30px",width:'300px'}} type="text" placeholder="KID" onChange={(e)=>setKID(e.target.value)}/>
              </div>
              <div style={{display:"flex",flexDirection:'column'}}>
              <label  style={{fontFamily:'Inter-Medium',fontSize:'15px'}}>Transaction Id</label>
              <Input  style={{fontFamily:'Inter-Medium',borderRadius:"5px",borderStyle:"solid",borderWidth:'1px',height:"30px",width:'300px'}} type="text" placeholder="Transaction ID" onChange={(e)=>setTransactionID(e.target.value)}/>
              </div>
              
             <div style={{border:'1px dashed #D0D0D0',padding:'1em',marginTop:'1em'}}>
              <ul style={{listStyle:"none"}}>
                <li>Face Liveness: <span style={{fontWeight:'bold'}}>{bankSelfieDetails?.selfieDetails?.faceLivenessResult}</span></li>
                <li>Face Liveness: <span style={{fontWeight:'bold'}}>{(bankSelfieDetails?.selfieDetails?.faceLivenessScore*100).toFixed(2)}%</span></li>
                <li>Face Match: <span style={{fontWeight:'bold'}}>{ Number(bankSelfieDetails?.selfieDetails?.facematchScore).toFixed(2)}%</span></li>
                <li>Face Source: <span style={{fontWeight:'bold'}}>{bankSelfieDetails?.selfieDetails?.facematchSource}</span></li>
                <li>Geo-Tag Accuracy: <span style={{fontWeight:'bold'}}>{Number(bankSelfieDetails?.selfieDetails?.geoTagAccuracy).toFixed(2)} %</span></li>
                <li>Geo-Tag Address: <span style={{fontWeight:'bold'}}>{bankSelfieDetails?.selfieDetails?.geoTagAddress}</span></li>
              </ul>
            </div>
             
             
            </FormGroup>
          </Form>
        </ModalBody>
       
            <div style={{backgroundColor:'#E9E9E9',marginTop:"10px",borderBottomLeftRadius:'20px',borderBottomRightRadius:'20px',display:'flex',justifyContent:'space-between',padding:'20px'}}>
            <Button  style={{cursor:"pointer",fontFamily:'Inter-Medium',fontSize:"13px",color:"white",backgroundColor:"#D32028",borderRadius:"30px",borderStyle:'none',height:"30px"}}
              onClick={() => selfieRetrigger() }
            >
              Refresh Selfie
            </Button>
            <Button  style={{cursor:"pointer",fontFamily:'Inter-Medium',fontSize:"13px",color:"white",backgroundColor:"#D32028",borderRadius:"30px",borderStyle:'none',height:"30px"}}
              onClick={() => selfieReinitiate() }
            >
              Re-Initiate Selfie
            </Button>
            </div>
        
      </Modal>
        </>
      )}
      
      {formStatusOpen && (
        <>
        <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
     <Modal style={{
      width:"500px",
       
      top:"10%",width:'600px',borderRadius:"20px"}} isOpen={formStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium',fontWeight:"600",marginLeft:'10px',marginTop:'14px'}}>Agreement Details</p> 
          {agreementTooltipOpen&&<span style={{fontFamily:'Inter-Medium',marginLeft:"200px",height:"30px",display:"flex",alignItems:"center",fontSize:'11px',backgroundColor:'black',color:'white',borderRadius:'5em',padding:'0.5em',position:'absolute',marginTop:'0.5em'}}>Copied to clipboard</span>}
          <span style={{marginRight:'20px',cursor:"pointer"}}><img onClick={() => closeAgreeCopy()} src={close} alt="close"/>
          {/* <Button color="info" style={{right:'1em',top:'10px',position:'absolute'}} type="button" onClick={()=>retriggerAgreement()}>Retrigger Agreement</Button> */}
          </span>
          </div>
        <ModalBody>
          <Form >
            <FormGroup>
             { (docDetails||info)?<>

            
              <div style={{display:'flex',flexDirection:"row",justifyContent:"space-between"}}>
                <div style={{marginLeft:"10px"}}>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Aadhaar Suffix:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {info?info.aadhaar_suffix:null}</p>
                </div>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Aadhaar Name:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {info?info.name:null}</p>
                </div>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Display Name:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {info?info.display_name:null}</p>
                </div>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Created At:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {docDetails?docDetails.createdAt:null}</p>
                </div>
                </div>
                <div style={{marginRight:"20px"}}>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Aadhaar Name Validation:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',width:'20px',height:'20px',fontSize:'14px',fontFamily:'Inter-Medium',backgroundColor:`${info?info.name_validation_score>=75?'#ECFDF3':info.name_validation_score>=60 &&info.name_validation_score<75?'yellow':info.name_validation_score<60?'#FF3333':null:null}`}}><span style={{fontWeight:'500',fontFamily:'Inter-Medium',color:'black',fontSize:'17px',fontWeight:'600'}}>{info?info.name_validation_score:null}</span></p>
                </div>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Postal Code:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {info?info.postal_code:null}</p>
                </div>
                <div>
                <span style={{fontWeight:'400',lineHeight:'30px',fontFamily:'Inter-Medium',color:'#D22129'}}>Done At:</span>
                <p style={{lineHeight:'20px',fontWeight:'500',fontFamily:'Inter-Medium',fontSize:'14px'}}> {docDetails?docDetails.updatedAt:null}</p>
                </div>
                </div>
              </div>
            
                
                  </>:<h3>Information is not yet available</h3>}
            </FormGroup>
          </Form>
        </ModalBody>
        <div style={{display:"flex",marginTop:"13px",borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",flexDirection:'row',backgroundColor:'#E9E9E9',alignItems:"center",justifyContent:'space-between',height:"60px"}}>
          
          {/* <Button   style={{fontSize:"12px",fontFamily:"Inter-Medium",marginLeft:"40px",marginRight:'1em',borderStyle:"none",borderRadius:"30px",width:"120px",height:'33px',backgroundColor:"#D32028"}}
                onClick={() => copyAgreementToggle() }
              >
                Copy
              </Button> */}

  
            {/* <Button onClick={() => closeAgreeCopy()}>Close Box</Button> */}
                <div style={{marginLeft:'150px',cursor:"pointer",marginRight:"50px",borderRadius:"30px",height:"33px",fontFamily:'Inter-Medium',backgroundColor:"#D32028",padding:'0.5em',display:"flex",flexDirection:"row",alignItems:"center",color:'white'}}>
                {decode.role==="institute_user"||decode.role==="institute_admin"?null:
                  <div  style={{textDecoration:'none'}} onClick={toggle1}>
                  <img style={{height:"18px",width:'20px',marginRight:'10px'}} src={download} alt="download"/><span style={{fontSize:"12px",fontFamily:"Inter-Medium",color:'white',textDecoration:'none'}}>Agreement</span>
                  </div>
                    }
                </div>
                </div>
      </Modal>
        </>
      )}
       {refreshMandateOpen && (
        <>
        <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
    <Modal style={{
      
      
      top:"10%",borderRadius:"10px"}} isOpen={mandateStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium',fontWeight:"600",marginLeft:'20px',marginTop:'13px'}}>Refresh Emandate <small>( Enter Customer Id )</small></p>
    
          </div>
        
        <ModalBody>
          <Input type="text" placeholder="Enter Customer Id" onChange={(e)=>setCustomerId(e.target.value)}/>
          <Button style={{backgroundColor:'#D32028'}} onClick={()=>handleRefreshMandate()}>Refresh</Button>
        </ModalBody>
      
        
        {/* <Button color='info'  style={{fontSize:"12px",fontFamily:"Inter-Medium",marginLeft:"40px",marginRight:'1em',borderStyle:"none",borderRadius:"30px",width:"120px",height:'33px',backgroundColor:"#D32028"}}
              onClick={() => copyAgreementToggle() }
            >
              Copy
            </Button> */}


          {/* <Button onClick={() => closeAgreeCopy()}>Close Box</Button> */}
         <ModalFooter>
           <p  onClick={()=>setRefreshMandateOpen(false)}style={{cursor:"pointer",borderRadius:"30px",height:"40px",fontFamily:'Inter-Medium',backgroundColor:'grey',textAlign:'center',color:'white',width:'5em'}}>
             Close</p>
        </ModalFooter>
      </Modal>
        </>
      )}
 <Modal isOpen={modal}  size="xl" style={{height:'100%'}}>
        <ModalHeader toggle={toggle1}>Agreement</ModalHeader>
        <ModalBody >
        <iframe
            src={itemPath}
            width="100%"
            height="1000vh"
            style={{ border: 'none' }}
          ></iframe>
        </ModalBody>
        
       
      </Modal>
{mandateStatusOpen && (
        <>
        <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
    <Modal style={{
      
      
      top:"10%",borderRadius:"10px"}} isOpen={mandateStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium',fontWeight:"600",marginLeft:'20px',marginTop:'13px'}}>Mandate Details</p>
          {mandateTooltipOpen&&<span style={{fontFamily:'Inter-Medium',marginLeft:"200px",height:"30px",display:"flex",alignItems:"center",fontSize:'11px',backgroundColor:'black',color:'white',borderRadius:'5em',padding:'0.5em',position:'absolute',marginTop:'0.5em'}}>Copied to clipboard</span>}
         
            <button style={{borderStyle:'solid',borderRadius:"30px",borderWidth:"1px",fontFamily:'Inter-Medium',fontSize:'12px',borderColor:'#D32027'}} onClick={()=>upimandateTrigger()}>
            <span style={{marginRight:'10px'}}><img style={{height:'20px',width:'20px'}} src={retrigger}/></span>Trigger UPI mandate
            </button>
          <span style={{marginRight:'20px',cursor:"pointer"}}>
         
            <img onClick={() => closeMandateCopy()} src={close} alt="close"/>
          {/* <Button color="info" style={{right:'1em',top:'10px',position:'absolute'}} type="button" onClick={()=>retriggerAgreement()}>Retrigger Agreement</Button> */}
          </span>
          </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
            { mandateDetails?<>
         
              <div style={{ display: 'flex', flexWrap: 'wrap',width:'100%' }}>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Name as per Appl.</span>
                
               <span 
                    
                    style={{
                      
                      textDecoration: 'none',
                      color: '#232323',
                       fontSize: '13px',
                      fontFamily: 'Inter-Medium',
                      lineHeight:"30px",
                      display: 'block',
                      width: '100%',
                      wordBreak: 'break-word'
                    }}>{mandateDetails?mandateDetails.nameAsPerApplication:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Name as per Bank</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.name:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Match Percent</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?(Math.round(mandateDetails.matchPercentage*100)/100):null}%</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"20px",fontSize:'15px'}}>Account Number</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.accountNumber:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>Account Type</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.accountType:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>IFSC Code </span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.ifsc:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>Customer mobile</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.phone:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>Mandate Type </span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.mandateType:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>CustomerId</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.customerId:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>TokenId</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.tokenId:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>PaymentId</span>
                
               <span style={{color:"#232323",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.paymentId:null}</span> 
              </p>
    </div>
    <div style={{width:'50%',height:'60px', marginBottom: '10px'}}> 
              <p style={{display:'flex',flexDirection:"column"}}>
                <span style={{color:'gray',fontFamily:'Inter-Medium',fontWeight:'300',lineHeight:"30px",fontSize:'15px'}}>Reason for Rejection</span>
                
               <span style={{color:"#D32027",fontSize:'13px',fontFamily:'Inter-Medium',lineHeight:'30px'}}>{mandateDetails?mandateDetails.remark:null}</span> 
              </p>
    </div>
    </div>
               
             </>:<h3>Information is not available</h3>} 
             
            </FormGroup>
          </Form>
        </ModalBody>
        <div style={{display:"flex",borderBottomLeftRadius:"10px",borderBottomRightRadius:"10px",flexDirection:'row',backgroundColor:'#E9E9E9',alignItems:"center",justifyContent:'space-between',height:"60px"}}>
        
        {/* <Button color='info'  style={{fontSize:"12px",fontFamily:"Inter-Medium",marginLeft:"40px",marginRight:'1em',borderStyle:"none",borderRadius:"30px",width:"120px",height:'33px',backgroundColor:"#D32028"}}
              onClick={() => copyAgreementToggle() }
            >
              Copy
            </Button> */}


          {/* <Button onClick={() => closeAgreeCopy()}>Close Box</Button> */}
          {
                mandateDetails?.status?null:<Button color="secondary" style={{border:'none',height:'2.5em',marginLeft:"10px",fontSize:'13px',borderRadius:'30px'}} onClick={()=>setRefreshMandateOpen(true)}>Refresh Emandate</Button>
          }
              <div style={{marginLeft:'150px',cursor:"pointer",marginRight:"50px",borderRadius:"30px",height:"33px",fontFamily:'Inter-Medium',backgroundColor:"#D32028",padding:'0.5em',display:"flex",flexDirection:"row",alignItems:"center",color:'white'}}>
              {decode.role==="institute_user"||decode.role==="institute_admin"?null:
                
                <a target='_blank' rel='noreferrer'href={docDetails?docDetails.signedDocumentUrl:null} style={{textDecoration:'none'}}>
                 <span style={{fontSize:"13px",color:'white',fontFamily:"Inter-Medium"}}>Download Agreement</span></a>
                  }</div></div>
      </Modal>
        </>
      )}
       {digilockerStatusOpen && (
        <>
        <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 999,
      }}
    ></div>
     <Modal style={{
      width:"500px",
    
      top:"15%",width:'400px',borderRadius:"10px"}} isOpen={digilockerStatusOpen}>
        <div style={{height:"60px",borderTopLeftRadius:"10px",borderTopRightRadius:"10px",display:"flex",flexDirection:'row',alignItems:"center",justifyContent:'space-between',backgroundColor:'#E9E9E9'}}>
          <p style={{fontFamily:'Inter-Medium'}}>DigiLocker Re-fresh</p>
          <span style={{marginRight:'20px',cursor:"pointer"}}><img  onClick={() => setDigilockerStatusOpen(!digilockerStatusOpen)} src={close} alt="close"/></span>

        </div>
        
        <ModalBody>
          <Form>
            <FormGroup>
            <div style={{marginLeft:'10px',display:"flex",flexDirection:'column'}}>
              <label style={{fontFamily:'Inter-Medium',fontSize:'15px'}}>KID</label>
              <Input style={{fontFamily:'Inter-Medium',borderRadius:"5px",borderStyle:"solid",borderWidth:'1px',height:"30px",width:'300px'}} type="text" placeholder="KID" onChange={(e)=>setKID(e.target.value)}/>
              </div>
              <div style={{marginLeft:'10px',display:"flex",flexDirection:'column'}}>
              <label  style={{fontFamily:'Inter-Medium',fontSize:'15px'}}>Transaction Id</label>
              <Input  style={{fontFamily:'Inter-Medium',borderRadius:"5px",borderStyle:"solid",borderWidth:'1px',height:"30px",width:'300px'}} type="text" placeholder="Transaction ID" onChange={(e)=>setTransactionID(e.target.value)}/>
              </div>
              
             
            </FormGroup>
          </Form>
        </ModalBody>
        <div style={{backgroundColor:'#E9E9E9',marginTop:"10px",borderBottomLeftRadius:'20px',borderBottomRightRadius:'20px'}}>
            <Button  style={{cursor:"pointer",marginTop:"15px",fontFamily:'Inter-Medium',fontSize:"13px",color:"white",marginLeft:"130px",backgroundColor:"#D32028",borderRadius:"30px",borderStyle:'none',height:"30px",marginBottom:'1em',marginRight:'1em'}}
               onClick={() => digilockerRetrigger() }
            >
             Retrigger DigiLocker Status
            </Button>
            </div>
        
      </Modal>
        </>
      )} 

         <Modal size="xl" isOpen={selectedStatusOpen}>
        <ModalHeader>Create Disbursement</ModalHeader>
        <ModalBody >
          <Form>
            <FormGroup>
            { 
              displaySelected?                    
             <>
             <div style={{display:"flex",flexDirection:"row",justifyContent:"space-between",backgroundColor:'#FCF0F1',width:"100%",borderRadius:"10px"}}>
              <p style={{marginLeft:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}>Application ID <br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{displaySelected.applicationId}</span></p>
               <p style={{marginRight:"15px",fontFamily:"Inter-Medium",fontSize:"14px",color:"#D32028"}}> Applicant Name<br/><span style={{fontFamily:"Inter-Medium",fontSize:"14px",color:"black"}}>{selectedName}</span></p>
            </div>
            <div style={{display:'flex',justifyContent:'space-around'}}>
            <div>
                  <div style={{display:'flex'}}>
                    <div style={{width:'12rem'}}>Loan Amount : </div>
                    <div style={{color:'black',fontWeight:'700'}}>₹{formatNumberWithCommas(displaySelected?.amount)}</div>
                  </div>
                  <div style={{display:'flex'}}>
                    <div style={{width:'12rem'}}>Tenure :  </div>
                    <div style={{color:'black',fontWeight:'700'}}>{displaySelected?.tenure}</div>
                  </div>
                  <div style={{display:'flex'}}>
                    <div style={{width:'12rem'}}>Emi : </div>
                    <div style={{color:'black',fontWeight:'700'}}>₹{formatNumberWithCommas(displaySelected?.emi)}</div>
                  </div>
                  <div style={{display:'flex'}}>
                    <div style={{width:'12rem'}}>Adv. Months : </div>
                    <div style={{color:'black',fontWeight:'700'}}>{displaySelected?.advanceEmis?displaySelected?.advanceEmis:0}</div>
                  </div>
                  <div style={{display:'flex'}}>
                    <div style={{width:'12rem'}}>                  
                    <input type='checkbox' checked={advChecked} onChange={()=>setAdvChecked(!advChecked)} disabled={displaySelected?.advanceEmiStatus === 2 || displaySelected?.advanceEmiStatus === 3} style={{marginRight:'10px'}}></input>Advance : </div>
                    <div style={{color:'black',fontWeight:'700',display:'flex'}}>₹{advChecked?formatNumberWithCommas(displaySelected?.advanceEmiAmount?displaySelected?.advanceEmiAmount:0):"0"}  <span style={{marginLeft:'20px'}}>{displaySelected?.advanceEmiStatus === 2?<p style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p>:displaySelected?.advanceEmiStatus === 3?<p style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px',fontWeight:'700',display:'flex',justifyContent:'center',alignItems:'center',color:'black'}}>NA</p>:null}</span></div>
                  </div>
                  <div style={{display:'flex'}}>
                    <div style={{width:'12rem'}}>                  
                    <input type='checkbox' checked={pfsChecked} onChange={()=>setPfsChecked(!pfsChecked)} disabled={displaySelected?.processingFeeStatus === 2 || displaySelected?.processingFeeStatus === 3} style={{marginRight:'10px'}}></input>Pf : </div>
                    <div style={{color:'black',fontWeight:'700',display:'flex'}}>₹{pfsChecked?formatNumberWithCommas(displaySelected?.processingFees?displaySelected?.processingFees:0):"0"}  <span style={{marginLeft:'30px'}}>{displaySelected?.processingFeeStatus === 2?<p style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></p>:displaySelected?.processingFeeStatus === 3?<p style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px',fontWeight:'700',display:'flex',justifyContent:'center',alignItems:'center',color:'black'}}>NA</p>:null}</span></div>
                  </div>
                  
                 
                  <div style={{display:'flex'}}>
                    <div style={{width:'12rem'}}>Subvention : </div>
                    <div style={{color:'black',fontWeight:'700'}}>₹{formatNumberWithCommas(displaySelected?.subventionAmount?displaySelected?.subventionAmount:0)}</div>
                    
                  </div>
                  <div style={{display:'flex'}}>
                    <div style={{width:'12rem'}}> GST on Subvention: </div>
                    <div style={{color:'black',fontWeight:'700'}}>
                    ₹{formatNumberWithCommas(
    displaySelected?.instituteId === '0943e9e2-82d3-42c9-b844-93a742936720'
      ? 0
      : displaySelected?.subventionAmountGST ?? 0
  )}                      </div>

                  </div>
                  <div style={{display:'flex'}}>
                    <div style={{width:'12rem'}}>Adjusted Amount : </div>
                    <input placeholder='₹' onChange={(e)=>setAdjustmentAmount(e.target.value)} style={{borderRadius:'5px',width:'10em',height:'3em',padding:'0.5em'}}></input>
                    
                  </div>
                  <div style={{marginTop:'10px',display:'flex'}}>
                    <div style={{width:'12rem'}} >Amt. to be Disbursed :
                    </div>
                    <div style={{color:'black',fontWeight:'700'}} >
                       <input style={{borderRadius:'5px',width:'10em',height:'3em',padding:'0.5em'}} id="disbursementAmt" disabled 
                   value={displaySelected?.amountToBeDisbursed - 
                    ((advChecked && displaySelected?.advanceEmiStatus === 1) ? (displaySelected?.advanceEmiAmount || 0) : 0) - 
                    ((pfsChecked && displaySelected?.processingFeeStatus === 1) ? (displaySelected?.processingFees || 0) : 0) - 
                    adjustmentAmt+
    (displaySelected?.instituteId === '0943e9e2-82d3-42c9-b844-93a742936720'
      ? displaySelected?.subventionAmountGST 
      : 0)} /></div>
                  </div>
                 
                 </div>
                 <div>
                 <p style={{fontWeight:'700',width:'14.5em'}}>Bank Details</p>

                  <p style={{width:'15em'}}><span style={{fontWeight:'550'}}>Bank Name:</span> {displaySelected.bankDetails.bank_name}</p>
                  <p style={{width:'15em'}}><span style={{fontWeight:'550'}}>IFSC:</span> {displaySelected.bankDetails.ifsc}</p>
                  <p style={{width:'15em'}}><span style={{fontWeight:'550'}}>Institute Name:</span> {displaySelected.bankDetails.name}</p>
                  <p style={{width:'15em'}}><span style={{fontWeight:'550'}}>Acc No. :</span>{displaySelected.bankDetails.account_number}</p>
                  <div style={{display:'flex',justifyContent:'space-between',}}>
                        <select onChange={(e)=>setSourceAccountNumber(e.target.value)} style={{ backgroundColor: '#7057EA',height:'2.5em',borderRadius:'5px',color:'white',fontWeight:'500',padding:'10px',paddingBottom:'5px',paddingTop:'5px'}}  >
                          <option value="Src Acc. No.">Src Acc No.</option>
                          {/* <option value="4801135000005919">Karur Vysya Bank , 4801135000005919</option> */}
                          {/* <option value="9949422660">Kotak Mahindra Bank , 9949422660</option> */}
                          <option value="409001784252">RBL Bank , 409001784252</option>
                        </select>
                    {/* <Dropdown id={dropdown${index}} isOpen={dropdown[index]} toggle={() => handleToggle(index,dropdown)}>
                      <DropdownToggle id={sourceAccountNumber${index}}  caret color="primary">
                        {sourceAccNum}
                      </DropdownToggle>
                      
                      <DropdownMenu>
                        
                        <DropdowndisplaySelected  onClick={(e) => handleSourceAccountNumber(e,index)}>
                          2323230091863329
                        </DropdowndisplaySelected>
                        
                      </DropdownMenu>
                    </Dropdown> */}
                    <p style={{paddingLeft:'1em',paddingRight:'1em',fontWeight:'600',marginTop:'0.2em'}}>To</p>
                    <Button style={{height:'2.5em'}} color="primary" type="button">{displaySelected.bankDetails.account_number}</Button>
                    </div>

                 </div>
            </div>
            <div style={{display:'flex',marginLeft:'4.5rem',marginTop:'1rem'}}>
                    <div style={{width:'12rem'}}>Notes :</div>
                    <input onChange={(e)=>setAmountNotes(e.target.value)} style={{borderRadius:'5px',width:'18em',height:'3em',padding:'0.5em'}}></input>
                  </div>
                 
                                  </>
           :null 
            }
             
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
        

          <Button  onClick={() => setSelectedStatusOpen(!selectedStatusOpen)}>Close Box</Button>
          <Button style={{backgroundColor:'#D32028',border:'none'}} onClick={() => handleStatusChangeToPendingApprovals(displaySelected.applicationId)}>Create Disbursement</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={fraudToolOpen} >
        <ModalHeader >Fraud Detection Indicators  <img src={close} style={{cursor:'pointer',marginLeft:'9vw'}} onClick={() => setFraudToolOpen(!fraudToolOpen)}/></ModalHeader>
        <ModalBody>
        <Table style={{ border: '1px solid #dee2e6'}}>
      <thead>
        <tr>
          <th style={{ border: '1px solid #dee2e6' }}>Indicator</th>
          <th style={{ border: '1px solid #dee2e6' }}>Status</th>
        </tr>
      </thead>
      <tbody>
        
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Agreement Name match</td>
          <td style={{ border: '1px solid #dee2e6', color: fruadDetails && fruadDetails.agreementMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer'}} onClick={()=>handleDocDownload(sessionStorage.getItem("regenUserId"),sessionStorage.getItem("regenAppId"))}>{fruadDetails && fruadDetails.agreementMatchScore !== null && fruadDetails.agreementMatchScore !== undefined ? `${fruadDetails.agreementMatchScore.toFixed(2)} %` : ''}</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Face Match</td>
          <td style={{ border: '1px solid #dee2e6', color: fruadDetails && fruadDetails.faceMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer' }}  onClick={()=>handleSelfieRetrigger(sessionStorage.getItem("regenUserId"),sessionStorage.getItem("regenAppId"))}>{fruadDetails && fruadDetails.faceMatchScore !== null && fruadDetails.faceMatchScore !== undefined ? `${fruadDetails.faceMatchScore.toFixed(2)} %` : ''}</td>
        </tr>
        {/* <tr>
          <td style={{ border: '1px solid #dee2e6' }}>OTP Match</td>
          <td style={{ border: '1px solid #dee2e6', color: fruadDetails && fruadDetails.otpScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer' }} onClick={()=>handleSelfieRetrigger(sessionStorage.getItem("regenUserId"),sessionStorage.getItem("regenAppId"))}>{fruadDetails && fruadDetails.otpScore !== null && fruadDetails.otpScore !== undefined ? `${fruadDetails.otpScore.toFixed(2)} %` : ''}</td>
        </tr> */}
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Location within 100KM</td>
          <td style={{ border: '1px solid #dee2e6', color: fruadDetails && fruadDetails.distanceRange > 100 ? '#D22129' : '#12B76A' }} >{fruadDetails && fruadDetails.distanceRange !== null && fruadDetails.distanceRange !== undefined? `${fruadDetails.distanceRange} KM` : ''}</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Name Match in Bank</td>
          <td style={{ border: '1px solid #dee2e6' , color: fruadDetails && fruadDetails.bankAccountNameMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer'}} onClick={()=>handleMandateDetails(sessionStorage.getItem("regenAppId"),sessionStorage.getItem("regenUserId"),sessionStorage.getItem("regenName"),sessionStorage.getItem("mStatus"))}>{fruadDetails && fruadDetails.bankAccountNameMatchScore !== null && fruadDetails.bankAccountNameMatchScore !== undefined ? `${fruadDetails.bankAccountNameMatchScore.toFixed(2)} %` : ''}</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Pan Name Match</td>
          <td style={{ border: '1px solid #dee2e6' , color: fruadDetails && fruadDetails.panMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer'}}>  {fruadDetails && fruadDetails.panMatchScore !== null && fruadDetails.panMatchScore !== undefined ? `${fruadDetails.panMatchScore.toFixed(2)} %` : ''}
          </td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Mandate Provider</td>
          <td style={{ border: '1px solid #dee2e6', color: '#667085'}} >{fruadDetails?(fruadDetails?.mandateProvider === 1 ? (<p>DigioUpiMandate</p>):fruadDetails?.mandateProvider === 2 ? (<p>RazorpayMandate</p>):null) :null}</td>
        </tr>
        {/* <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Consent Location Match</td>
          <td style={{ border: '1px solid #dee2e6',color: fruadDetails && fruadDetails.consentLocationMatch === 1 ? '#D22129' : (fruadDetails && fruadDetails.consentLocationMatch === 100 ? '#12B76A' : '')}} >{fruadDetails && fruadDetails.consentLocationMatch !== null && fruadDetails.consentLocationMatch !== undefined? `${fruadDetails.consentLocationMatch}` : ''}</td>
        </tr> */}
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Consent Range</td>
          <td style={{ border: '1px solid #dee2e6', color: fruadDetails && fruadDetails.consentRange > 100 ? '#D22129' : '#12B76A' }} >{fruadDetails && fruadDetails.consentRange !== null && fruadDetails.consentRange !== undefined? `${fruadDetails.consentRange} KM` : ''}</td>
        </tr>
        <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Name Match in BankStatement</td>
          <td style={{ border: '1px solid #dee2e6' , color: fruadDetails && fruadDetails.bankStatementMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer'}}>  {fruadDetails && fruadDetails.bankStatementMatchScore !== null && fruadDetails.bankStatementMatchScore !== undefined ? `${fruadDetails.bankStatementMatchScore.toFixed(2)} %` : ''}
          </td>
        </tr>
        {/* <tr>
          <td style={{ border: '1px solid #dee2e6' }}>Name Match in AccountAggregator</td>
          <td style={{ border: '1px solid #dee2e6' , color: fruadDetails && fruadDetails.aaNameMatchScore < 70 ? '#D22129' : '#12B76A',textDecoration:'underline',cursor:'pointer'}}>  {fruadDetails && fruadDetails.aaNameMatchScore !== null && fruadDetails.aaNameMatchScore !== undefined ? `${fruadDetails.aaNameMatchScore.toFixed(2)} %` : ''}
          </td>
        </tr> */}
       
      </tbody>
    </Table>
          
          
        </ModalBody>
        
      </Modal>
      {/* <Modal isOpen={formStatusOpen}>
        <ModalHeader>Agreement Details</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
             { (docDetails||info)?<>

            
             <Button color='info'  style={{marginBottom:'1em',marginRight:'1em'}}
              onClick={() => copyAgreementToggle() } >
              Copy
            </Button>
             {agreementTooltipOpen&&<span style={{fontSize:'11px',backgroundColor:'black',color:'white',borderRadius:'5em',padding:'0.5em',position:'absolute',marginTop:'0.5em'}}>Copied to clipboard</span>}


             <p><span style={{fontWeight:'700'}}>Aadhaar Suffix:</span> {info?info.aadhaar_suffix:null}</p>
             <p><span style={{fontWeight:'700'}}>Name:</span> {info?info.name:null} </p>
             <p><span style={{fontWeight:'700'}}>Postal Code:</span>  {info?info.postal_code:null}</p>
             <p><span style={{fontWeight:'700'}}>Display Name: </span> {info?info.display_name:null}</p>
            <p style={{color:'white',backgroundColor:`${info?info.name_validation_score>=75?'lightGreen':info.name_validation_score>=60 &&info.name_validation_score<75?'yellow':info.name_validation_score<60?'#FF3333':null:null}`}}><span style={{fontWeight:'700' ,color:'black'}}>Aadhaar Name Validation:</span>  <span style={{color:'black',fontSize:'17px',marginLeft:'7px',fontWeight:'600'}}>{info?info.name_validation_score:null}</span></p>
             
             <p><span style={{fontWeight:'700'}}>Created At:</span>  {docDetails?docDetails.createdAt:null}</p>
            
             <p><span style={{fontWeight:'700'}}>Done At:</span>  {docDetails?docDetails.updatedAt:null}</p> {decode.role==="institute_user"||decode.role==="institute_admin"?null:<p style={{backgroundColor:'aliceBlue',height:'3em',padding:'0.5em'}}><a target='_blank' rel='noreferrer'href={docDetails?docDetails.signedDocumentUrl:null}>Click To Download Signed Agreement Document</a></p>}</>:<h3>Information is not yet available</h3>}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
        

          <Button onClick={() => closeAgreeCopy()}>Close Box</Button>
        </ModalFooter>
      </Modal> */}
{/*       
       <Modal isOpen={mandateStatusOpen}>
        <ModalHeader>Mandate Details</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
            { mandateDetails?<>
         
             <Button color='info'  style={{marginBottom:'1em',marginRight:'1em'}}
              onClick={() => copyMandateToggle() }
            >
              Copy
            </Button>
               {mandateTooltipOpen&&<span style={{fontSize:'11px',backgroundColor:'black',color:'white',borderRadius:'5em',padding:'0.5em',position:'absolute',marginTop:'0.5em'}}>Copied to clipboard</span>}
            <p><span style={{fontWeight:'700'}}>Name: </span>{mandateDetails?mandateDetails.name:null}</p>
            <p><span style={{fontWeight:'700'}}>Account Number: </span>{mandateDetails?mandateDetails.accountNumber:null}</p>
             <p><span style={{fontWeight:'700'}}>Account Type: </span>{mandateDetails?mandateDetails.accountType:null}</p>
             <p><span style={{fontWeight:'700'}}>ifscCode:</span> {mandateDetails?mandateDetails.ifsc:null}</p>
             <p><span style={{fontWeight:'700'}}>Mobile:</span> {mandateDetails?mandateDetails.phone:null}</p>
            <p ><span style={{fontWeight:'700'}}>Mandate Mode:</span> {mandateDetails?mandateDetails.mandateType:null}</p>
            <p ><span style={{fontWeight:'700'}}>Customer Id:</span> {mandateDetails?mandateDetails.customerId:null}</p>
             <p><span style={{fontWeight:'700'}}>token Id:</span> {mandateDetails?mandateDetails.tokenId:null}</p>
             <p><span style={{fontWeight:'700'}}>Payment Id:</span> {mandateDetails?mandateDetails.paymentId:null}</p></>:<h3>Information is not available</h3>}
             
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
        

          <Button onClick={() => closeMandateCopy()}>Close Box</Button>
        </ModalFooter>
      </Modal> */}
      {isLoading ? (
         <Box style={{ display: 'flex',alignItems:'center',justifyContent:'center',padding:'15%',backgroundColor:'white',height:'100vh'}}>
         <CircularProgress />
       </Box>
      ):(
        <>
        <div className='tables' style={{ marginTop: '20px' }}>
            <table>
              <thead className='table-heading'>
                <tr>
                  {decode.role!=="institute_user"&&decode.role!=="institute_admin"?<th  id="select" style={{width:'5em',borderTopLeftRadius:'8px'}}><span style={{  fontSize: '0.9vw',fontFamily: "Inter" ,marginLeft:'10px',whiteSpace:'nowrap',width:'7em'}}>Select</span></th>:null}
                  <th  id="appId" style={{width:'10em'}}><span style={{  fontSize: '0.9vw',fontFamily: "Inter" ,marginLeft:'10px',whiteSpace:'nowrap',width:'10em'}}>Appl. Id</span> 
                  {/* <img style={{cursor:'pointer',width:'15px'}} src={Sort} alt="sort"/> */}
                  </th>
                  <th  id="appname" style={{width:'15%'}}><span style={{  fontSize: '0.9vw',fontFamily: "Inter" ,marginLeft:'10px',whiteSpace:'nowrap'}}>Appl. Name</span> </th>
                  {(decode.role==="institute_user"||decode.role=="institute_admin" ) && decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? 
                   <th  style={{  fontSize: '0.9vw',fontFamily:"Inter",width:'10em' }} id="appname">
                   <span style={{marginLeft:'10px'}}></span> Student Name
                   </th>:null
                  }
                  <th  id="insName" style={{width:'12em'}}><span style={{  fontSize: '0.9vw',fontFamily: "Inter" ,marginLeft:'10px',whiteSpace:'nowrap'}}>Institute Name</span></th>
                  <th title='Digi Locker' style={{width:'4em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'5px'}}><img src={kyc} style={{height:'30px',width:'30px'}}/></span></th>
                  <th title='selfie' id="selfie"style={{width:'4em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw'}}><img src={selfie}  style={{height:'30px',width:'30px'}}/></span></th>
                  <th title='agreement' id="agreement"style={{width:'4em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'5px'}}><img src={agreement}  style={{height:'25px',width:'25px'}}/></span></th>
                  <th title='mandate' id="eMandate"style={{width:'4em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'5px'}}><img src={mandate}  style={{height:'35px',width:'30px',paddingBottom:'5px'}}/></span></th>
                  <th title='fraud detection' style={{width:'4em'}}><span style={{fontFamily:'Inter',fontSize:'0.9vw',marginLeft:'5px'}}><img src={fraudimage}  style={{height:'33px',width:'40px'}}/></span></th>
                  {/* <th  id="selfie"style={{width:'13em'}}><span style={{marginLeft:'18px',fontFamily:'Inter',fontSize:'0.9vw'}}>Selfie</span></th>
                  <th  id="agreement"style={{width:'13em'}}><span style={{marginLeft:'7px',fontFamily:'Inter',fontSize:'0.9vw'}}>Agreement</span></th>
                  <th  id="eMandate"style={{width:'13em'}}><span style={{marginLeft:'7px',fontFamily:'Inter',fontSize:'0.9vw'}}>EMandate</span></th>
                  {/* <th id="agreement" style={{width:'15em'}}>Disbursement Status</th>
                   */}
                  <th  id="amt" style={{width:'8em'}}><span style={{  fontSize: '0.9vw',fontFamily: "Inter" ,marginLeft:'10px'}}>Amount</span></th>
                  <th  id="tenure" style={{width:'2em'}}><span style={{  fontSize: '0.9vw',fontFamily: "Inter" ,marginLeft:'10px'}}>Tenure</span></th>
                  {/* <th  id="pfAmount" style={{width:'8em'}}><span style={{marginLeft:'25px', fontSize: '0.9vw',fontFamily: "Inter" ,whiteSpace:'nowrap'}}>Pmt Status</span></th> */}
                  { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:<th id="fundSource" style={{width:'8em'}}><span style={{marginLeft:'15px',fontFamily:"Inter",whiteSpace:'nowrap', fontSize: '0.9vw'}}>Fund Src</span></th>}
                  { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:<th id="actions"  style={{width:'5em',borderTopRightRadius:'8px'}} ><span style={{fontFamily:"Inter", fontSize: '0.9vw'}}>Actions</span></th>}
                </tr>
              </thead>
              { 
                tableData.map((item,index) => {
          
                  return (
                    
                      <tbody className='table-body' key={item.applicationId}>
                      {/* { (item.agreement==="Successful")&&(item.selfie==="Successful")&&(item.emandate==="Successful")? */}
                      
                        <tr className='table-row'  style={{ lineHeight: '20px' }}>
                        {decode.role!=="institute_user"&&decode.role!=="institute_admin"?<td style={{  fontSize: '0.9vw', width: '4em',fontFamily:"Inter" }}><input id={`input${index}`} disabled={!selectedAlready} style={{marginLeft:'1em',height:'18px',width:'18px'}} onChange={(e)=>handleSelectedApp(e,item,index)} type="checkbox"/></td>:null}
                        {decode.role!=="institute_user"&&decode.role!=="institute_admin"?
                        <td >
                          <span onClick={()=>handleApplicationProfile(item.applicationId,item.userId,item.coapplicantId)} style={{display:'inline-block',whiteSpace:'nowrap',overflow:'hidden', cursor:'pointer', fontSize: '0.9vw', fontWeight: '500', fontFamily:'Inter', maxWidth:'10em',color:'#101828',fontFamily:"Inter" }}>{item.applicationId}</span>
                        </td>:<td  style={{  fontSize: '0.9vw', fontWeight: '400', maxWidth: '7em',fontFamily:"Inter"  }}>
                          {item.applicationId}
                        </td>
                        }
                         {decode.role==="institute_user"||decode.role==="institute_admin"?<td
                          style={{
                            fontFamily:"Inter",fontWeight:'400',color:'#101828',fontSize:'0.9vw' ,
                           
                          }}
                         
                        >
                           <span title={item.name} style={{  fontSize: '0.9vw', width: '15em', color: '#101828',fontFamily:"Inter" ,lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName ? (
                            <span title={item.coapplicantName} style={{  fontSize: '0.9vw', width: '15em', color: '#101828',fontFamily:"Inter" ,lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}>
                              {' '}
                              {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                            </span>
                          ) : (
                            ''
                          )}
                        </td>:<td
                          style={{
                             fontSize: '0.9vw',
                            color: '#101828',
                            fontFamily:"Inter" ,
                            cursor: 'pointer',
                            
                          }}
                        >
                          <span title={item.name} style={{  fontSize: '0.9vw', width: '15em', color: '#101828',fontFamily:"Inter" ,lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }} onClick={() => handleUser(item)}>{item?.name?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</span>{' '}<br/>
                          {item.coapplicantName ? (
                            <span title={item.coapplicantName} style={{  fontSize: '0.9vw', width: '15em', color: '#101828',fontFamily:"Inter" ,lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }} onClick={() => handleCoApp(item)}>
                              {' '}
                              {item?.coapplicantName?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} ( C )
                            </span>
                          ) : (
                            ''
                          )}
                        </td>}
                        {(decode.role==="institute_user"||decode.role==="institute_admin")&&decode.instituteId === "0f25fafd-77c0-4c05-9cf1-1b238f5eebd3" ? <td title={item.studentName} >
                         <span style={{width: '10em', fontWeight: '400',  fontSize: '0.9vw', color: '#667085',fontFamily:"Inter",display:"block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}> {item.studentName ? item.studentName : "-"}</span>
                        </td>:null}
                        <td title={item.instituteName}><span style={{  fontSize: '0.9vw', width: '12em', color: '#667085',fontFamily:"Inter" ,lineHeight: '1.5em',display:"inline-block",whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'  }}>{item.instituteName?item.instituteName:'-'}</span></td>
                        <td style={{  fontSize: '12px', width: '6.5em'}}>
                          
                          {item.coapplicantDigilockerStatus?<>
                          <span style={{cursor:'pointer'}}  onClick={()=>handleDigilockerRetrigger(item.digilockerStatus,item.user,item.applicationId)}>
                            {item.digilockerStatus ? (
                            item.digilockerStatus === "Pass" ? (
                              <div title='Pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>

                            ) : item.digilockerStatus === "Initiated" ? (
                            <div title='Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>                   
                            ) :item.digilockerStatus === "Not Initiated" ? (
                              <div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>                   

                            ) : (
                              item.digilockerStatus
                            )
                          ) : (
                            <div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>
                          )}
                          </span>
                          <span style={{cursor:'pointer'}} onClick={()=>handleDigilockerRetrigger(item.coapplicantDigilockerStatus,item.coapplicantId,item.applicationId)}>
                            {item.coapplicantDigilockerStatus ? (
                              item.coapplicantDigilockerStatus === "Pass" ? (
                                <div title='Pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>

                              ) : item.coapplicantDigilockerStatus === "Initiated" ? (
                                  <div title='Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>                   
                              ) :item.coapplicantDigilockerStatus === "Not Initiated" ? (
                                <div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>                   

                              ) : (
                                item.coapplicantDigilockerStatus
                              )
                            ) : (
                              <div style={{fontSize:'12px',fontWeight:'bold',marginTop:'10px'}}>NA</div>
                            )}
                            
                            </span>
                          </>:
                          <span style={{cursor:'pointer'}}  onClick={()=>handleDigilockerRetrigger(item.digilockerStatus,item.user,item.applicationId)}>
                            {item.digilockerStatus ? (
                            item.digilockerStatus === "Pass" ? (
                              <div title='Pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>

                            ) : item.digilockerStatus === "Initiated" ? (
                              <div title='Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>                   

                            ) :item.digilockerStatus === "Not Initiated" ? (
                              <div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>                   

                            ) :
                             (
                              item.digilockerStatus
                            )
                          ) : (
                            <div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div> )}
                          </span>}
                        </td>
                        <td>
                         <span key={item.userId}  id="selfie" onClick={()=>handleSelfieRetrigger(item.userId,item.applicationId)} style={{cursor:'pointer'}}
                        > 
                         {item.selfie?
                         (item.selfie==="Pass"?<div title='Pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                         :item.selfie==="Not Initiated"?<div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>                   
                         :item.selfie==="Initiated"?<div title='Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>                   
                         :item.selfie==="Face mismatch"?<div title='Attn! Face mismatch' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                         :item.selfie):<div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>}
                         </span>
                         {item.coapplicantSelfie?<span key={item.userId}  id="selfie" onClick={()=>handleSelfieRetrigger(item.coapplicantId,item.applicationId)} style={{cursor:'pointer'}}
                        >
                          {item.coapplicantSelfie?
                         (item.coapplicantSelfie==="Pass"?<div title='Pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                         :item.coapplicantSelfie==="Not Initiated"?<div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>                   
                         :item.coapplicantSelfie==="Initiated"?<div title='Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>                   
                         :item.coapplicantSelfie==="Face mismatch"?<div title='Attn! Face mismatch' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                         :item.coapplicantSelfie):<div style={{fontSize:'12px',fontWeight:'bold',marginTop:'10px'}}>NA</div>}
                
                         </span>:null}
                         
                        </td>
                        <td style={{maxWidth:'2em'}}>
                          {decode.role==="institute_user"||decode.role==="institute_admin"?<>
                          <span key={item.userId} id="signed" style={{cursor:'pointer'}}
                          > 
                            
                             {item.agreement?
                    (
                    item.agreement==="Signed"?<div title='Signed' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.agreement==="Invalid"?<div title='Attn!' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.agreement==="Pending"?<div title='Pending' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.agreement==="Manual"?<div title='Manual' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer'}}>M</div>
                    :item.agreement==="Not Initiated"?<div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>                   
                     :item.agreement):<div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>}
                          </span>
                            {item.coapplicantAgreement?
                            <span key={item.userId} id="signed" style={{cursor:'pointer'}}
                            >
                            
                            {item.coapplicantAgreement?
                    (
                    item.coapplicantAgreement==="Signed"?<div title='Signed' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantAgreement==="Invalid"?<div title='Attn!' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantAgreement==="Pending"?<div title='Pending' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantAgreement==="Manual"?<div title='Manual' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer',marginTop:'10px'}}>M</div>
                    :item.coapplicantAgreement==="Not Initiated"?<div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>                   
                     :item.coapplicantAgreement):<div style={{fontSize:'12px',fontWeight:'bold',marginTop:'10px'}}>NA</div>} </span>:null}</>:<><span onClick={()=>handleDocDownload(item.userId,item.applicationId)} key={item.userId} id="signed"  style={{cursor:'pointer'}}
                          >
                         {item.agreement?
                    (
                    item.agreement==="Signed"?<div title='Signed' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.agreement==="Invalid"?<div title='Attn!' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.agreement==="Regenerated"?<div title='Regenerated' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.agreement==="Pending"?<div title='Pending' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.agreement==="Manual"?<div title='Manual' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer'}}>M</div>
                    :item.agreement==="Not Initiated"?<div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>                   
                     :item.agreement):<div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>}
                        </span>{item.coapplicantAgreement?<span onClick={()=>handleDocDownload(item.coapplicantId,item.applicationId)} key={item.userId} id="signed" style={{cursor:'pointer'}}
                            >
                           
                           {item.coapplicantAgreement?
                    (
                    item.coapplicantAgreement==="Signed"?<div title='Signed' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantAgreement==="Invalid"?<div title='Attn!' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantAgreement==="Pending"?<div title='Pending' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantAgreement==="Manual"?<div title='Manual' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer',marginTop:'10px'}}>M</div>
                    :item.coapplicantAgreement==="Not Initiated"?<div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>                   
                     :item.coapplicantAgreement):<div style={{fontSize:'12px',fontWeight:'bold',marginTop:'10px'}}>NA</div>}  </span>:null}</>}
                          
                        </td>
                        <td >
                            {decode.role==="institute_user"||decode.role==="institute_admin"?
                           <>
                           <span id="emandate" style={{cursor:'pointer'}}>
                    
                    {item.emandate?
                    (item.emandate==="Rejected"?<div title='Rejected' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate==="Pass"?<div title='Pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate==="Upi Mandate Done"?<div title='Upi Mandate Done' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer'}}>U</div>
                    :item.emandate==="Upi Mandate Failed"?<div title='Upi Mandate Failed' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer'}}>U</div>
                    :item.emandate==="Upi Mandate Initiated"?<div title='Upi Mandate Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer'}}>U</div>
                    :item.emandate==="Upi Mandate Partial"?<div title='Upi Mandate Partial' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer'}}>U</div>
                    :item.emandate==="Initiated"?<div title='Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate==="Not Initiated"?<div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate==="Invalid Bank Details"?<div title='Invalid Bank Details' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate==="Not Applicable"?<div title='Not Applicable' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate):<div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>}
                            
                            </span>
                            {item.coapplicantEmandate?<span id="emandate" style={{cursor:'pointer'}}> 
           
           {item.coapplicantEmandate?
                    (item.coapplicantEmandate==="Rejected"?<div title='Rejected' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate==="Pass"?<div title='Pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate==="Upi Mandate Done"?<div title='Upi Mandate Done' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer',marginTop:'10px'}}>U</div>
                    :item.coapplicantEmandate==="Upi Mandate Failed"?<div title='Upi Mandate Failed' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer',marginTop:'10px'}}>U</div>
                    :item.coapplicantEmandate==="Upi Mandate Initiated"?<div title='Upi Mandate Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer',marginTop:'10px'}}>U</div>
                    :item.coapplicantEmandate==="Upi Mandate Partial"?<div title='Upi Mandate Partial' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer',marginTop:'10px'}}>U</div>
                    :item.coapplicantEmandate==="Initiated"?<div title='Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate==="Not Initiated"?<div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate==="Invalid Bank Details"?<div title='Invalid Bank Details' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate==="Not Applicable"?<div title='Not Applicable' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate):<div style={{fontSize:'12px',fontWeight:'bold',marginTop:'10px'}}>NA</div>} </span>:null}</>:
                            <>
                            <span id="emandate" onClick={()=>handleMandateDetails(item.applicationId,item.userId,item.name,item.emandate,item.cra)} style={{cursor:'pointer'}}>
                             
                             
                    {item.emandate?
                    (item.emandate==="Rejected"?<div title='Rejected' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate==="Pass"?<div title='Pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate==="Upi Mandate Done"?<div title='Upi Mandate Done' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer'}}>U</div>
                    :item.emandate==="Upi Mandate Failed"?<div title='Upi Mandate Failed' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer'}}>U</div>
                    :item.emandate==="Upi Mandate Initiated"?<div title='Upi Mandate Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer'}}>U</div>
                    :item.emandate==="Upi Mandate Partial"?<div title='Upi Mandate Partial' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer'}}>U</div>
                    :item.emandate==="Initiated"?<div title='Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate==="Not Initiated"?<div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate==="Invalid Bank Details"?<div title='Invalid Bank Details' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate==="Not Applicable"?<div title='Not Applicable' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                    :item.emandate):<div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>}                        </span>
                            {item.coapplicantEmandate?<span id="emandate" onClick={()=>handleMandateDetails(item.applicationId,item.coapplicantId,item.name,item.coapplicantEmandate,item.cra)} style={{cursor:'pointer'}}
                            >
                          {item.coapplicantEmandate?
                    (item.coapplicantEmandate==="Rejected"?<div title='Rejected' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate==="Pass"?<div title='Pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate==="Upi Mandate Done"?<div title='Upi Mandate Done' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer',marginTop:'10px'}}>U</div>
                    :item.coapplicantEmandate==="Upi Mandate Failed"?<div title='Upi Mandate Failed' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer',marginTop:'10px'}}>U</div>
                    :item.coapplicantEmandate==="Upi Mandate Initiated"?<div title='Upi Mandate Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer',marginTop:'10px'}}>U</div>
                    :item.coapplicantEmandate==="Upi Mandate Partial"?<div title='Upi Mandate Partial' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',display:'flex',alignItems:'center',textAlign:'center',justifyContent:'center',fontWeight:'bold',cursor:'pointer',marginTop:'10px'}}>U</div>
                    :item.coapplicantEmandate==="Initiated"?<div title='Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate==="Not Initiated"?<div title='Not Initiated' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate==="Invalid Bank Details"?<div title='Invalid Bank Details' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate==="Not Applicable"?<div title='Not Applicable' style={{fontSize:'12px',backgroundColor:'#898989',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                    :item.coapplicantEmandate):<div style={{fontSize:'12px',fontWeight:'bold',marginTop:'10px'}}>NA</div>}                           </span>:null}</>}
                           
                        </td>
                        <td >
                           
                            {decode.role==="institute_user"||decode.role==="institute_admin" ?
                            <>
                            {item.coapplicantId ? 
                              <>
                                <span id="emandate"  style={{cursor:'pointer'}}>
                                  {item.applicantFraudCheck?
                                  (item.applicantFraudCheck==="fail"?<div title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                                  :item.applicantFraudCheck==="pass"?<div title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                                  :item.applicantFraudCheck):<div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>}
                                </span>
                                <span id="emandate"  style={{cursor:'pointer'}}>
                                          {item.coapplicantFraudCheck?
                                    (item.coapplicantFraudCheck==="fail"?<div title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                                    :item.coapplicantFraudCheck==="pass"?<div title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                                    :item.coapplicantFraudCheck):<div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>}  
                                </span>
                              </>
                              :
                              <span id="emandate"  style={{cursor:'pointer'}}>
                                  {item.applicantFraudCheck?
                                  (item.applicantFraudCheck==="fail"?<div title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                                  :item.applicantFraudCheck==="pass"?<div title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                                  :item.applicantFraudCheck):<div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>}
                                </span>
                            }
                            </>
                          :
                          <>
                          {item.coapplicantId ? 
                            <>
                              <span id="emandate" onClick={()=>handleFraudDetails(item.applicationId,item.userId,item.name,item?.emandate)} style={{cursor:'pointer'}}>
                                {item.applicantFraudCheck?
                                (item.applicantFraudCheck==="fail"?<div title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                                :item.applicantFraudCheck==="pass"?<div title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                                :item.applicantFraudCheck):<div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>}
                              </span>
                              <span id="emandate" onClick={()=>handleFraudDetails(item.applicationId,item.coapplicantId,item.name,item?.emandate)} style={{cursor:'pointer'}}>
                                        {item.coapplicantFraudCheck?
                                  (item.coapplicantFraudCheck==="fail"?<div title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px',marginTop:'10px'}}></div>
                                  :item.coapplicantFraudCheck==="pass"?<div title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                                  :item.coapplicantFraudCheck):<div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>}  
                              </span>
                            </>
                            :
                            <><span id="emandate" onClick={()=>handleFraudDetails(item.applicationId,item.userId,item.name,item?.emandate)} style={{cursor:'pointer'}}>
                            {item.applicantFraudCheck?
                            (item.applicantFraudCheck==="fail"?<div title='fail' style={{fontSize:'12px',backgroundColor:'#D22129',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                            :item.applicantFraudCheck==="pass"?<div title='pass' style={{fontSize:'12px',backgroundColor:'#12B76A',borderStyle:'solid',borderRadius:'50%',border:'none',height:'20px',width:'20px'}}></div>
                            :item.applicantFraudCheck):<div style={{fontSize:'12px',fontWeight:'bold'}}>NA</div>}
                          </span></>
                            
                          }
                          </>
                              }

                           
                        </td>  
                        <td style={{  fontSize: '0.9vw', width: "6em" , color: '#667085',fontFamily:"Inter" }}>{item.amount ? ( <>&#8377;{formatNumberWithCommas(item.amount)}</>) : ( '-')}</td>
                        <td style={{  fontSize: '0.9vw', width: '2em', textAlign: 'center', color: '#667085',fontFamily:"Inter"  }}>{item.tenure?item.tenure:'-'}
                          
                        </td>
                        {/* <td style={{  fontSize: '0.9vw', width: '5.5em', textAlign: 'center', color: '#667085',fontFamily:"Inter" }}>{item.paymentStatus ? item.paymentStatus : "-"}
                          
                        </td> */}
                        { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null: <td style={{  fontSize: '0.9vw', width: '4em', textAlign: 'center', color: '#667085',fontFamily:"Inter"   }}>{item.fundSourceCode?item.fundSourceCode:'-'}
                          
                        </td>}
                       { decode.role==="institute_user"||decode.role==="institute_admin"||decode.role==="user"||decode.role==="co_lender"?null:
                        <td style={{width:'5em'}}>
                          <div >
                            <span title='Move Status' ><MoveDisbursementStatus    ApplicantId={item} /></span>
                          </div>
                         
                        </td>}
                      </tr>
                    
                    </tbody>
                        
                  );

              
              })}
            </table>
            </div>
            {totalFetchRows ? ( totalFetchRows <= 100 ?(
                    <div style={{display:"flex" ,justifyContent:"center",marginTop:"10px"}}>
                      <div style={{ textAlign: 'center', fontWeight: 'bold',fontFamily:"Inter", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px' }}>
               
               Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length}{" "}
                records of {totalFetchRows} |
               
             </div>
            <div style={{display:"flex", justifyContent:"space-between",}}>
            &nbsp;<p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
         
         
          </div>
                      
                    </div>
               
            ):( <div style={{display:"flex",justifyContent:"center",marginTop:"10px"}}><div style={{ textAlign: 'center',fontWeight: 'bold',justifyContent:"space-between",fontFamily:"Inter-Medium", fontSize: '1vw',marginLeft:'10px',marginBottom:'5px',marginRight:'10px'}}>
                
            Showing {currentPage * rowsPerPage + 1 - rowsPerPage}-{currentPage * rowsPerPage - rowsPerPage+tableData?.length} {" "}
            records of {totalFetchRows} |
           
          </div>
          <div style={{display:"flex", justifyContent:"space-between",}}>
            <p style={{fontWeight:'600'}}>Rows Per Pages :</p>
          <div>
          <span onClick={()=>handlepageChange(15)} style={{cursor:'pointer',padding:'10px',color: activePage === 15 ? "#D32028" : "black",backgroundColor:activePage === 15 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>15</span>
          <span onClick={()=>handlepageChange(50)} style={{cursor:'pointer',padding:'10px',color: activePage === 50 ? "#D32028" : "black",backgroundColor:activePage === 50 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>50</span>
          <span onClick={()=>handlepageChange(100)} style={{cursor:'pointer',padding:'10px',color: activePage === 100 ? "#D32028" : "black",backgroundColor:activePage === 100 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>100</span>
          <span onClick={()=>handlepageChange(500)} style={{cursor:'pointer',padding:'10px',color: activePage === 500 ? "#D32028" : "black",backgroundColor:activePage === 500 ?"#FFF2F3" : "" ,borderRadius:'8px'}}>500</span>
          </div>
          </div>
          </div> )
             
            ) : (
              <div style={{ textAlign: 'center', fontWeight: 'bold', fontFamily:"Inter", fontSize: '0.9vw' }}>No Records</div>
            )}

            <PaginationTable
              startPage={currentPage}
              rowsPerPage={rowsPerPage}
              totalRows={totalFetchRows}
              paginate={paginate}
              searchText={searchText}
              filterDateFrom={filterDateFrom}
            />
        </>
      )}
          
         
        </CardBody>
        </div>
      </Card>
    );
  }
  return <div>....Loading</div>;
};

export default InDisbursement;
